import React from 'react';
import { Navigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import { ROUTES } from 'constants/index';

export function PrivateRoute({ isLoggedIn, children }) {
  return isLoggedIn ? children : <Navigate to={ROUTES.LOGIN} />;
}

PrivateRoute.propTypes = {
  isLoggedIn: PropTypes.bool,
  children: PropTypes.node,
};
