import { handleActions, combineActions } from 'redux-actions';
import { fromJS } from 'immutable';

import { mapListToObject } from 'helpers/common';
import { merger } from 'ducks/projects/reducer';

import { LOGIN_RESPONSE } from '../auth/actions';
import {
  LOGGED_USER_INFO_RESPONSE,
  LOGGED_USER_INFO_ERROR,
  LOGOUT_USER,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_RESPONSE,
  GET_ALL_USERS_ERROR,
  GET_USERS_RESPONSE,
  GET_USERS_ERROR,
} from './actions';

const initialState = fromJS({
  usersCount: 0,
  data: {
    id: null,
    username: '',
    name: '',
    email: '',
    roles: [],
  },
  users: {},
  isRequesting: false,
  error: '',
});

export default handleActions(
  {
    [LOGOUT_USER]: (state) => state.merge(initialState),
    [LOGIN_RESPONSE]: (state, { payload: { response } }) =>
      state.setIn(['data', 'id'], response.data.user_id),
    [LOGGED_USER_INFO_RESPONSE]: (state, { payload }) =>
      state.mergeWith(
        merger,
        fromJS({
          data: {
            username: payload.username,
            name: payload.name,
            email: payload.email,
            roles: payload.roles,
          },
        })
      ),
    [GET_ALL_USERS_REQUEST]: (state) => state.set('isRequesting', true),
    [combineActions(GET_USERS_RESPONSE, GET_ALL_USERS_RESPONSE)]: (
      state,
      action
    ) => {
      const requestedUsers = mapListToObject(action.payload.users);

      return state
        .mergeWith(merger, fromJS({ users: requestedUsers }))
        .set('isRequesting', false);
    },
    [combineActions(
      LOGGED_USER_INFO_ERROR,
      GET_USERS_ERROR,
      GET_ALL_USERS_ERROR
    )]: (state, { payload }) => state.set('error', payload.error),
  },
  initialState
);
