import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Row, Button, Tooltip, Popover } from 'antd';
import PropTypes from 'prop-types';

import styles from './MachineSettings.module.css';

const propTypes = {
  isErrors: PropTypes.bool,
  isReallyDirty: PropTypes.bool,
  isUpdatingSettings: PropTypes.bool,
  saveForm: PropTypes.func,
};

export function BottomButtonsPanel({
  isErrors,
  isReallyDirty,
  isUpdatingSettings,
  saveForm,
}) {
  const { t } = useTranslation();
  const popoverContent = t('Machine_settings.Popover.text');

  return (
    <Row
      type="flex"
      justify="end"
      className={clsx(styles.buttonRow, 'no-print')}
    >
      <Button
        type="primary"
        onClick={saveForm}
        disabled={isErrors || !isReallyDirty}
        loading={isUpdatingSettings}
        className={styles.formButton}
      >
        {t('Machine_settings.Save_button')}
      </Button>
      <Popover content={popoverContent} visible={isErrors}>
        <Button
          type="primary"
          htmlType="submit"
          // onClick={downloadConfig}
          disabled={isErrors}
          className={styles.formButton}
        >
          {t('Machine_settings.Download_button')}
        </Button>
      </Popover>
      <Tooltip
        id="no-print"
        title={t('Machine_settings.Print_button.Tooltip')}
        placement="topLeft"
      >
        <Button
          onClick={() => window.print()}
          className={styles.formButton}
          type="primary"
        >
          {t('Machine_settings.Print_button.Label')}
        </Button>
      </Tooltip>
    </Row>
  );
}

BottomButtonsPanel.propTypes = propTypes;
