import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

import { LOGOUT_USER } from '../user/actions';
import {
  GET_FILE_HISTORY_REQUEST,
  GET_FILE_HISTORY_RESPONSE,
  GET_FILE_HISTORY_ERROR,
} from './actions';

const initialState = fromJS({
  entries: [],
  sorting: {
    field: 'createdAt',
    order: 'desc',
  },
  isRequesting: false,
  hasNextPage: false,
  currentPage: 1,
  error: '',
});

export default handleActions(
  {
    [LOGOUT_USER]: (state) => state.merge(initialState),
    [GET_FILE_HISTORY_REQUEST]: (state, action) =>
      state
        .set('isRequesting', true)
        .set('currentPage', action.payload.page)
        .updateIn(['sorting'], (sorting) =>
          action.payload.sorting ? fromJS(action.payload.sorting) : sorting
        ),
    [GET_FILE_HISTORY_RESPONSE]: (state, action) => {
      const { 'hydra:member': collection, 'hydra:totalItems': count } =
        action.payload.entries;

      const perPage = 30;
      const hasNextPage = action.payload.page * perPage < count;

      return state
        .updateIn(['entries'], (entries) =>
          action.payload.page === 1
            ? fromJS(collection)
            : entries.concat(collection)
        )
        .set('isRequesting', false)
        .set('hasNextPage', hasNextPage);
    },
    [GET_FILE_HISTORY_ERROR]: (state, action) =>
      state.set('error', action.payload.error),
  },
  initialState
);
