/**
 * Turns an array of objects, each holding the specified
 * field, into an array of unique values of that field.
 * @param {Object[]} objectList
 * @param {string} fieldName
 * @returns {Array}
 */
export const getUniqueFieldValues = (objectList, fieldName) =>
  objectList.reduce((acc, item) => {
    if (acc.some((value) => value === item[fieldName])) {
      return acc;
    }
    return [...acc, item[fieldName]];
  }, []);

/**
 * Turns an array of objects, each holding the specified
 * unique identifier (uid), into an object of objects
 * with those identifiers as keys.
 * @param {Object[]} objectList
 * @param {string} fieldName
 * @returns {Object}
 */
export const mapListToObject = (objectList, fieldName = 'id', transformKey) => {
  const isTransform = typeof transformKey === 'function';

  return objectList.reduce(
    (acc, object) => ({
      ...acc,
      [isTransform ? transformKey(object[fieldName]) : object[fieldName]]:
        object,
    }),
    {}
  );
};

/**
 * @param {string} endpoint
 * @returns {number}
 */
export const getIdFromEndpoint = (endpoint) =>
  Number.parseInt(endpoint.substring(endpoint.lastIndexOf('/') + 1), 10);

/**
 * Formik refuse to play nice with ant-d Select
 * this is a workaround
 * Don't forget to use useCallback when implementing it
 * @param field
 * @param setFieldValue
 * @returns {function(*=): *}
 */
export const updateField = (field, setFieldValue) => (value) =>
  setFieldValue(field, value);

/**
 * Mapper for form values to turn them form string to number
 * @param {Object} data
 * @param {@Object} rooms
 */
export function formatStringToIntValues({ data, rooms }) {
  const normalizedData = {
    ...data,
    installationType: `/api/installation_types/${data.installationType}`,
    airToAirUnitsReference: `/api/reference_units/${data.airToAirUnitsReference}`,
    nominalAirflow: Number(data.nominalAirflow),
    nominalAirflowExternalStaticPressure: Number(
      data.nominalAirflowExternalStaticPressure
    ),
    minimalAirflowOneOutletOpen: Number(data.minimalAirflowOneOutletOpen),
    minimalAirflowOneOutletOpenExternalStaticPressure: Number(
      data.minimalAirflowOneOutletOpenExternalStaticPressure
    ),
    airflowDuringDefrostCycle: Number(data.airflowDuringDefrostCycle),
    externalStaticPressureAvailableThermostatOff: Number(
      data.externalStaticPressureAvailableThermostatOff
    ),
    airDuctDistributionLeakage: Number(data.airDuctDistributionLeakage),
    airflowWithThermostatOff: Number(data.airflowWithThermostatOff),
    historyStepMinutes: Number(data.historyStepMinutes),
    minimalSetTemperatureInHeatingOperation: Number(
      data.minimalSetTemperatureInHeatingOperation
    ),
    maximalSetTemperatureInHeatingOperation: Number(
      data.maximalSetTemperatureInHeatingOperation
    ),
    minimalSetTemperatureInCoolingOperation: Number(
      data.minimalSetTemperatureInCoolingOperation
    ),
    maximalSetTemperatureInCoolingOperation: Number(
      data.maximalSetTemperatureInCoolingOperation
    ),
    standardAirspeedSetpointAtAirgrillsOutlet: Number(
      data.standardAirspeedSetpointAtAirgrillsOutlet
    ),
    maximalAirspeedSetpointAtTheAirgrillsOutlet: Number(
      data.maximalAirspeedSetpointAtTheAirgrillsOutlet
    ),
    lowNoiseAirflow: Number(data.lowNoiseAirflow),
    modeBoostAirflow: Number(data.modeBoostAirflow),
    minimalAirflowWithCompressorOn: Number(data.minimalAirflowWithCompressorOn),
    diffStH: Number(data.diffStH),
    diffStL: Number(data.diffStL),
    openingOutletTime: Number(data.openingOutletTime),
    closingOutletTime: Number(data.closingOutletTime),
  };

  const normalizedRooms = rooms.map((room) => {
    let normalizedRoom = {
      ...room,
      treatedSurface: Number(room.treatedSurface),
      ceilingHeight: Number(room.ceilingHeight),
      effectiveOpenedAirgrillSurface: Number(
        room.effectiveOpenedAirgrillSurface
      ),
      effectiveClosedAirgrillSurface: Number(
        room.effectiveClosedAirgrillSurface
      ),
      selectedAirgrillDimension: `/api/outlets/${room.selectedAirgrillDimension}`,
    };
    // eslint-disable-next-line no-prototype-builtins
    if (+room.secondChannelTreatedSurface || +room.secondChannelCeilingHeight) {
      normalizedRoom = {
        ...normalizedRoom,
        secondChannelCeilingHeight: Number(room.secondChannelCeilingHeight),
        secondChannelEffectiveClosedAirgrillSurface: Number(
          room.secondChannelEffectiveClosedAirgrillSurface
        ),
        secondChannelEffectiveOpenedAirgrillSurface: Number(
          room.secondChannelEffectiveOpenedAirgrillSurface
        ),
        secondChannelSelectedAirgrillDimension:
          room.secondChannelSelectedAirgrillDimension
            ? `/api/outlets/${room.secondChannelSelectedAirgrillDimension}`
            : null,
        secondChannelTreatedSurface: Number(room.secondChannelTreatedSurface),
      };
    } else {
      normalizedRoom = {
        ...normalizedRoom,
        secondChannelSelectedAirgrillDimension: null,
        secondChannelSelectedAirgrillDimensionValue: 0,
      };
    }

    return normalizedRoom;
  });

  return { normalizedData, normalizedRooms };
}

export const debounce = (func, ms) => {
  let timeoutId;
  const context = this;
  const result = (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(context, args), ms);
  };
  result.cancel = () => clearTimeout(timeoutId);

  return result;
};

export const redirect = (to) => {
  window.location = to;
};
