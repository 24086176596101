import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tabs } from 'antd';

import { getAllUsersRequest } from 'ducks/user/actions';
import {
  getProjectsRequest,
  getProjectsParamsRequest,
} from 'ducks/projects/actions';
import { getMachinesRequest } from 'ducks/machines/actions';

import {
  isExpertSelector,
  userDataSelector,
  usersSelector,
} from 'ducks/user/selectors';
import {
  projectsSelector,
  projectsCountSelector,
  isUpdatingProjectsSelector,
  isRequestingProjectsSelector,
  projectsParamsSelector,
  projectsIdsSelector,
} from 'ducks/projects/selectors';
import {
  machinesSelector,
  machinesCountSelector,
  isUpdatingMachinesSelector,
  isRequestingMachinesSelector,
  machinesIdsSelector,
} from 'ducks/machines/selectors';

import { useAdminCheck } from 'hooks/useAdminCheck';

import { ProjectsTabPane } from '../../components/ProjectsTabPane';
import { MachinesTabPane } from '../../components/MachinesTabPane';

import styles from './ProjectsPage.module.css';

const { TabPane } = Tabs;

export function ProjectsPage() {
  /* Hooks */
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isAdmin = useAdminCheck();
  const isExpert = useSelector(isExpertSelector);

  /* Selectors */
  const users = useSelector(usersSelector);
  const projects = useSelector(projectsSelector);
  const projectsIds = useSelector(projectsIdsSelector);
  const projectsCount = useSelector(projectsCountSelector);
  const machines = useSelector(machinesSelector);
  const machinesIds = useSelector(machinesIdsSelector);
  const machinesCount = useSelector(machinesCountSelector);
  const PARAMS = useSelector(projectsParamsSelector);

  const { username: userName } = useSelector(userDataSelector);

  const isRequestingProjects = useSelector(isRequestingProjectsSelector);
  const isRequestingMachines = useSelector(isRequestingMachinesSelector);
  const isUpdatingProjects = useSelector(isUpdatingProjectsSelector);
  const isUpdatingMachines = useSelector(isUpdatingMachinesSelector);

  /* Effects */
  useEffect(() => {
    dispatch(getAllUsersRequest());
    dispatch(
      getProjectsRequest({
        page: 1,
        perPage: 50,
        sortByCreation: 'desc',
      })
    );
    dispatch(
      getMachinesRequest({
        page: 1,
        perPage: 50,
        // sortBySaveDate: 'desc'
      })
    );
    dispatch(getProjectsParamsRequest());
  }, [dispatch]);

  return (
    <div className={styles.ProjectsPage}>
      <Row type="flex" justify="center" className={styles.pageRow}>
        <Col span={23}>
          <Tabs type="card" tabBarGutter={4}>
            <TabPane tab={t('Projects_page.Tab_titles.Projects')} key="1">
              <ProjectsTabPane
                users={users}
                userName={userName}
                projects={projects}
                projectsIds={projectsIds}
                projectsCount={projectsCount}
                machines={machines}
                PARAMS={PARAMS}
                isRequestingProjects={isRequestingProjects}
                isUpdatingProjects={isUpdatingProjects}
                isAdmin={isAdmin || isExpert}
              />
            </TabPane>
            <TabPane
              tab={t('Projects_page.Tab_titles.Machines')}
              key="2"
              disabled={!(isAdmin || isExpert)}
            >
              <MachinesTabPane
                users={users}
                projects={projects}
                machines={machines}
                machinesIds={machinesIds}
                machinesCount={machinesCount}
                PARAMS={PARAMS}
                isRequestingMachines={isRequestingMachines}
                isUpdatingMachines={isUpdatingMachines}
                isAdmin={isAdmin || isExpert}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
}
