import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setLanguageAction } from 'ducks/config/actions';
import { langSelector } from 'ducks/config/selectors';

export const useTranslation = () => {
  const lang = useSelector(langSelector);
  const dispatch = useDispatch();

  const setLanguage = useCallback(
    (language) => {
      dispatch(setLanguageAction({ lang: language }));
    },
    [dispatch]
  );

  return {
    lang,
    setLanguage,
  };
};
