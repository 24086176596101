import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Row, Col, InputNumber, Checkbox, Button } from 'antd';
import PropTypes from 'prop-types';

import { langSelector } from 'ducks/config/selectors';
import { TableRowsNames } from './TableRowNames';
import { TableMainColumn } from './TableMainColumn';
import { TableColumn } from './TableColumn';
import {
  calculateRoomParams,
  calculateMainParams,
  selectAirgrillByAutoParam,
} from './calculationHelpers';
import mainStyles from '../MachineSettings.module.css';
import styles from './RoomsConfigTable.module.css';

const COLS_PER_ROW = 10;

const getInitialTableCols = (roomsCount, maxChannelsCount) =>
  roomsCount > maxChannelsCount ? maxChannelsCount : roomsCount;

const getTotalRowsNumber = (cols, mainRoomChannels) =>
  Math.ceil((cols + (mainRoomChannels === 2 ? 1 : 0)) / COLS_PER_ROW);

const emptyObject = (obj) =>
  typeof obj === 'object'
    ? Object.values(obj).filter((item) => !!item).length
    : null;

const getMaxColumnsNumber = (rowIndex, mainRoomChannels) =>
  rowIndex === 0 && mainRoomChannels === 2 ? COLS_PER_ROW - 1 : COLS_PER_ROW;

export function RoomsConfigTable({
  children,
  values,
  errors,
  additionalErrors,
  installationType,
  airSpeed,
  mainAirflowParams,
  selectedUnit,
  outlets,
  roomNames,
  updateRooms,
  handleBlur,
  handleChange,
  handleValueChange,
  handleAdvancedSettings,
  handleAirflowParamsChange,
  // removeMainRoomSecondChannelParams,
  setFirstRoomChannels,
  setMinimalAirleakError,
  setGlobalCeilingHeight,
  setFieldValue,
  setFieldTouched,
}) {
  const { t } = useTranslation();
  const lang = useSelector(langSelector);
  // const translateRoomNames = () =>
  //   roomNames.map((room) => ({
  //     ...room,
  //     label: t(`Machine_settings.Room_names.${room.label}`),
  //   }));

  /* States */
  const [maxChannelsCount, setMaxChannelsCount] = useState(4);
  const [mainRoomChannels, setMainRoomChannels] = useState(2);
  const [tableCols, setTableCols] = useState(4);
  const [roomParams, setRoomParams] = useState([]);
  const [isAdvancedChecked, setIsAdvancedChecked] = useState(false);
  const [translatedRoomNames, setTranslatedRoomNames] = useState(roomNames);

  /* Effects */
  useEffect(() => {
    if (roomNames.length) {
      translateRoomNames();
    }
  }, [lang, roomNames]);

  /**
   * Main room channels calculations
   */
  useEffect(() => {
    if (
      +values.rooms[0].secondChannelTreatedSurface > 0 ||
      +values.rooms[0].secondChannelCeilingHeight > 0 ||
      +values.rooms[0].secondChannelSelectedAirgrillDimensionValue > 200 ||
      installationType === 2
    ) {
      setFirstRoomChannels(2);
      setMainRoomChannels(2);
    } else {
      setFirstRoomChannels(1);
      setMainRoomChannels(1);
    }
  }, [values.rooms, installationType]);

  useEffect(() => {
    setTableCols(getInitialTableCols(values.rooms.length, maxChannelsCount));
  }, [values.rooms.length, maxChannelsCount]);

  /**
   * Effect for calculating max channels
   */
  useEffect(() => {
    if (selectedUnit?.id) {
      setMaxChannelsCount(
        // eslint-disable-next-line no-nested-ternary
        selectedUnit.id >= 12 ? 9 : mainRoomChannels === 2 ? 4 : 8
      );
    }
  }, [selectedUnit, mainRoomChannels, maxChannelsCount]);

  /**
   * Params calculations
   */
  useEffect(() => {
    const { rooms } = values;
    let secondChannelSelectedAirGrill;

    const additionalParams = rooms.map((room, index) => {
      const selectedAirGrill = outlets.find(
        (outlet) => outlet.id === room.selectedAirgrillDimension
      );
      if (!index && room.secondChannelSelectedAirgrillDimension) {
        secondChannelSelectedAirGrill = outlets.find(
          (outlet) => outlet.id === room.secondChannelSelectedAirgrillDimension
        );
      }

      if (selectedAirGrill || secondChannelSelectedAirGrill) {
        const calculatedRoomParams = calculateRoomParams(room, index, {
          selectedAirGrill,
          secondChannelSelectedAirGrill,
        });

        if (installationType === 2) {
          // eslint-disable-next-line no-console
          console.log('handleValueChange for secondChannel');

          handleValueChange(
            `rooms[${index}].secondChannelCeilingHeight`,
            calculatedRoomParams.secondChannelCeilingHeight
          );
          handleValueChange(
            `rooms[${index}].secondChannelTreatedSurface`,
            calculatedRoomParams.secondChannelTreatedSurface
          );
        }

        handleValueChange(
          `rooms[${index}].effectiveOpenedAirgrillSurface`,
          calculatedRoomParams.effectiveOpenedAirgrillSurface
        );
        handleValueChange(
          `rooms[${index}].effectiveClosedAirgrillSurface`,
          calculatedRoomParams.effectiveClosedAirgrillSurface
        );

        if (
          !index &&
          calculatedRoomParams.secondChannelEffectiveOpenedAirgrillSurface
        ) {
          handleValueChange(
            `rooms[${index}].secondChannelEffectiveOpenedAirgrillSurface`,
            calculatedRoomParams.secondChannelEffectiveOpenedAirgrillSurface
          );
          handleValueChange(
            `rooms[${index}].secondChannelEffectiveClosedAirgrillSurface`,
            calculatedRoomParams.secondChannelEffectiveClosedAirgrillSurface
          );
        }
        return calculatedRoomParams;
      }

      return {
        index,
        treatedSurface: Number.parseInt(room.treatedSurface, 10) || 0,
        totalAirflowLowNoise: 0,
        totalAirflowBoostMode: 0,
        effectiveOpenedAirgrillSurface: 0,
        effectiveClosedAirgrillSurface: 0,
        airJetLengthInCooling: 0,
        airJetLengthInHeating: 0,
        autoSettingParam: 0,
        airJetLengthInHeatingLowNoise: 0,
        airJetLengthInCoolingLowNoise: 0,
        airJetLengthInHeatingBoostMode: 0,
        airJetLengthInCoolingBoostMode: 0,
        rateOfVolumeLowNoise: 0,
        rateOfVolumeBoostMode: 0,
      };
    });

    const {
      calculatedRoomParams,
      insufficientAirleak,
      airSpeedLowNoise,
      airSpeedBoostMode,
      totalAirflowLowNoise,
      totalAirflowBoostMode,
    } = calculateMainParams(
      additionalParams,
      {
        standartAirSpeed: airSpeed.standart,
        maxAirSpeed: airSpeed.maximum,
        minIndoorUnitAirFlow: airSpeed.minIndoorUnitAirFlow,
      },
      selectedUnit
    );
    if (Number(totalAirflowLowNoise)) {
      setMinimalAirleakError(insufficientAirleak);
    }
    setRoomParams(calculatedRoomParams);

    if (Number(totalAirflowLowNoise) && Number(totalAirflowBoostMode)) {
      handleAirflowParamsChange({
        airSpeedLowNoise,
        airSpeedBoostMode,
        totalAirflowLowNoise,
        totalAirflowBoostMode,
      });
    }
  }, [
    values.rooms,
    outlets,
    airSpeed.standart,
    airSpeed.maximum,
    airSpeed.minIndoorUnitAirFlow,
    selectedUnit,
    setMinimalAirleakError,
    // mainRoomChannels,
    handleAirflowParamsChange,
  ]);

  /* Handlers */
  const translateRoomNames = useCallback(() => {
    const rooms = roomNames.map((room) => ({
      ...room,
      label: t(`Machine_settings.Room_names.${room.label}`),
    }));
    if (rooms.length) {
      setTranslatedRoomNames(rooms);
    }
  }, [roomNames, setTranslatedRoomNames]);

  const setAutomaticGrills = useCallback(() => {
    const selectionBoundaries = { min: 200, max: 700 };
    roomParams.forEach((room, index) => {
      const { autoSettingParam } = room;
      const selectedGrill = selectAirgrillByAutoParam(
        autoSettingParam,
        selectionBoundaries
      );

      const grillId = outlets.find((grill) => grill.label === selectedGrill).id;
      handleValueChange(`rooms[${index}].selectedAirgrillDimension`, grillId);
    });
    if (roomParams[0].secondChannelAutoSettingParam) {
      const selectedGrill = selectAirgrillByAutoParam(
        roomParams[0].secondChannelAutoSettingParam,
        selectionBoundaries
      );
      const grillId = outlets.find((grill) => grill.label === selectedGrill).id;
      handleValueChange(
        `rooms[0].secondChannelSelectedAirgrillDimension`,
        grillId
      );
    }
  }, [roomParams, outlets, handleValueChange]);

  const onChangeColumnsNumber = useCallback(
    (value) => {
      let newTableCols = value;

      if (!newTableCols || newTableCols <= 0) {
        newTableCols = 1;
      }
      if (newTableCols > 9) {
        newTableCols = 9;
      }

      setTableCols(newTableCols);
      updateRooms(newTableCols);
      handleValueChange('channelsUsed', newTableCols);
    },
    [updateRooms, handleValueChange]
  );

  const handleAdvancedSettingsCheck = useCallback(
    ({ target: { checked } }) => {
      setIsAdvancedChecked(checked);
      handleAdvancedSettings(checked);
    },
    [handleAdvancedSettings]
  );

  const handleFirstRoomChannelChange = useCallback(
    (value) => {
      if (value === 1) {
        removeMainRoomSecondChannelParams();
      }
      // add main handler in root component
      setFirstRoomChannels(value);
      setMainRoomChannels(value);
    },
    [values]
  );

  const removeMainRoomSecondChannelParams = useCallback(() => {
    const firstRoomValues = { ...values.rooms[0] };

    firstRoomValues.secondChannelCeilingHeight = 0;
    firstRoomValues.secondChannelTreatedSurface = 0;
    firstRoomValues.secondChannelSelectedAirgrillDimension = 0;
    firstRoomValues.secondChannelSelectedAirgrillDimensionValue = 200;
    firstRoomValues.secondChannelAirleakStatus = false;
    firstRoomValues.secondChannelEffectiveClosedAirgrillSurfaceValue = 0;
    firstRoomValues.secondChannelEffectiveOpenedAirgrillSurfaceValue = 0;
    setFieldValue('rooms[0]', firstRoomValues);
  }, [values]);

  const handleSetGlobalCeilingHeight = useCallback(
    ({ target: { value } }) => {
      setGlobalCeilingHeight(value, mainRoomChannels);
    },
    [setGlobalCeilingHeight, mainRoomChannels]
  );

  return (
    <div className={clsx(styles.RoomsConfigTable)}>
      <Row className={mainStyles.row}>
        <Col span={7}>
          <div className={styles.checkboxPanel}>
            <Checkbox
              checked={isAdvancedChecked}
              onChange={handleAdvancedSettingsCheck}
            >
              {t('Machine_settings.Settings_modes_section.Advanced_input')}
            </Checkbox>
          </div>
        </Col>
      </Row>
      <Row className={mainStyles.row}>
        <InputNumber
          name="channelsUsed"
          value={tableCols}
          parser={(value) => {
            let finalValue = Number(value.substring(0, 1));

            finalValue =
              Number.isNaN(finalValue) || finalValue === 0 ? '' : finalValue;

            finalValue =
              finalValue > maxChannelsCount ? maxChannelsCount : finalValue;

            return finalValue;
          }}
          min={1}
          max={maxChannelsCount}
          onClick={(event) => event.target.select()}
          onBlur={() => setFieldTouched('channelsUsed')}
          onChange={onChangeColumnsNumber}
          className={styles.channelsUsedInput}
        />
        {t(
          'Machine_settings.Rooms_config_section.Inputs.Number_of_channels.Label'
        )}
      </Row>
      <Row className={mainStyles.row}>
        <Col>
          <Button type="ghost" onClick={setAutomaticGrills}>
            {t('Machine_settings.Settings_modes_section.Automatic_button')}
          </Button>
          <div className={clsx(mainStyles.fieldTitle, mainStyles.errorMessage)}>
            {errors?.rooms &&
              !emptyObject(errors.rooms) &&
              t('Machine_settings.Field_error')}
          </div>
        </Col>
      </Row>
      {Array(getTotalRowsNumber(tableCols, mainRoomChannels))
        .fill(0)
        // eslint-disable-next-line no-unused-vars
        .map((_item, rowIndex) => {
          const maxColumnsInCurrentRow = getMaxColumnsNumber(
            rowIndex,
            mainRoomChannels
          );

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Row key={rowIndex} className={mainStyles.row}>
              <Col span={4} className={mainStyles.tableRowsNamesCol}>
                <TableRowsNames
                  advanced={isAdvancedChecked}
                  mainAirflowParams={mainAirflowParams}
                />
              </Col>
              <Col>
                {Array(maxColumnsInCurrentRow) // Columns
                  .fill(0)
                  // eslint-disable-next-line no-shadow
                  .map((_item, colIndex) => {
                    const correctionIndex =
                      mainRoomChannels === 2 && rowIndex > 0 ? 1 : 0;
                    const dataIndex =
                      colIndex +
                      rowIndex * maxColumnsInCurrentRow -
                      correctionIndex;

                    return !colIndex && !rowIndex
                      ? values.rooms[0] && (
                          <TableMainColumn
                            key="mainColumn"
                            values={values.rooms[0]}
                            roomParams={roomParams[0]}
                            errors={errors?.rooms?.[0] || {}}
                            additionalErrors={additionalErrors}
                            installationType={installationType}
                            outlets={outlets}
                            roomNames={translatedRoomNames}
                            roomChannels={mainRoomChannels}
                            advanced={isAdvancedChecked}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            setFieldValue={handleValueChange}
                            setFieldTouched={setFieldTouched}
                            setChannels={handleFirstRoomChannelChange}
                            setGlobalCeilingHeight={
                              handleSetGlobalCeilingHeight
                            }
                          >
                            {children}
                          </TableMainColumn>
                        )
                      : values.rooms[dataIndex] && (
                          <TableColumn
                            // eslint-disable-next-line react/no-array-index-key
                            key={dataIndex}
                            index={dataIndex}
                            values={values.rooms[dataIndex]}
                            roomParams={roomParams[dataIndex]}
                            errors={errors?.rooms?.[dataIndex] || {}}
                            outlets={outlets}
                            roomNames={translatedRoomNames}
                            advanced={isAdvancedChecked}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            setFieldValue={handleValueChange}
                            setFieldTouched={setFieldTouched}
                          />
                        );
                  })}
              </Col>
            </Row>
          );
        })}
    </div>
  );
}

RoomsConfigTable.propTypes = {
  children: PropTypes.node,
  values: PropTypes.shape({
    rooms: PropTypes.array,
    channelsUsed: PropTypes.number,
    ceilingHeight: PropTypes.string,
    installationType: PropTypes.number,
  }),
  errors: PropTypes.shape({
    rooms: PropTypes.array,
    channelsUsed: PropTypes.string,
    ceilingHeight: PropTypes.string,
  }),
  additionalErrors: PropTypes.shape({
    secondChannelCeilingHeight: PropTypes.bool,
    secondChannelTreatedSurface: PropTypes.bool,
  }),
  installationType: PropTypes.number,
  airSpeed: PropTypes.shape({
    standart: PropTypes.number,
    maximum: PropTypes.number,
    minIndoorUnitAirFlow: PropTypes.number,
  }),
  mainAirflowParams: PropTypes.shape({
    airSpeedLowNoise: PropTypes.number,
    airSpeedBoostMode: PropTypes.number,
    totalAirflowLowNoise: PropTypes.number,
    totalAirflowBoostMode: PropTypes.number,
  }),
  outlets: PropTypes.array,
  roomNames: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  selectedUnit: PropTypes.object,
  updateRooms: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  handleValueChange: PropTypes.func,
  handleAdvancedSettings: PropTypes.func.isRequired,
  handleAirflowParamsChange: PropTypes.func,
  // removeMainRoomSecondChannelParams: PropTypes.func,
  setFirstRoomChannels: PropTypes.func,
  setMinimalAirleakError: PropTypes.func,
  setGlobalCeilingHeight: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
};
