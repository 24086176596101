import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { generatePath, matchPath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Breadcrumb, Typography } from 'antd';
import PropTypes from 'prop-types';

import { ROUTES } from 'constants/index';

import { logout as logoutAction } from 'ducks/auth/actions';
import { userDataSelector } from 'ducks/user/selectors';
import { projectSelector } from 'ducks/projects/selectors';

import logo from 'assets/image/logo.png';
import logoutIcon from 'assets/image/logout-sign.png';

import styles from './SiteHeader.module.css';

const { Text } = Typography;

const getBcRoutes = (t) => [
  {
    path: ROUTES.ROOT,
    breadcrumbName: t('Header.Breadcrumbs.Root'),
    trail: [],
  },
  {
    path: ROUTES.PROJECTS,
    breadcrumbName: t('Header.Breadcrumbs.Projects'),
    trail: [
      {
        path: ROUTES.ROOT,
        breadcrumbName: t('Header.Breadcrumbs.Root'),
      },
    ],
  },
  {
    path: ROUTES.PROJECT_DETAILS,
    breadcrumbName: t('Header.Breadcrumbs.Project_details'),
    trail: [
      {
        path: ROUTES.ROOT,
        breadcrumbName: t('Header.Breadcrumbs.Root'),
      },
      {
        path: ROUTES.PROJECTS,
        breadcrumbName: t('Header.Breadcrumbs.Projects'),
      },
    ],
  },
  {
    path: ROUTES.PROJECT_MACHINE_SETTINGS,
    breadcrumbName: t('Header.Breadcrumbs.Project_machine_settings'),
    trail: [
      {
        path: ROUTES.ROOT,
        breadcrumbName: t('Header.Breadcrumbs.Root'),
      },
      {
        path: ROUTES.PROJECTS,
        breadcrumbName: t('Header.Breadcrumbs.Projects'),
      },
      {
        path: ROUTES.PROJECT_DETAILS,
        breadcrumbName: t('Header.Breadcrumbs.Project_details'),
      },
    ],
  },
  {
    path: ROUTES.MACHINE_SETTINGS,
    breadcrumbName: t('Header.Breadcrumbs.Machine_settings'),
    trail: [
      {
        path: ROUTES.ROOT,
        breadcrumbName: t('Header.Breadcrumbs.Root'),
      },
      {
        path: ROUTES.PROJECTS,
        breadcrumbName: t('Header.Breadcrumbs.Projects'),
      },
    ],
  },
  {
    path: ROUTES.PROJECT_NEW,
    breadcrumbName: t('Header.Breadcrumbs.Project_new'),
    trail: [
      {
        path: ROUTES.ROOT,
        breadcrumbName: t('Header.Breadcrumbs.Root'),
      },
    ],
  },
  {
    path: ROUTES.OPTIONS,
    breadcrumbName: t('Header.Breadcrumbs.Options'),
    trail: [
      {
        path: ROUTES.ROOT,
        breadcrumbName: t('Header.Breadcrumbs.Root'),
      },
    ],
  },
];

const propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export function SiteHeader({ isLoggedIn }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { name: userName } = useSelector(userDataSelector);
  const { id: projectId } = useSelector(projectSelector);
  const location = useLocation();
  const { pathname } = location;

  const bcRoutes = useMemo(() => getBcRoutes(t), [t]);

  const match = bcRoutes
    .map((route) =>
      matchPath({ path: route.path, caseSensitive: true, end: true }, pathname)
    )
    .find((result) => result !== null);

  const currentParams = match?.params || {};

  const matchedPath = match?.path || '';

  const currentBcRoute = bcRoutes.find((route) => route.path === matchedPath);
  const bcRouteChain = currentBcRoute?.trail
    ?.map((trailRoute) => {
      const mappedRoute = bcRoutes.find(
        (route) => route.path === trailRoute.path
      );

      return mappedRoute || trailRoute;
    })
    .concat(currentBcRoute);

  if (currentBcRoute) {
    currentBcRoute.currentParams = currentParams;
  }

  const itemRender = (route, _, routes) => {
    const last = routes.indexOf(route) === routes.length - 1;

    if (matchedPath === ROUTES.ROOT) {
      return null;
    }

    return last || (route.path === '/projects/:id' && !projectId) ? (
      <Text type="secondary" className={styles.bcText}>
        {route.breadcrumbName}
      </Text>
    ) : (
      <Link
        to={generatePath(route.path, {
          id: projectId,
        })}
      >
        <Text className={styles.bcLink}>{route.breadcrumbName}</Text>
      </Link>
    );
  };

  const logOutHandler = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  return (
    <div className={clsx(styles.SiteHeader, 'no-print')}>
      <Link to={ROUTES.ROOT}>
        <img className={styles.logo} src={logo} alt="Aldes logo" />
      </Link>
      {isLoggedIn && (
        <>
          <Breadcrumb itemRender={itemRender} routes={bcRouteChain} />
          <div className={styles.quitButton} onClick={logOutHandler}>
            <span className={styles.quitButtonText}>
              {userName.toUpperCase()}
            </span>
            <img className={styles.quitIcon} src={logoutIcon} alt="logout" />
          </div>
        </>
      )}
    </div>
  );
}

SiteHeader.propTypes = propTypes;
