import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Select, Icon } from 'antd';
import { useTranslation } from 'react-i18next';

import { useTranslation as useTranslationSettings } from 'hooks/useTranslation';
import { BASE_URL } from '../../config';

import styles from './SiteFooter.module.css';

const { Option } = Select;

export function SiteFooter() {
  const { lang, setLanguage } = useTranslationSettings();
  const { i18n, t } = useTranslation();

  const changeLanguage = (value) => {
    setLanguage(value);
    i18n.changeLanguage(value);
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  return (
    <div className={clsx(styles.SiteFooter, 'no-print')}>
      <div className={styles.linksBlock}>
        {/* TODO: The final links are TBD */}
        <a href={`${BASE_URL}uploads/files/documents/V08_SOFT_TONE.bin`}>
          <div className={styles.footerText}>
            <Icon type="download" /> {t('Footer.Links.TOne_file')}
          </div>
        </a>
        <a
          href={`${BASE_URL}uploads/files/documents/V06_Notice_Utilisation_T_One.pdf`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className={styles.footerText}>
            <Icon type="download" /> {t('Footer.Links.TOne_manual')}
          </div>
        </a>
      </div>
      <div className={styles.linksBlock}>
        {/* TODO: The final links are TBD */}
        <a
          href={`${BASE_URL}uploads/files/documents/V15_SOFT_TONE_AIR_AQUAAIR.bin`}
        >
          <div className={styles.footerText}>
            <Icon type="download" /> {t('Footer.Links.Aqua_air_file')}
          </div>
        </a>
        <a
          href={`${BASE_URL}uploads/files/documents/Aldes-TOneAquaAIR-Util-35033190D-BD.pdf`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className={styles.footerText}>
            <Icon type="download" /> {t('Footer.Links.Aqua_air_manual')}
          </div>
        </a>
      </div>
      <div className={styles.languageSelect}>
        <Select
          defaultValue={lang}
          style={{ width: 120 }}
          size="large"
          onChange={changeLanguage}
        >
          <Option value="en">English</Option>
          <Option value="fr">Français</Option>
          <Option value="es">Español</Option>
        </Select>
      </div>
    </div>
  );
}
