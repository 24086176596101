import { combineReducers } from 'redux-immutable';

import auth from 'ducks/auth/reducer';
import config from 'ducks/config/reducer';
import fileHistory from 'ducks/fileHistory/reducer';
import machines from 'ducks/machines/reducer';
import machineSettings from 'ducks/machineSettings/reducer';
import projects from 'ducks/projects/reducer';
import user from 'ducks/user/reducer';

export function createReducer() {
  return combineReducers({
    auth,
    config,
    fileHistory,
    machines,
    machineSettings,
    projects,
    user,
  });
}
