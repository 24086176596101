import { createSelector } from 'reselect';

const getFileHistoryState = (state) => state.getIn(['fileHistory']);

export const fileHistoryEntriesSelector = createSelector(
  [getFileHistoryState],
  (fileHistory) => fileHistory.get('entries').toJS()
);

export const isRequestingFileHistorySelector = createSelector(
  [getFileHistoryState],
  (fileHistory) => fileHistory.get('isRequesting')
);

export const hasNextHistoryPageSelector = createSelector(
  [getFileHistoryState],
  (fileHistory) => fileHistory.get('hasNextPage')
);

export const fileHistoryCurrentPageSelector = createSelector(
  [getFileHistoryState],
  (fileHistory) => fileHistory.get('currentPage')
);

export const fileHistorySortingSelector = createSelector(
  [getFileHistoryState],
  (fileHistory) => fileHistory.get('sorting').toJS()
);
