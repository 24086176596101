import { createSelector } from 'reselect';
import { ROLES } from '../../constants';

const getUserData = (state) => state.getIn(['user', 'data']);
const getUsers = (state) => state.getIn(['user', 'users']);
const getIsRequestingUsers = (state) => state.getIn(['users', 'isRequesting']);

export const isLoggedInSelector = createSelector([getUserData], (userData) =>
  Boolean(userData.get('username'))
);

export const adminRoleSelector = createSelector([getUserData], (userData) =>
  userData.getIn(['roles']).toJS().includes(ROLES.ADMIN)
);

export const isExpertSelector = createSelector([getUserData], (userData) =>
  userData.getIn(['roles']).toJS().includes(ROLES.EXPERT)
);

export const userDataSelector = createSelector([getUserData], (userData) =>
  userData.toJS()
);

export const usersSelector = createSelector([getUsers], (users) =>
  users.toJS()
);

export const isRequestingUsersSelector = createSelector(
  [getIsRequestingUsers],
  (isRequesting) => isRequesting
);
