import { createSelector } from 'reselect';

const getAuthState = (state) => state.getIn(['auth']);

export const isLoginFailedSelector = createSelector([getAuthState], (auth) =>
  Boolean(auth.getIn(['error', 'statusCode']))
);

export const userCredentialsSelector = createSelector([getAuthState], (auth) =>
  auth.getIn(['credentials']).toJS()
);
