import { createAction } from 'redux-actions';

import { getTypeCreator } from 'utils';

const getType = getTypeCreator('@@app/machine');

// GET MACHINES IN CHUNKS (PAGES)
export const GET_MACHINES_REQUEST = getType('GET_MACHINES_REQUEST');
export const getMachinesRequest = createAction(GET_MACHINES_REQUEST);

export const GET_MACHINES_RESPONSE = getType('GET_MACHINES_RESPONSE');
export const getMachinesResponse = createAction(GET_MACHINES_RESPONSE);

export const GET_MACHINES_ERROR = getType('GET_MACHINES_ERROR');
export const getMachinesError = createAction(GET_MACHINES_ERROR);

// GET MACHINE BY ID
export const GET_MACHINE_BY_ID_REQUEST = getType('GET_MACHINE_BY_ID_REQUEST');
export const getMachineByIdRequest = createAction(GET_MACHINE_BY_ID_REQUEST);

export const GET_MACHINE_BY_ID_RESPONSE = getType('GET_MACHINE_BY_ID_RESPONSE');
export const getMachineByIdResponse = createAction(GET_MACHINE_BY_ID_RESPONSE);

export const GET_MACHINE_BY_ID_ERROR = getType('GET_MACHINE_BY_ID_ERROR');
export const getMachineByIdError = createAction(GET_MACHINE_BY_ID_ERROR);

// DOWNLOAD MACHINES FILE BY IDS
export const DOWNLOAD_MACHINES_FILE_BY_IDS_REQUEST = getType(
  'DOWNLOAD_MACHINES_FILE_BY_IDS_REQUEST'
);
export const downloadMachinesFileByIdsRequest = createAction(
  DOWNLOAD_MACHINES_FILE_BY_IDS_REQUEST
);

// DOWNLOAD ALL MACHINES FILE
export const DOWNLOAD_ALL_MACHINES_FILE_REQUEST = getType(
  'DOWNLOAD_ALL_MACHINES_FILE_REQUEST'
);
export const downloadAllMachinesFileRequest = createAction(
  DOWNLOAD_ALL_MACHINES_FILE_REQUEST
);

// UPDATE MACHINES BY IDS
export const UPDATE_MACHINES_REQUEST = getType('UPDATE_MACHINES_REQUEST');
export const updateMachinesRequest = createAction(UPDATE_MACHINES_REQUEST);

export const UPDATE_MACHINES_RESPONSE = getType('UPDATE_MACHINES_RESPONSE');
export const updateMachinesResponse = createAction(UPDATE_MACHINES_RESPONSE);

export const UPDATE_MACHINES_ERROR = getType('UPDATE_MACHINES_ERROR');
export const updateMachinesError = createAction(UPDATE_MACHINES_ERROR);

// CREATE NEW MACHINE
export const CREATE_MACHINE_REQUEST = getType('CREATE_MACHINE_REQUEST');
export const createMachineRequest = createAction(CREATE_MACHINE_REQUEST);

export const CREATE_MACHINE_RESPONSE = getType('CREATE_MACHINE_RESPONSE');
export const createMachineResponse = createAction(CREATE_MACHINE_RESPONSE);

export const CREATE_MACHINE_ERROR = getType('CREATE_MACHINE_ERROR');
export const createMachineError = createAction(CREATE_MACHINE_ERROR);

// CLONE MACHINE
export const CLONE_MACHINE_REQUEST = getType('CLONE_MACHINE_REQUEST');
export const cloneMachineRequest = createAction(CLONE_MACHINE_REQUEST);
