import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Select, InputNumber } from 'antd';
import PropTypes from 'prop-types';

import { FormInput } from 'components/ui/FormInput';

import { integerPattern } from '../../constants';

import styles from './MachineSettings.module.css';

const { Option } = Select;

const propTypes = {
  isAdmin: PropTypes.bool,
  values: PropTypes.object,
  selectedUnit: PropTypes.object,
  distributionTypes: PropTypes.array,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  handleChange: PropTypes.func,
  handleRegulationType: PropTypes.func,
};

export function AirDistributionSettings({
  isAdmin,
  values,
  selectedUnit,
  errors,
  distributionTypes,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleRegulationType,
}) {
  const [selectedParam, setSelectedParam] = useState(values.typeRegulationCp);
  const [areFieldsDisabled, setAreFieldsDisabled] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedParam(values.typeRegulationCp);
    setAreFieldsDisabled(values.typeRegulationCp !== 3);
  }, [values.typeRegulationCp]);

  const handleDistributionType = useCallback(
    (selected) => {
      if (+selected === 3) {
        setAreFieldsDisabled(false);
      } else {
        setAreFieldsDisabled(true);
      }
      // setFieldValue('typeRegulationCp', Number(selected));
      // setAreFieldsDisabled(+selected !== 3);
      handleRegulationType(Number(selected));
    },
    [handleRegulationType]
  );

  const getTradfromDistribType = (type) => {
    switch (type) {
      case 1:
        return t(
          'Machine_settings.Air_distribution_section.Inputs.Type_1.Label'
        );
      case 2:
        return t(
          'Machine_settings.Air_distribution_section.Inputs.Type_2.Label'
        );
      case 3:
        return t(
          'Machine_settings.Air_distribution_section.Inputs.Type_3.Label'
        );
      default:
        return '';
    }
  };

  return (
    <Col span={15} className={styles.MachineSettings}>
      <Row>
        <Col span={14} style={{ marginRight: '10px' }}>
          <div className={styles.columnLabel}>
            {t('Machine_settings.Air_distribution_section.Title')}
          </div>
          <div className={styles.field}>
            <div className={styles.formLabel}>
              {t('Machine_settings.Air_distribution_section.Inputs.Type.Label')}
            </div>
            <Form.Item className={styles.formItem}>
              <Select
                name="typeRegulationCp"
                value={
                  distributionTypes.length
                    ? getTradfromDistribType(
                        distributionTypes.find(
                          (type) => type.id === selectedParam
                        )?.id
                      )
                    : ''
                }
                onBlur={() => setFieldTouched('typeRegulationCp')}
                onChange={handleDistributionType}
              >
                {distributionTypes.map((type) => (
                  <Option key={type.id}>
                    {getTradfromDistribType(type.id)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <div className={styles.formLabel}>
                  {t(
                    'Machine_settings.Air_distribution_section.Inputs.Nominal_airflow.Label'
                  )}
                </div>
                <InputNumber
                  name="nominalAirflow"
                  value={values.nominalAirflow}
                  min={selectedUnit?.minValueNominalAirflow || 0}
                  max={selectedUnit?.maxValueNominalAirflow || undefined}
                  disabled={areFieldsDisabled}
                  defaultValue={values.nominalAirflow}
                  formatter={(newValue) =>
                    `${newValue} ${t('Common_terms.Measurements.Airflow')}`
                  }
                  parser={(newValue) => {
                    const preppedValue = Number(
                      newValue.replace(
                        ` ${t('Common_terms.Measurements.Airflow')}`,
                        ''
                      )
                    );

                    if (!preppedValue) {
                      return 0;
                    }

                    return Number.isNaN(preppedValue)
                      ? values.nominalAirflow
                      : preppedValue;
                  }}
                  onBlur={() => setFieldTouched('nominalAirflow')}
                  onChange={(newValue) => {
                    setFieldValue('nominalAirflow', newValue);
                  }}
                  onClick={(event) => event.target.select()}
                  className={styles.inputNumber}
                />
              </Col>
              <Col span={12}>
                <div className={styles.formLabel}>
                  {t(
                    'Machine_settings.Air_distribution_section.Inputs.External_pressure.Label'
                  )}
                </div>
                <InputNumber
                  name="nominalAirflowExternalStaticPressure"
                  disabled={areFieldsDisabled}
                  value={values.nominalAirflowExternalStaticPressure}
                  min={selectedUnit?.minValueExternalStaticPressure || 0}
                  max={
                    selectedUnit?.maxValueExternalStaticPressure || undefined
                  }
                  defaultValue={values.nominalAirflowExternalStaticPressure}
                  formatter={(newValue) =>
                    `${newValue} ${t('Common_terms.Measurements.Pressure')}`
                  }
                  parser={(newValue) => {
                    const preppedValue = Number(
                      newValue.replace(
                        ` ${t('Common_terms.Measurements.Pressure')}`,
                        ''
                      )
                    );

                    if (!preppedValue) {
                      return 0;
                    }

                    return Number.isNaN(preppedValue)
                      ? values.nominalAirflow
                      : preppedValue;
                  }}
                  onBlur={() =>
                    setFieldTouched('nominalAirflowExternalStaticPressure')
                  }
                  onChange={(newValue) => {
                    setFieldValue(
                      'nominalAirflowExternalStaticPressure',
                      newValue
                    );
                  }}
                  onClick={(event) => event.target.select()}
                  className={styles.inputNumber}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <div className={styles.formLabel}>
                  {t(
                    'Machine_settings.Air_distribution_section.Inputs.Minimal_airflow.Label'
                  )}
                </div>
                <InputNumber
                  name="minimalAirflowOneOutletOpen"
                  disabled={areFieldsDisabled}
                  value={values.minimalAirflowOneOutletOpen}
                  error={errors.minimalAirflowOneOutletOpen}
                  min={selectedUnit?.minValueNominalAirflowOneOutletOpened || 0}
                  max={
                    selectedUnit?.maxValueNominalAirflowOneOutletOpened ||
                    undefined
                  }
                  defaultValue={values.minimalAirflowOneOutletOpen}
                  formatter={(newValue) =>
                    `${newValue} ${t('Common_terms.Measurements.Airflow')}`
                  }
                  parser={(newValue) => {
                    const preppedValue = Number(
                      newValue.replace(
                        ` ${t('Common_terms.Measurements.Airflow')}`,
                        ''
                      )
                    );

                    if (!preppedValue) {
                      return 0;
                    }

                    return Number.isNaN(preppedValue)
                      ? values.nominalAirflow
                      : preppedValue;
                  }}
                  onBlur={() => setFieldTouched('minimalAirflowOneOutletOpen')}
                  onChange={(newValue) => {
                    setFieldValue('minimalAirflowOneOutletOpen', newValue);
                  }}
                  onClick={(event) => event.target.select()}
                  className={styles.inputNumber}
                />
              </Col>
              <Col span={12}>
                <div className={styles.formLabel}>
                  {t(
                    'Machine_settings.Air_distribution_section.Inputs.External_pressure.Label'
                  )}
                </div>
                <InputNumber
                  name="minimalAirflowOneOutletOpenExternalStaticPressure"
                  disabled={areFieldsDisabled}
                  value={
                    values.minimalAirflowOneOutletOpenExternalStaticPressure
                  }
                  min={
                    selectedUnit?.minValueExternalStaticPressureOneOutletOpened ||
                    0
                  }
                  max={
                    selectedUnit?.maxValueExternalStaticPressureOneOutletOpened ||
                    0
                  }
                  defaultValue={
                    values.minimalAirflowOneOutletOpenExternalStaticPressure
                  }
                  formatter={(newValue) =>
                    `${newValue} ${t('Common_terms.Measurements.Pressure')}`
                  }
                  parser={(newValue) => {
                    const preppedValue = Number(
                      newValue.replace(
                        ` ${t('Common_terms.Measurements.Pressure')}`,
                        ''
                      )
                    );

                    if (!preppedValue) {
                      return 0;
                    }

                    return Number.isNaN(preppedValue)
                      ? values.nominalAirflow
                      : preppedValue;
                  }}
                  onBlur={() =>
                    setFieldTouched(
                      'minimalAirflowOneOutletOpenExternalStaticPressure'
                    )
                  }
                  onChange={(newValue) => {
                    setFieldValue(
                      'minimalAirflowOneOutletOpenExternalStaticPressure',
                      newValue
                    );
                  }}
                  onClick={(event) => event.target.select()}
                  className={styles.inputNumber}
                />
              </Col>
            </Row>
          </div>
        </Col>
        {isAdmin && (
          <Col span={9}>
            <div>
              <div className={styles.columnLabel} />
            </div>
            <div className={styles.field}>
              <div className={styles.formLabel}>
                {t(
                  'Machine_settings.Air_distribution_section.Inputs.Airflow_therm_off.Label'
                )}
              </div>
              <FormInput
                styles={{
                  itemClassName: styles.formItem,
                  inputClassName: styles.formInputDescribe,
                }}
                name="airflowWithThermostatOff"
                type="number"
                value={values.airflowWithThermostatOff}
                error={errors.airflowWithThermostatOff}
                pattern={integerPattern}
                autoCorrect
                handleBlur={() => setFieldTouched('airflowWithThermostatOff')}
                handleChange={handleChange}
              />
            </div>
            <div className={styles.field}>
              <div className={styles.formLabel}>
                {t(
                  'Machine_settings.Air_distribution_section.Inputs.Airflow_defrost_cycle.Label'
                )}
              </div>
              <FormInput
                styles={{
                  itemClassName: styles.formItem,
                  inputClassName: styles.formInputDescribe,
                }}
                name="airflowDuringDefrostCycle"
                type="number"
                value={values.airflowDuringDefrostCycle}
                error={errors.airflowDuringDefrostCycle}
                pattern={integerPattern}
                autoCorrect
                handleBlur={() => setFieldTouched('airflowDuringDefrostCycle')}
                handleChange={handleChange}
              />
            </div>
            <div className={styles.field}>
              <div className={styles.formLabel}>
                {t(
                  'Machine_settings.Air_distribution_section.Inputs.Airduct_leakage.Label'
                )}
              </div>
              <FormInput
                styles={{
                  itemClassName: styles.formItem,
                  inputClassName: styles.formInputDescribe,
                }}
                name="airDuctDistributionLeakage"
                type="number"
                value={values.airDuctDistributionLeakage}
                error={errors.airDuctDistributionLeakage}
                pattern={integerPattern}
                autoCorrect
                handleBlur={() => setFieldTouched('airDuctDistributionLeakage')}
                handleChange={handleChange}
              />
            </div>
            <div className={styles.field}>
              <div className={styles.formLabel}>
                {t(
                  'Machine_settings.Air_distribution_section.Inputs.External_pressure.Label'
                )}
              </div>
              <FormInput
                styles={{
                  itemClassName: styles.formItemTable,
                  inputClassName: styles.formInputDescribe,
                }}
                name="externalStaticPressureAvailableThermostatOff"
                type="number"
                value={values.externalStaticPressureAvailableThermostatOff}
                error={errors.externalStaticPressureAvailableThermostatOff}
                pattern={integerPattern}
                autoCorrect
                handleBlur={() =>
                  setFieldTouched(
                    'externalStaticPressureAvailableThermostatOff'
                  )
                }
                handleChange={handleChange}
              />
            </div>
          </Col>
        )}
      </Row>
    </Col>
  );
}

AirDistributionSettings.propTypes = propTypes;
