// Sorting
export const PROJECTS_DEFAULT_SORTING = {
  order: 'descend',
  field: 'createdAt',
  columnKey: 'createdAt',
};

export const MACHINES_DEFAULT_SORTING = {
  order: 'descend',
  field: 'updatedAt',
  columnKey: 'updatedAt',
};

// Search
export const PROJECTS_SEARCHABLE_COLUMNS = [
  'type',
  'reference',
  'address',
  'postcode',
  'cityName',
];

export const MACHINES_SEARCHABLE_COLUMNS = ['label'];

// Records per page
export const RECORDS_PER_PAGE_OPTIONS = [10, 50, 100, 250];
