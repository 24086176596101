import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Input, Select, Checkbox, Button, Modal } from 'antd';
import PropTypes from 'prop-types';

import { RECORDS_PER_PAGE_OPTIONS } from '../../../constants/tables';

import styles from './ProjectsFilters.module.css';

const { Search } = Input;
const { Option } = Select;

const propTypes = {
  filters: PropTypes.object.isRequired,
  installers: PropTypes.arrayOf(PropTypes.object).isRequired,
  recordsPerPage: PropTypes.number.isRequired,
  selectedRecords: PropTypes.object.isRequired,
  resetSelectedRecords: PropTypes.func.isRequired,
  handleSearchInput: PropTypes.func.isRequired,
  handleInstallerInput: PropTypes.func.isRequired,
  handleShowDeletedInput: PropTypes.func.isRequired,
  handleRecordsPerPageInput: PropTypes.func.isRequired,
  handleDeleteProjects: PropTypes.func.isRequired,
  handleDownloadCSV: PropTypes.func.isRequired,
  isUpdatingProjects: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export function ProjectsFilters({
  filters,
  installers,
  recordsPerPage,
  selectedRecords,
  resetSelectedRecords,
  handleSearchInput,
  handleInstallerInput,
  handleShowDeletedInput,
  handleRecordsPerPageInput,
  handleDeleteProjects,
  handleDownloadCSV,
  isUpdatingProjects,
  isAdmin,
}) {
  /* Hooks */
  const { t } = useTranslation();

  /* States */
  const [deleteModal, setDeleteModal] = useState({
    visible: false,
    confirmLoading: false,
  });
  const [isConfigModalVisible, setIsConfigModalVisible] = useState(false);

  /* Effects */
  useEffect(() => {
    // Close the modal if it's currently opened
    // and the updating process has just completed.
    if (deleteModal.visible && !isUpdatingProjects) {
      setDeleteModal({
        confirmLoading: false,
        visible: false,
      });
      resetSelectedRecords();
    }
  }, [isUpdatingProjects, setDeleteModal, resetSelectedRecords]);

  /* Event handlers */
  // Delete button
  const openDeleteModal = useCallback(
    () => setDeleteModal((prevState) => ({ ...prevState, visible: true })),
    []
  );
  const closeDeleteModal = useCallback(
    () => setDeleteModal((prevState) => ({ ...prevState, visible: false })),
    []
  );
  const handleDeleteModalOkButton = useCallback(() => {
    setDeleteModal((prevState) => ({
      ...prevState,
      confirmLoading: true,
    }));

    handleDeleteProjects();
  }, [handleDeleteProjects]);

  // Config button
  const openConfigModal = useCallback(() => setIsConfigModalVisible(true), []);
  const closeConfigModal = useCallback(
    () => setIsConfigModalVisible(false),
    []
  );

  // Other
  const isRecordsSelected = !!selectedRecords.ids.length;

  return (
    <div className={styles.ProjectsFilters}>
      <Row type="flex" justify="space-between" gutter={[0, 16]}>
        <Col>
          <Search
            name="searchTerm"
            placeholder={t('Projects_filters.Inputs.Search_term.Placeholder')}
            allowClear
            value={filters.searchTerm}
            onChange={handleSearchInput}
            className={styles.searchInput}
          />
          {isAdmin && (
            <Select
              name="installer"
              placeholder={t('Projects_filters.Inputs.Installer.Placeholder')}
              allowClear
              showSearch
              loading={!installers.length}
              value={filters.installer || undefined}
              onChange={handleInstallerInput}
              dropdownMenuStyle={{ maxHeight: '165px' }}
              className={styles.installerInput}
              filterOption={(inputValue, { props: { searchkey } }) =>
                searchkey.toLowerCase().includes(inputValue.toLowerCase())
              }
            >
              {installers.map(({ id, username, name }) => (
                <Option key={id} value={username} searchkey={name}>
                  {name}
                </Option>
              ))}
            </Select>
          )}
          <Checkbox
            name="showDeleted"
            checked={filters.isShowDeleted}
            onChange={handleShowDeletedInput}
            className={styles.input}
          >
            {t('Projects_filters.Inputs.Show_deleted.Label')}
          </Checkbox>
        </Col>
        <Col>
          <Button
            icon="delete"
            disabled={!isRecordsSelected || filters.isShowDeleted}
            onClick={openDeleteModal}
            className={styles.button}
          >
            {t('Projects_filters.Delete_button')}
          </Button>
          <Modal
            title={t('Projects_filters.Modals.Delete.Title')}
            okText={t('Projects_filters.Modals.Delete.OK_button')}
            cancelText={t('Projects_filters.Modals.Delete.Cancel_button')}
            closable={false}
            maskClosable={false}
            visible={deleteModal.visible}
            confirmLoading={deleteModal.confirmLoading}
            onOk={handleDeleteModalOkButton}
            onCancel={closeDeleteModal}
          >
            <p>
              {t('Projects_filters.Modals.Delete.Content')}
              {selectedRecords.records.map((row) => row.reference).join(', ')}?
            </p>
          </Modal>
          <Button
            type="primary"
            onClick={handleDownloadCSV}
            className={styles.button}
          >
            {t('Projects_filters.Download_button')}
          </Button>
          <Button
            type="primary"
            onClick={openConfigModal}
            className={styles.button}
          >
            {t('Projects_filters.Config_button')}
          </Button>
          <Modal
            title={t('Projects_filters.Modals.Config.Title')}
            onCancel={closeConfigModal}
            visible={isConfigModalVisible}
            footer={[
              <Button key="ok" type="primary" onClick={closeConfigModal}>
                {t('Projects_filters.Modals.Config.OK_button')}
              </Button>,
            ]}
            width={550}
          >
            <pre>{t('Projects_filters.Modals.Config.Content')}</pre>
          </Modal>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" gutter={[0, 16]}>
        <Col>
          <Select
            name="recordsPerPage"
            value={recordsPerPage || undefined}
            onChange={handleRecordsPerPageInput}
            className={styles.recordsPerPageInput}
          >
            {RECORDS_PER_PAGE_OPTIONS.map((size) => (
              <Option key={size} value={size}>
                {size}
              </Option>
            ))}
          </Select>
          {t('Projects_filters.Inputs.Per_page')}
        </Col>
        <Col>
          {isRecordsSelected &&
            t('Projects_filters.Row_selection_hint', {
              count: selectedRecords.ids.length,
            })}
        </Col>
      </Row>
    </div>
  );
}

ProjectsFilters.propTypes = propTypes;
