import { useSelector, useDispatch } from 'react-redux';
// import Cookies from 'js-cookie';

import { loggedUserInfoRequest } from 'ducks/user/actions';
import { isLoggedInSelector } from 'ducks/user/selectors';
import { AUTH_TOKEN_KEY } from '../config';

export const useLoggedInCheck = () => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const isTokenSet = Boolean(localStorage.getItem(AUTH_TOKEN_KEY));
  const dispatch = useDispatch();

  if (isTokenSet) {
    dispatch(loggedUserInfoRequest());
  }
  return isLoggedIn || isTokenSet;
};
