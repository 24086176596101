import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';

const { Option } = Select;

export function FormSelect({
  name,
  value,
  required = true,
  optionList,
  idField = 'id',
  labelField = 'label',
  searchField,
  disabled,
  showSearch = false,
  allowClear = false,
  handleChange,
  handleBlur,
  tabIndex,
  styles: compStyles,
}) {
  const [ownError, setOwnError] = useState();
  const onChange = useCallback(
    (val) => {
      handleChange(name, val);
    },
    [handleChange, name]
  );

  useEffect(() => {
    const selected = optionList.filter((option) => option[idField] === value);
    if (!selected.length && required) {
      setOwnError('error');
    } else {
      setOwnError('');
    }
  }, [optionList, value, idField, required]);

  return (
    <Form.Item
      validateStatus={ownError ? 'error' : undefined}
      className={compStyles.itemClassName}
    >
      <Select
        name={name}
        value={value}
        disabled={disabled}
        showSearch={showSearch}
        allowClear={allowClear}
        onChange={onChange}
        onBlur={handleBlur}
        tabIndex={tabIndex}
        filterOption={(
          inputValue,
          { props: { value: optionValue, searchkey } }
        ) =>
          optionValue.toLowerCase().includes(inputValue.toLowerCase()) ||
          searchkey.toLowerCase().includes(inputValue.toLowerCase())
        }
      >
        {optionList.map((option) => (
          <Option
            key={option[idField]}
            value={option[idField]}
            searchkey={option[searchField]}
          >
            {option[labelField]}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  optionList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  idField: PropTypes.string,
  labelField: PropTypes.string,
  searchField: PropTypes.string,
  disabled: PropTypes.bool,
  showSearch: PropTypes.bool,
  allowClear: PropTypes.bool,
  tabIndex: PropTypes.number,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    itemClassName: PropTypes.string,
    inputClassName: PropTypes.string,
  }),
};
