import { createAction } from 'redux-actions';

import { getTypeCreator } from 'utils';

const getType = getTypeCreator('@@app/project');

// GET PROJECTS IN CHUNKS (PAGES)
export const GET_PROJECTS_REQUEST = getType('GET_PROJECTS_REQUEST');
export const getProjectsRequest = createAction(GET_PROJECTS_REQUEST);

export const GET_PROJECTS_RESPONSE = getType('GET_PROJECTS_RESPONSE');
export const getProjectsResponse = createAction(GET_PROJECTS_RESPONSE);

export const GET_PROJECTS_ERROR = getType('GET_PROJECTS_ERROR');
export const getProjectsError = createAction(GET_PROJECTS_ERROR);

// GET PROJECT BY ID
export const GET_PROJECT_BY_ID_REQUEST = getType('GET_PROJECT_BY_ID_REQUEST');
export const getProjectByIdRequest = createAction(GET_PROJECT_BY_ID_REQUEST);

export const GET_PROJECT_BY_ID_RESPONSE = getType('GET_PROJECT_BY_ID_RESPONSE');
export const getProjectByIdResponse = createAction(GET_PROJECT_BY_ID_RESPONSE);

export const GET_PROJECT_BY_ID_ERROR = getType('GET_PROJECT_BY_ID_ERROR');
export const getProjectByIdError = createAction(GET_PROJECT_BY_ID_ERROR);

// DOWNLOAD PROJECTS FILE BY IDS
export const DOWNLOAD_PROJECTS_FILE_BY_IDS_REQUEST = getType(
  'DOWNLOAD_PROJECTS_FILE_BY_IDS_REQUEST'
);
export const downloadProjectsFileByIdsRequest = createAction(
  DOWNLOAD_PROJECTS_FILE_BY_IDS_REQUEST
);

// DOWNLOAD ALL PROJECTS FILE
export const DOWNLOAD_ALL_PROJECTS_FILE_REQUEST = getType(
  'DOWNLOAD_ALL_PROJECTS_FILE_REQUEST'
);
export const downloadAllProjectsFileRequest = createAction(
  DOWNLOAD_ALL_PROJECTS_FILE_REQUEST
);

// UPDATE PROJECTS BY IDS
export const UPDATE_PROJECTS_REQUEST = getType('UPDATE_PROJECTS_REQUEST');
export const updateProjectsRequest = createAction(UPDATE_PROJECTS_REQUEST);

export const UPDATE_PROJECTS_RESPONSE = getType('UPDATE_PROJECTS_RESPONSE');
export const updateProjectsResponse = createAction(UPDATE_PROJECTS_RESPONSE);

export const UPDATE_PROJECTS_ERROR = getType('UPDATE_PROJECTS_ERROR');
export const updateProjectsError = createAction(UPDATE_PROJECTS_ERROR);

// CREATE NEW PROJECT
export const CREATE_PROJECT_REQUEST = getType('CREATE_PROJECT_REQUEST');
export const createProjectRequest = createAction(CREATE_PROJECT_REQUEST);

export const CREATE_PROJECT_RESPONSE = getType('CREATE_PROJECT_RESPONSE');
export const createProjectResponse = createAction(CREATE_PROJECT_RESPONSE);

export const CREATE_PROJECT_ERROR = getType('CREATE_PROJECT_ERROR');
export const createProjectError = createAction(CREATE_PROJECT_ERROR);

// GET PROJECTS PARAMS
export const GET_PROJECTS_PARAMS_REQUEST = getType(
  'GET_PROJECTS_PARAMS_REQUEST'
);
export const getProjectsParamsRequest = createAction(
  GET_PROJECTS_PARAMS_REQUEST
);

export const GET_PROJECTS_PARAMS_RESPONSE = getType(
  'GET_PROJECTS_PARAMS_RESPONSE'
);
export const getProjectsParamsResponse = createAction(
  GET_PROJECTS_PARAMS_RESPONSE
);

export const GET_PROJECTS_PARAMS_ERROR = getType('GET_PROJECTS_PARAMS_ERROR');
export const getProjectsParamsError = createAction(GET_PROJECTS_PARAMS_ERROR);
