import { all, fork } from 'redux-saga/effects';

import { authenticationMainSaga } from 'ducks/auth/sagas';
import { userInfoMainSaga } from 'ducks/user/sagas';
import { projectsMainSaga } from 'ducks/projects/sagas';
import { machinesMainSaga } from 'ducks/machines/sagas';
import { machineSettingsMainSaga } from 'ducks/machineSettings/sagas';
import { fileHistoryMainSaga } from 'ducks/fileHistory/sagas';

export function* rootSaga() {
  yield all([
    fork(authenticationMainSaga),
    fork(userInfoMainSaga),
    fork(projectsMainSaga),
    fork(machinesMainSaga),
    fork(machineSettingsMainSaga),
    fork(fileHistoryMainSaga),
  ]);
}
