import { request } from 'utils/request';
import { generatePath } from 'react-router';

import { ENDPOINTS } from '../../constants';

const getUserInfo = (userId) =>
  request.get(generatePath(ENDPOINTS.USER, { id: userId }));

const getUsers = (page = 1, itemsPerPage = 30) =>
  request.get(ENDPOINTS.USERS, {
    params: { page, items_per_page: itemsPerPage },
  });

export const userServices = { getUserInfo, getUsers };
