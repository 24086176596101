import { createAction } from 'redux-actions';

import { getTypeCreator } from 'utils';

const getType = getTypeCreator('@@app/history');

// GET FILE HISTORY ENTRIES IN CHUNKS (PAGES)
export const GET_FILE_HISTORY_REQUEST = getType('GET_FILE_HISTORY_REQUEST');
export const getFileHistoryRequest = createAction(GET_FILE_HISTORY_REQUEST);

export const GET_FILE_HISTORY_RESPONSE = getType('GET_FILE_HISTORY_RESPONSE');
export const getFileHistoryResponse = createAction(GET_FILE_HISTORY_RESPONSE);

export const GET_FILE_HISTORY_ERROR = getType('GET_FILE_HISTORY_ERROR');
export const getFileHistoryError = createAction(GET_FILE_HISTORY_ERROR);
