import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Table, Tooltip, Icon, Input } from 'antd';
import PropTypes from 'prop-types';

import { ROUTES } from 'constants/index';

import { getLocalizedSubTypeLabel } from 'utils';

import { langSelector } from 'ducks/config/selectors';

import { updateMachinesRequest } from 'ducks/machines/actions';
import {
  makeCellTextLocalizer,
  makeCellTextDateFormatter,
  makeCellTextLinkinizer,
  makeCellTextTooltipizer,
  makeCellTextHighlighter,
} from '../../../utils/tableUtils';

const editableSpanPropTypes = {
  innerHTML: PropTypes.string,
  text: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
};

function EditableSpan({ innerHTML, text, record }) {
  const dispatch = useDispatch();

  const [isEditingMode, setIsEditingMode] = useState(false);
  const [value, setValue] = useState(text);

  const handleChange = (event) => {
    const { value: newValue } = event.target;

    setValue(newValue);
  };

  const handleUpdate = (event) => {
    const { value: newLabel } = event.target;

    if (event.type === 'keydown' && event.key !== 'Enter') {
      return;
    }

    if (newLabel === text) {
      setIsEditingMode(false);
      return;
    }

    const toUpdateLabel = [
      {
        id: record.id,
        fields: { label: newLabel },
      },
    ];

    dispatch(
      updateMachinesRequest({
        machines: toUpdateLabel,
        parentProjectId: record.projectId,
      })
    );
    setIsEditingMode(false);
  };

  return isEditingMode ? (
    <Input
      value={value}
      defaultValue={text}
      onBlur={handleUpdate}
      onKeyDown={handleUpdate}
      onChange={handleChange}
      style={{
        width: '95%',
        height: '95%',
        padding: '4px',
      }}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
    />
  ) : (
    <div
      onClick={() => setIsEditingMode(true)}
      style={{ minWidth: '80px', minHeight: '20px' }}
    >
      <Tooltip title={text}>
        {innerHTML ? (
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={innerHTML}
          />
        ) : (
          <span>{text}</span>
        )}
      </Tooltip>
    </div>
  );
}

EditableSpan.propTypes = editableSpanPropTypes;

/* Cell Processor Helpers */
export const getTooltip = (text) => (
  <Tooltip title={text} placement="topLeft">
    {text}
  </Tooltip>
);
export const getSpan = (innerHTML, text) =>
  innerHTML ? (
    <span
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={innerHTML}
    />
  ) : (
    <span>{text || ''}</span>
  );
export const getEditableSpan = (innerHTML, text, record) => (
  <EditableSpan innerHTML={innerHTML} text={text} record={record} />
);
export const getLink = (content, path, id) => (
  <Link to={generatePath(path, { id })}>{content}</Link>
);

export const makeIconLink = (iconType) =>
  function (content, path, id) {
    return (
      <Link to={generatePath(path, { id })} style={{ fontSize: '20px' }}>
        <Icon type={iconType} />
      </Link>
    );
  };

const propTypes = {
  rowSelection: PropTypes.object.isRequired,
  projectRecords: PropTypes.arrayOf(PropTypes.object).isRequired,
  machineRecords: PropTypes.arrayOf(PropTypes.object).isRequired,
  filters: PropTypes.shape({ searchTerm: PropTypes.string }).isRequired,
  sorting: PropTypes.shape({ sortedInfo: PropTypes.object }).isRequired,
  handleTableChange: PropTypes.func.isRequired,
  isRequestingProjects: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export function ProjectsTable({
  rowSelection,
  projectRecords,
  machineRecords,
  filters,
  sorting,
  handleTableChange,
  isRequestingProjects,
  isAdmin,
}) {
  /* Hooks */
  const { t } = useTranslation();

  const lang = useSelector(langSelector);

  /* Projects (main) table config */
  const formatCellTextDate = makeCellTextDateFormatter(lang);
  const tooltipizeCellText = makeCellTextTooltipizer(getTooltip);

  const highlightCellText = makeCellTextHighlighter(
    getSpan,
    filters.searchTerm
  );
  const localizeTypeText = makeCellTextLocalizer(
    t,
    getLocalizedSubTypeLabel,
    highlightCellText
  );

  const linkinizeCellTextWithTooltip = makeCellTextLinkinizer(
    getLink,
    ROUTES.PROJECT_DETAILS,
    tooltipizeCellText
  );
  const preProcessCellText = makeCellTextHighlighter(
    getSpan,
    filters.searchTerm,
    linkinizeCellTextWithTooltip
  );

  const projectColumns = [
    {
      title: t('Projects_table.Column_titles.Type'),
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      sortOrder:
        sorting.sortedInfo.columnKey === 'type' && sorting.sortedInfo.order,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: localizeTypeText,
      ellipsis: true,
    },
    {
      title: t('Projects_table.Column_titles.Reference'),
      dataIndex: 'reference',
      sorter: true,
      sortOrder:
        sorting.sortedInfo.columnKey === 'reference' &&
        sorting.sortedInfo.order,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: preProcessCellText,
      ellipsis: true,
    },
    {
      title: t('Projects_table.Column_titles.Created_at'),
      dataIndex: 'createdAt',
      sorter: true,
      sortOrder:
        sorting.sortedInfo.columnKey === 'createdAt' &&
        sorting.sortedInfo.order,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: formatCellTextDate,
      ellipsis: true,
    },
    {
      title: t('Projects_table.Column_titles.Installer'),
      dataIndex: 'installer',
      ellipsis: true,
    },
    {
      title: t('Projects_table.Column_titles.Id_installer'),
      dataIndex: 'idInstaller',
      ellipsis: true,
    },
    {
      title: t('Projects_table.Column_titles.Address'),
      dataIndex: 'address',
      render: highlightCellText,
      ellipsis: true,
    },
    {
      title: t('Projects_table.Column_titles.Postcode'),
      dataIndex: 'postcode',
      render: highlightCellText,
      ellipsis: true,
    },
    {
      title: t('Projects_table.Column_titles.City_name'),
      dataIndex: 'cityName',
      sorter: true,
      sortOrder:
        sorting.sortedInfo.columnKey === 'cityName' && sorting.sortedInfo.order,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: highlightCellText,
      ellipsis: true,
    },
  ].reduce((acc, column) => {
    if (
      !isAdmin &&
      (column.dataIndex === 'installer' || column.dataIndex === 'idInstaller')
    ) {
      return acc;
    }
    return [...acc, column];
  }, []);

  /* Machines (nested) table config */
  const machineColumns = [
    {
      title: t('Machines_table.Column_titles.Client'),
      dataIndex: 'projectReference',
      key: 'projectReference',
      render: tooltipizeCellText,
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Id'),
      dataIndex: 'id',
      key: 'id',
      render: tooltipizeCellText,
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Label'),
      dataIndex: 'label',
      render: tooltipizeCellText,
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Saved_at'),
      dataIndex: 'updatedAt',
      render: formatCellTextDate,
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Downloaded_at'),
      dataIndex: 'downloadedAt',
      render: formatCellTextDate,
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Installer'),
      dataIndex: 'installer',
      render: tooltipizeCellText,
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Regulation_type'),
      dataIndex: 'regulationType',
      render: tooltipizeCellText,
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Mode_available'),
      dataIndex: 'modeAvailable',
      render: tooltipizeCellText,
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Units_ref'),
      dataIndex: 'unitsRef',
      render: tooltipizeCellText,
      ellipsis: true,
    },
  ].reduce((acc, column) => {
    if (!isAdmin && column.dataIndex === 'installer') {
      return acc;
    }
    return [...acc, column];
  }, []);

  return (
    <Table
      rowKey="id"
      columns={projectColumns}
      dataSource={projectRecords}
      rowSelection={rowSelection}
      onChange={handleTableChange}
      loading={isRequestingProjects}
      locale={{ emptyText: t('Projects_table.Empty_text') }}
      pagination={false}
      expandedRowRender={(projectRecord) => (
        <Table
          rowKey="id"
          pagination={false}
          columns={machineColumns}
          // onChange={handleNestedTableChange}
          locale={{ emptyText: t('Machines_table.Empty_text') }}
          dataSource={machineRecords.filter((machine) => {
            const withMachines =
              !!projectRecord.machines && projectRecord.machines.length;

            if (withMachines) {
              const machineIds = projectRecord.machines.map(({ id }) => id);

              return (
                ((!machine.deletedAt && !projectRecord.deletedAt) ||
                  (machine.deletedAt && projectRecord.deletedAt)) &&
                machineIds.includes(machine.id)
              );
            }
            return false;
          })}
        />
      )}
    />
  );
}

ProjectsTable.propTypes = propTypes;
