import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Input, Select, Button, Typography } from 'antd';
import PropTypes from 'prop-types';

import { getLocalizedSubTypeLabel } from 'utils';
import { sanitizeValue } from 'utils/formUtils';

import styles from './NewProjectForm.module.css';

const { Title } = Typography;
const { Option } = Select;

const propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldsError: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }),
  fields: PropTypes.object.isRequired,
  handleCreateProject: PropTypes.func.isRequired,
};

function NewProjectForm(props) {
  const { t } = useTranslation();

  const {
    form: { getFieldDecorator, getFieldsError, validateFields },
    fields,
    handleCreateProject,
  } = props;

  const { type, installer, country } = fields;

  const handleCreateButton = () => {
    validateFields();

    const isErrors = Object.values(getFieldsError()).some((field) => !!field);

    if (!isErrors) {
      handleCreateProject();
    }
  };

  return (
    <div className={styles.NewProjectForm}>
      <Form layout="vertical">
        <Row
          type="flex"
          justify="space-between"
          align="top"
          className={styles.sectionTitle}
        >
          <Col>
            <Title level={4} className={styles.title}>
              {t('New_project_form.Section_titles.General')}
            </Title>
          </Col>
          <Col>
            <Button type="primary" onClick={handleCreateButton}>
              {t('New_project_form.Create_button')}
            </Button>
          </Col>
        </Row>
        <Row type="flex" align="top">
          <Col>
            <Form.Item label={t('New_project_form.Inputs.Type.Label')}>
              {getFieldDecorator('type')(
                <Select name="type" className={styles.generalInput}>
                  {type.selectOptions.map(({ name, label }) => (
                    <Option key={name} value={name}>
                      {getLocalizedSubTypeLabel(t, label)}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label={t('New_project_form.Inputs.Reference.Label')}
              extra={t('New_project_form.Inputs.Reference.Extra')}
            >
              {getFieldDecorator('reference', {
                getValueFromEvent: (e) =>
                  sanitizeValue(e.currentTarget.value, 30, true).toUpperCase(),
                validateFirst: true,
                rules: [
                  { transform: (value) => value.trim() },
                  {
                    required: true,
                    message: t(
                      'New_project_form.Inputs.Reference.Messages.Required'
                    ),
                  },
                  {
                    min: 2,
                    message: t(
                      'New_project_form.Inputs.Reference.Messages.Min'
                    ),
                  },
                ],
              })(
                <Input
                  name="reference"
                  placeholder={t(
                    'New_project_form.Inputs.Reference.Placeholder'
                  )}
                  className={styles.generalInput}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" align="top">
          <Form.Item label={t('New_project_form.Inputs.Installer.Label')}>
            {getFieldDecorator('installer')(
              <Select
                name="installer"
                showSearch
                disabled={installer.disabled}
                className={styles.generalInput}
                filterOption={(inputValue, { props: { searchkey } }) =>
                  searchkey.toLowerCase().includes(inputValue.toLowerCase())
                }
              >
                {installer.selectOptions.map(({ id, username, name }) => (
                  <Option key={id} value={username} searchkey={name}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Row>
        <Row type="flex" align="top" className={styles.sectionTitle}>
          <Title level={4} className={styles.title}>
            {t('New_project_form.Section_titles.Client')}
          </Title>
        </Row>
        <Row type="flex" align="top">
          <Form.Item label={t('New_project_form.Inputs.Address.Label')}>
            {getFieldDecorator('address', {
              getValueFromEvent: (e) =>
                sanitizeValue(e.currentTarget.value, 100),
              validateFirst: true,
              rules: [
                {
                  min: 1,
                  message: t('New_project_form.Inputs.Address.Messages.Min'),
                },
              ],
            })(
              <Input
                name="address"
                placeholder={t('New_project_form.Inputs.Address.Placeholder')}
                className={styles.clientInput}
              />
            )}
          </Form.Item>
          <Form.Item label={t('New_project_form.Inputs.Postcode.Label')}>
            {getFieldDecorator('postcode', {
              getValueFromEvent: (e) => {
                const { value: currentValue } = fields.postcode;
                const newValue = sanitizeValue(e.currentTarget.value, 5);

                return /^[0-9]*$/gm.test(newValue) ? newValue : currentValue;
              },
              validateFirst: true,
              rules: [
                {
                  required: true,
                  message: t(
                    'New_project_form.Inputs.Postcode.Messages.Required'
                  ),
                },
                {
                  min: 5,
                  message: t('New_project_form.Inputs.Postcode.Messages.Min'),
                },
              ],
            })(
              <Input
                name="postcode"
                placeholder={t('New_project_form.Inputs.Postcode.Placeholder')}
                className={styles.clientInput}
              />
            )}
          </Form.Item>
        </Row>
        <Row type="flex" align="top">
          <Form.Item label={t('New_project_form.Inputs.City.Label')}>
            {getFieldDecorator('city', {
              getValueFromEvent: (e) =>
                sanitizeValue(e.currentTarget.value, 30),
              validateFirst: true,
              rules: [
                { transform: (value) => value.trim() },
                {
                  required: true,
                  message: t('New_project_form.Inputs.City.Messages.Required'),
                },
                {
                  min: 2,
                  message: t('New_project_form.Inputs.City.Messages.Min'),
                },
              ],
            })(
              <Input
                name="city"
                placeholder={t('New_project_form.Inputs.City.Placeholder')}
                className={styles.clientInput}
              />
            )}
          </Form.Item>
          <Form.Item label={t('New_project_form.Inputs.Country.Label')}>
            {getFieldDecorator('country')(
              <Select name="country" className={styles.generalInput}>
                {country.selectOptions.map(({ code, label }) => (
                  <Option key={code} value={label}>
                    {label}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}

NewProjectForm.propTypes = propTypes;

const WrappedNewProjectForm = Form.create({
  name: 'newProject',
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
  mapPropsToFields(props) {
    const { fields } = props;

    return {
      type: Form.createFormField({
        ...fields.type,
        value: fields.type.value,
      }),
      reference: Form.createFormField({
        ...fields.reference,
        reference: fields.reference.value,
      }),
      installer: Form.createFormField({
        ...props.fields.installer,
        installer: props.fields.installer.value,
      }),
      address: Form.createFormField({
        ...fields.address,
        address: fields.address.value,
      }),
      postcode: Form.createFormField({
        ...fields.postcode,
        postcode: fields.postcode.value,
      }),
      city: Form.createFormField({
        ...fields.city,
        city: fields.city.value,
      }),
      country: Form.createFormField({
        ...fields.country,
        country: fields.country.value,
      }),
    };
  },
})(NewProjectForm);

export default WrappedNewProjectForm;
