import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from 'redux';

import createSagaMiddleware from 'redux-saga';
import { fromJS } from 'immutable';

import { createReducer } from 'ducks/rootReducer';
import { rootSaga } from 'ducks/rootSaga';

const sagaMiddleware = createSagaMiddleware();

export function configureStore(initialState = fromJS({})) {
  const middlewares = [sagaMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          shouldHotReload: false,
        })
      : compose;

  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(...enhancers)
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
