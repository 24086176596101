export const getTypeCreator =
  (prefix = 'app') =>
  (typeName) =>
    `${prefix}/${typeName}`;

export const getLocalizedSubTypeLabel = (t, label) => {
  if (label === 'House or flat' || label === 'house_or_flat') {
    return t('Common_terms.Type_labels.House_or_flat');
  }
  if (
    label === 'Housing subdivision or building' ||
    label === 'subdivision_or_building'
  ) {
    return t('Common_terms.Type_labels.Subdivision_or_building');
  }
  return label;
};

export const camelToSentenceCase = (camelString) => {
  let sentenceString = camelString.replace(/([a-z])([A-Z])/g, '$1 $2');

  sentenceString = sentenceString
    .split(' ')
    .map((word, index) => {
      const newWord =
        (index === 0
          ? word.charAt(0).toUpperCase()
          : word.charAt(0).toLowerCase()) + word.slice(1);

      return newWord;
    })
    .join(' ');

  return sentenceString;
};

export const triggerDownload = (fileData, fileType = '', fileName = '') => {
  const a = document.createElement('a');
  const url = window.URL.createObjectURL(
    new Blob(Array.isArray(fileData) ? fileData : [fileData], {
      type: fileType,
    })
  );

  a.href = url;
  a.setAttribute('download', fileName);
  document.body.appendChild(a);

  a.click();
  a.remove();
};
