import moment from 'moment';

export const mapProjectsToRecords = (byId, isAdmin, userName, ids) => {
  let projectRecords = ids.map((id) => {
    const project = byId[id];

    const { installer, houseNumber, address } = project;
    const { name: installerName, username: installerUserName } = installer;

    return {
      ...project,
      installer: installerName,
      idInstaller: installerUserName,
      address: `${houseNumber || ''} ${address}`,
    };
  });

  if (!isAdmin) {
    projectRecords = projectRecords.filter(
      ({ idInstaller }) => idInstaller === userName
    );
  }

  return projectRecords;
};

export const mapMachinesToRecords = (
  byId = {},
  REGULATION_TYPES = {},
  MODES = {},
  ids
) =>
  (ids ? ids.map((id) => byId[id]) : Object.values(byId)).map((machine) => {
    const modeAvailable = MODES.CHAUFFAGE_SEUL
      ? MODES.CHAUFFAGE_SEUL.label
      : '';

    const regulationType = getRegulationType(
      machine.airToAirUnitsReference,
      REGULATION_TYPES
    );

    return {
      ...machine,
      installer: machine.installerName,
      regulationType,
      modeAvailable,
      unitsRef: machine.airToAirUnitsReference,
    };
  });

export const getRegulationType = (
  airToAirUnitsReference,
  REGULATION_TYPES = {}
) => {
  let regulationType = '';

  if (Object.keys(REGULATION_TYPES).length) {
    // TODO: Should we move this dependency to backend?
    if (airToAirUnitsReference?.includes('Aqua')) {
      regulationType = REGULATION_TYPES[2]?.label;
    } else {
      regulationType = REGULATION_TYPES[1]?.label;
    }
  }

  return regulationType;
};

export const getHighlightHTML = (text, searchTerm) => {
  const lowText = text.toLowerCase();
  const lowSearchTerm = searchTerm.toLowerCase();

  const splittedText = text.split('');
  const start = lowText.indexOf(lowSearchTerm);
  const end = start + searchTerm.length + 1;

  splittedText.splice(start, 0, '<span style="background-color: #f7f12a">');
  splittedText.splice(end, 0, '</span>');

  return splittedText.join('');
};

/* Cell text processors */
export const makeCellTextLocalizer =
  (t, translate, nextProcessor) => (text, record) => {
    const isTranslate = typeof translate === 'function';
    const isNextProcessor = typeof nextProcessor === 'function';

    const result = isTranslate ? translate(t, text) : text;

    return isNextProcessor ? nextProcessor(result, record) : result;
  };

export const makeCellTextDateFormatter =
  (lang, dateFormat = 'LL') =>
  (text) => {
    if (!text) {
      return '';
    }

    moment.locale('en');
    return moment(text).format(dateFormat);
  };

export const makeCellTextTooltipizer =
  (getTooltipComponent, nextProcessor) => (text, record) => {
    const isNextProcessor = typeof nextProcessor === 'function';

    const result = text ? getTooltipComponent(text) : '';

    return isNextProcessor ? nextProcessor(result, record) : result;
  };

export const makeCellTextLinkinizer =
  (getLinkComponent, path, nextProcessor) => (text, record) => {
    const { id } = record;
    const isNextProcessor = typeof nextProcessor === 'function';

    const result = text && path && id ? getLinkComponent(text, path, id) : '';

    return isNextProcessor ? nextProcessor(result, record) : result;
  };

export const makeCellTextHighlighter =
  (getSpanElement, searchTerm, nextProcessor) => (text, record) => {
    const lowText = text.toLowerCase();
    const lowSearchTerm = searchTerm.toLowerCase();
    const isNextProcessor = typeof nextProcessor === 'function';

    if (searchTerm.length >= 3 && lowText.includes(lowSearchTerm)) {
      const innerHighlightHTML = {
        __html: getHighlightHTML(text, searchTerm),
      };

      const result = getSpanElement(innerHighlightHTML, text, record);

      return isNextProcessor ? nextProcessor(result, record) : result;
    }

    const result = getSpanElement(undefined, text, record);

    return isNextProcessor ? nextProcessor(result, record) : text;
  };
