import React from 'react';
import { Form, Row, Col, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { FormInput } from 'components/ui/FormInput';

import { floatOneDigitPattern } from '../../constants';

import styles from './MachineSettings.module.css';

const { Option } = Select;

const propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  historyOptions: PropTypes.array,
  setError: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
};

export function SpecificOptions({
  values,
  errors,
  historyOptions,
  setError,
  handleChange,
  setFieldValue,
  setFieldTouched,
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.section}>
      <Row className={styles.narrowRow}>
        <div className={styles.columnLabel}>
          {t('Machine_settings.Specific_options_section.Title')}
        </div>
      </Row>
      <Row className={styles.row}>
        <Col span={6} xl={6}>
          <div className={styles.field}>
            <div className={styles.formLabel}>
              {t(
                'Machine_settings.Specific_options_section.Inputs.History_step.Label'
              )}
            </div>
            <Form.Item className={styles.formItem}>
              <Select
                name="historyStepMinutes"
                value={values.historyStepMinutes}
                onBlur={() => setFieldTouched('historyStepMinutes')}
                onChange={(selected) =>
                  setFieldValue('historyStepMinutes', selected)
                }
              >
                {historyOptions.map((option) => (
                  <Option key={option}>{option}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className={styles.field}>
            <div className={styles.formLabel}>
              {t(
                'Machine_settings.Specific_options_section.Inputs.Standard_airspeed.Label'
              )}
            </div>
            <FormInput
              styles={{
                itemClassName: styles.formItem,
                inputClassName: styles.formInputDescribe,
              }}
              name="standardAirspeedSetpointAtAirgrillsOutlet"
              value={values.standardAirspeedSetpointAtAirgrillsOutlet}
              error={errors.standardAirspeedSetpointAtAirgrillsOutlet}
              type="number"
              pattern={floatOneDigitPattern}
              minValue={2.0}
              maxValue={4.0}
              showTooltip
              handleBlur={() =>
                setFieldTouched('standardAirspeedSetpointAtAirgrillsOutlet')
              }
              handleChange={handleChange}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.formLabel}>
              {t(
                'Machine_settings.Specific_options_section.Inputs.Maximal_airspeed.Label'
              )}
            </div>
            <FormInput
              styles={{
                itemClassName: styles.formItem,
                inputClassName: styles.formInputDescribe,
              }}
              name="maximalAirspeedSetpointAtTheAirgrillsOutlet"
              value={values.maximalAirspeedSetpointAtTheAirgrillsOutlet}
              error={errors.maximalAirspeedSetpointAtTheAirgrillsOutlet}
              type="number"
              pattern={floatOneDigitPattern}
              minValue={2.0}
              maxValue={4.0}
              showTooltip
              handleBlur={() =>
                setFieldTouched('maximalAirspeedSetpointAtTheAirgrillsOutlet')
              }
              handleChange={handleChange}
            />
          </div>
        </Col>
        <Col span={7} xl={6} className={styles.col}>
          <div className={styles.field}>
            <div className={styles.formLabel}>
              {t(
                'Machine_settings.Specific_options_section.Inputs.Maximal_set_heating.Label'
              )}
            </div>
            <FormInput
              styles={{
                itemClassName: styles.formItem,
                inputClassName: styles.formInputDescribe,
              }}
              name="maximalSetTemperatureInHeatingOperation"
              value={values.maximalSetTemperatureInHeatingOperation}
              error={errors.maximalSetTemperatureInHeatingOperation}
              pattern={floatOneDigitPattern}
              type="number"
              minValue={16}
              maxValue={31}
              showTooltip
              setError={setError}
              handleBlur={() =>
                setFieldTouched('maximalSetTemperatureInHeatingOperation')
              }
              handleChange={handleChange}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.formLabel}>
              {t(
                'Machine_settings.Specific_options_section.Inputs.Minimal_set_heating.Label'
              )}
            </div>
            <FormInput
              styles={{
                itemClassName: styles.formItem,
                inputClassName: styles.formInputDescribe,
              }}
              name="minimalSetTemperatureInHeatingOperation"
              value={values.minimalSetTemperatureInHeatingOperation}
              error={errors.minimalSetTemperatureInHeatingOperation}
              pattern={floatOneDigitPattern}
              type="number"
              minValue={16}
              maxValue={31}
              showTooltip
              setError={setError}
              handleBlur={() =>
                setFieldTouched('minimalSetTemperatureInHeatingOperation')
              }
              handleChange={handleChange}
            />
          </div>
        </Col>
        <Col span={8} xl={7} className={styles.col}>
          <div className={styles.field}>
            <div className={styles.formLabel}>
              {t(
                'Machine_settings.Specific_options_section.Inputs.Maximal_set_cooling.Label'
              )}
            </div>
            <FormInput
              styles={{
                itemClassName: styles.formItem,
                inputClassName: styles.formInputDescribe,
              }}
              name="maximalSetTemperatureInCoolingOperation"
              value={values.maximalSetTemperatureInCoolingOperation}
              error={errors.maximalSetTemperatureInCoolingOperation}
              pattern={floatOneDigitPattern}
              type="number"
              minValue={16}
              maxValue={31}
              showTooltip
              setError={setError}
              handleBlur={() =>
                setFieldTouched('maximalSetTemperatureInCoolingOperation')
              }
              handleChange={handleChange}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.formLabel}>
              {t(
                'Machine_settings.Specific_options_section.Inputs.Minimal_set_cooling.Label'
              )}
            </div>
            <FormInput
              styles={{
                itemClassName: styles.formItem,
                inputClassName: styles.formInputDescribe,
              }}
              name="minimalSetTemperatureInCoolingOperation"
              value={values.minimalSetTemperatureInCoolingOperation}
              error={errors.minimalSetTemperatureInCoolingOperation}
              pattern={floatOneDigitPattern}
              type="number"
              minValue={16}
              maxValue={31}
              showTooltip
              setError={setError}
              handleBlur={() =>
                setFieldTouched('minimalSetTemperatureInCoolingOperation')
              }
              handleChange={handleChange}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

SpecificOptions.propTypes = propTypes;
