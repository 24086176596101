import { createSelector } from 'reselect';

const getProjectsState = (state) => state.getIn(['projects']);

export const projectsCountSelector = createSelector(
  [getProjectsState],
  (projects) => projects.get('projectsCount')
);

export const projectsSelector = createSelector([getProjectsState], (projects) =>
  projects.get('projects').toJS()
);

export const projectsIdsSelector = createSelector(
  [getProjectsState],
  (projects) => projects.get('projectsIds').toJS()
);

export const projectSelector = createSelector([getProjectsState], (projects) =>
  projects.get('project').toJS()
);

export const projectsParamsSelector = createSelector(
  [getProjectsState],
  (projects) => projects.get('params').toJS()
);

export const isRequestingProjectsSelector = createSelector(
  [getProjectsState],
  (projects) => projects.get('isRequesting')
);

export const isUpdatingProjectsSelector = createSelector(
  [getProjectsState],
  (projects) => projects.get('isUpdating')
);
