import { call, put, takeLatest, all } from 'redux-saga/effects';
import { generatePath } from 'react-router';

import { mapListToObject } from 'helpers/common';

import {
  GET_PROJECTS_REQUEST,
  GET_PROJECT_BY_ID_REQUEST,
  DOWNLOAD_ALL_PROJECTS_FILE_REQUEST,
  DOWNLOAD_PROJECTS_FILE_BY_IDS_REQUEST,
  UPDATE_PROJECTS_REQUEST,
  CREATE_PROJECT_REQUEST,
  GET_PROJECTS_PARAMS_REQUEST,
  getProjectsResponse,
  getProjectsError,
  getProjectByIdResponse,
  getProjectByIdError,
  updateProjectsResponse,
  updateProjectsError,
  createProjectError,
  getProjectsParamsResponse,
  getProjectsParamsError,
} from 'ducks/projects/actions';
import { createMachineRequest } from 'ducks/machines/actions';

import { projectsServices } from 'ducks/projects/services';
import { machinesServices } from 'ducks/machines/services';

import { triggerDownload } from 'utils';

import { ROUTES, ENDPOINTS } from '../../constants';

const DEFAULT_PROJECTS_COUNT = 4000;

function* getProjectsSaga(action) {
  try {
    const page = action.payload && action.payload.page;
    const perPage = action.payload && action.payload.perPage;
    const searchTerm = action.payload && action.payload.searchTerm;
    const installerId = action.payload && action.payload.installerId;
    const isDeleted = action.payload && action.payload.isDeleted;
    const sortByType = action.payload && action.payload.sortByType;
    const sortByReference = action.payload && action.payload.sortByReference;
    const sortByCreation = action.payload && action.payload.sortByCreation;
    const sortByCity = action.payload && action.payload.sortByCity;

    const projects = yield call(projectsServices.getProjects, {
      page,
      perPage,
      searchTerm,
      installerId,
      isDeleted,
      sortByType,
      sortByReference,
      sortByCreation,
      sortByCity,
    });

    yield put(getProjectsResponse({ projects, page }));
  } catch (error) {
    yield put(getProjectsError({ error }));
  }
}

function* getProjectByIdSaga(action) {
  try {
    const project = yield call(
      projectsServices.getProject,
      action.payload?.projectId
    );
    const { machines } = project;

    const completeMachines = yield all(
      machines.map(({ id }) => call(machinesServices.getMachine, id))
    );

    const updatedProject = {
      ...project,
      machines: [...completeMachines],
    };

    yield put(
      getProjectByIdResponse({
        project: updatedProject,
      })
    );
    yield put(updateProjectsResponse({ projects: [updatedProject] }));
  } catch (error) {
    yield put(getProjectByIdError({ error }));
  }
}

function* downloadProjectsFileByIdsSaga({ payload: { projectIds } }) {
  try {
    const file = yield call(projectsServices.getProjectsFileByIds, projectIds);

    // TODO: The file name is TBD
    yield call(triggerDownload, file, 'text/csv', 'selected_projects.csv');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* downloadAllProjectsFileSaga() {
  try {
    const file = yield call(
      projectsServices.getProjectsFile,
      1,
      DEFAULT_PROJECTS_COUNT
    );

    // TODO: The file name is TBD
    yield call(triggerDownload, file, 'text/csv', 'all_projects.csv');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* updateProjectsSaga({ payload: { projects } }) {
  try {
    const updatedProjects = yield all(
      projects.map(({ id, fields }) =>
        call(projectsServices.updateProject, id, fields)
      )
    );

    // Get rid of the 'installer' field that is inconsistent
    // with the already loaded projects' 'installer' field
    // (ruining the ImmutableJS' deep merge process).
    const preppedProjects = updatedProjects.map((project) => {
      const { installer, ...projectWithoutInstaller } = project;
      return projectWithoutInstaller;
    });

    yield put(updateProjectsResponse({ projects: preppedProjects }));
  } catch (error) {
    yield put(updateProjectsError({ error }));
  }
}

function* createProjectSaga({
  payload: { fields, blankMachineLabel, redirectPath },
}) {
  try {
    const project = yield call(projectsServices.createProject, fields);

    if (typeof blankMachineLabel === 'string' && blankMachineLabel.length > 0) {
      yield put(
        createMachineRequest({
          fields: {
            project: generatePath(`api/${ENDPOINTS.PROJECT}`, {
              id: project.id,
            }),
            label: blankMachineLabel,
          },
          parentProjectId: project.id,
        })
      );
    }

    if (redirectPath) {
      if (typeof redirectPath === 'string' && redirectPath.length > 0) {
        window.location.href = redirectPath;
        return;
      }

      const defaultPath = generatePath(ROUTES.PROJECT_DETAILS, {
        id: project.id,
      });
      window.location.href = defaultPath;
    }
  } catch (error) {
    yield put(createProjectError({ error }));
  }
}

function* getProjectsParamsSaga() {
  try {
    const subTypes = yield call(projectsServices.getProjectsSubtypes);
    const regulationTypes = yield call(projectsServices.getRegulationTypes);
    const modes = yield call(projectsServices.getModes);
    const unitsRefs = yield call(projectsServices.getReferenceUnits);
    const countries = yield call(projectsServices.getCountries);

    const preppedSubtypes = mapListToObject(subTypes, 'name', (key) =>
      key.toUpperCase()
    );
    const preppedRegulationTypes = mapListToObject(regulationTypes);
    const preppedModes = mapListToObject(modes, 'label', (key) =>
      key.split(' ').join('_').toUpperCase()
    );
    const preppedUnitsRefs = mapListToObject(unitsRefs);
    const preppedCountries = mapListToObject(countries, 'code');

    const params = {
      SUBTYPES: preppedSubtypes,
      REGULATION_TYPES: preppedRegulationTypes,
      MODES: preppedModes,
      UNITS_REFERENCES: preppedUnitsRefs,
      COUNTRIES: preppedCountries,
    };

    yield put(getProjectsParamsResponse({ params }));
  } catch (error) {
    yield put(getProjectsParamsError({ error }));
  }
}

export function* projectsMainSaga() {
  yield takeLatest(GET_PROJECTS_REQUEST, getProjectsSaga);
  yield takeLatest(GET_PROJECT_BY_ID_REQUEST, getProjectByIdSaga);
  yield takeLatest(
    DOWNLOAD_PROJECTS_FILE_BY_IDS_REQUEST,
    downloadProjectsFileByIdsSaga
  );
  yield takeLatest(
    DOWNLOAD_ALL_PROJECTS_FILE_REQUEST,
    downloadAllProjectsFileSaga
  );
  yield takeLatest(UPDATE_PROJECTS_REQUEST, updateProjectsSaga);
  yield takeLatest(CREATE_PROJECT_REQUEST, createProjectSaga);
  yield takeLatest(GET_PROJECTS_PARAMS_REQUEST, getProjectsParamsSaga);
}
