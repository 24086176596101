import React, { useState, useEffect, useCallback } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  getMachinesRequest,
  updateMachinesRequest,
  downloadMachinesFileByIdsRequest,
  downloadAllMachinesFileRequest,
} from 'ducks/machines/actions';

import { ROLES } from '../../constants';
import { MACHINES_DEFAULT_SORTING } from '../../constants/tables';

import { mapMachinesToRecords } from '../../utils/tableUtils';

import { MachinesTable } from './MachinesTable';
import { MachinesFilters } from './MachinesFilters';
import { debounce } from '../../helpers/common';

const propTypes = {
  users: PropTypes.object.isRequired,
  machinesCount: PropTypes.number.isRequired,
  machines: PropTypes.object.isRequired,
  machinesIds: PropTypes.array.isRequired,
  PARAMS: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isRequestingMachines: PropTypes.bool.isRequired,
  isUpdatingMachines: PropTypes.bool.isRequired,
};

export function MachinesTabPane({
  users,
  machinesCount,
  machines,
  machinesIds,
  PARAMS,
  isRequestingMachines,
  isUpdatingMachines,
  isAdmin,
}) {
  /* Hooks */
  const dispatch = useDispatch();

  const initialFilters = {
    searchTerm: '',
    searchMachineId: '',
    installer: '',
    unitsRef: '',
    isShowDeleted: false,
  };
  const initialSorting = {
    sortedInfo: MACHINES_DEFAULT_SORTING,
  };
  const initialSelectedRecords = {
    ids: [],
    records: [],
  };

  /* States */
  const [machineRecords, setMachineRecords] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [sorting, setSorting] = useState(initialSorting);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [selectedRecords, setSelectedRecords] = useState(
    initialSelectedRecords
  );

  /* Select inputs' option lists */
  const installers = Object.values(users).filter(
    ({ roles }) =>
      roles.includes(ROLES.INSTALLER) || roles.includes(ROLES.ADMIN)
  );
  const unitsRefs = Object.values(PARAMS.UNITS_REFERENCES);

  const selectedInstallerId = installers.find(
    ({ username }) => username === filters.installer
  )?.id;
  const selectedUnitsRefId = unitsRefs.find(
    ({ model }) => model === filters.unitsRef
  )?.id;

  /* Effects */
  useEffect(() => {
    const records = mapMachinesToRecords(
      machines,
      PARAMS.REGULATION_TYPES,
      PARAMS.MODES,
      machinesIds
    );

    setMachineRecords(records);
  }, [machines, filters, PARAMS.REGULATION_TYPES, PARAMS.MODES]);

  const debouncedMachinesRequest = useCallback(
    debounce((params) => dispatch(getMachinesRequest(params)), 300),
    [dispatch, getMachinesRequest]
  );

  useEffect(() => {
    if (isUpdatingMachines === false) {
      const { field, order } = sorting.sortedInfo;
      const orderName = order === 'ascend' ? 'asc' : 'desc';

      debouncedMachinesRequest({
        page: currentPage,
        perPage: recordsPerPage,
        searchLabel: filters.searchTerm,
        searchMachineId: filters.searchMachineId,
        isDeleted: filters.isShowDeleted,
        installerId: selectedInstallerId,
        unitsRefId: selectedUnitsRefId,
        sortByType: field === 'type' ? orderName : null,
        sortByRef: field === 'projectReference' ? orderName : null,
        sortBySaveDate: field === 'updatedAt' ? orderName : null,
        sortByDownloadDate: field === 'downloadedAt' ? orderName : null,
      });
    }
  }, [
    dispatch,
    isUpdatingMachines,
    currentPage,
    recordsPerPage,
    filters.isShowDeleted,
    selectedInstallerId,
    selectedUnitsRefId,
    filters.searchTerm,
    filters.searchMachineId,
    sorting.sortedInfo,
  ]);

  useEffect(() => {
    if (
      !sorting.sortedInfo.order &&
      sorting.sortedInfo.columnKey !== 'updatedAt'
    ) {
      setSorting({
        sortedInfo: MACHINES_DEFAULT_SORTING,
      });
    }
  }, [sorting]);

  /* Table config */
  const rowSelection = {
    selectedRowKeys: selectedRecords.ids,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRecords({
        ids: selectedRowKeys,
        records: selectedRows,
      });
    },
  };

  /* Event handlers */
  const handleTableChange = useCallback((page, f, sorter) => {
    setCurrentPage(1);
    setSorting({ sortedInfo: sorter });
  }, []);

  const handleSearchInput = useCallback((event) => {
    const { value: currentSearchTerm } = event.target;

    setCurrentPage(1);
    setFilters((prevState) => ({
      ...prevState,
      searchTerm: currentSearchTerm,
    }));
  }, []);

  const handleSearchByIdInput = useCallback((event) => {
    const { value: currentSearchMachineId } = event.target;

    setCurrentPage(1);
    setFilters((prevState) => ({
      ...prevState,
      searchMachineId: currentSearchMachineId,
    }));
  }, []);

  const handleInstallerInput = useCallback((currentInstaller) => {
    setCurrentPage(1);
    setFilters((prevState) => ({
      ...prevState,
      installer: currentInstaller,
    }));
  }, []);

  const handleUnitsRefInput = useCallback((currentUnitsRef) => {
    setCurrentPage(1);
    setFilters((prevState) => ({
      ...prevState,
      unitsRef: currentUnitsRef,
    }));
  }, []);

  const handleShowDeletedInput = useCallback((event) => {
    setCurrentPage(1);
    setFilters((prevState) => ({
      ...prevState,
      isShowDeleted: event.target.checked,
    }));
  }, []);

  const handleRecordsPerPageInput = useCallback((currentRecordsPerPage) => {
    setCurrentPage(1);
    setRecordsPerPage(currentRecordsPerPage);
  }, []);

  const handleDeleteMachines = () => {
    const today = moment().toISOString();
    const toMarkAsDeleted = selectedRecords.ids.map((id) => ({
      id,
      fields: { deletedAt: today },
    }));

    dispatch(
      updateMachinesRequest({
        machines: toMarkAsDeleted,
      })
    );
  };

  const handleDownloadCSV = () => {
    const { ids } = selectedRecords;

    if (ids.length) {
      dispatch(downloadMachinesFileByIdsRequest({ machineIds: ids }));
      return;
    }

    dispatch(downloadAllMachinesFileRequest());
  };

  const resetSelectedRecords = () => {
    setSelectedRecords({
      ids: [],
      records: [],
    });
  };

  const infiniteRef = useInfiniteScroll({
    loading: isRequestingMachines,
    hasNextPage:
      machinesCount > 0 &&
      Math.ceil(machinesCount / recordsPerPage) !== currentPage,
    onLoadMore: () => setCurrentPage((page) => page + 1),
  });

  return (
    <div ref={infiniteRef}>
      <MachinesFilters
        filters={filters}
        installers={installers}
        unitsRefs={unitsRefs}
        recordsPerPage={recordsPerPage}
        selectedRecords={selectedRecords}
        resetSelectedRecords={resetSelectedRecords}
        handleSearchInput={handleSearchInput}
        handleSearchByIdInput={handleSearchByIdInput}
        handleInstallerInput={handleInstallerInput}
        handleUnitsRefInput={handleUnitsRefInput}
        handleShowDeletedInput={handleShowDeletedInput}
        handleRecordsPerPageInput={handleRecordsPerPageInput}
        handleDeleteMachines={handleDeleteMachines}
        handleDownloadCSV={handleDownloadCSV}
        isAdmin={isAdmin}
        isUpdatingMachines={isUpdatingMachines}
      />
      <MachinesTable
        filters={filters}
        sorting={sorting}
        rowSelection={rowSelection}
        machineRecords={machineRecords}
        handleTableChange={handleTableChange}
        isRequestingMachines={isRequestingMachines}
        isAdmin={isAdmin}
      />
    </div>
  );
}

MachinesTabPane.propTypes = propTypes;
