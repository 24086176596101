import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button } from 'antd';
import PropTypes from 'prop-types';
import { sanitizeValue, removeNonHexCharacters } from 'utils/formUtils';

import styles from './OptionsForm.module.css';
import { REVERSIBLE_TYPES } from '../../constants';
import { Checkbox } from '../ui';

const propTypes = {
  form: PropTypes.object.isRequired,
  isIdFound: PropTypes.bool.isRequired,
  handleFileDownload: PropTypes.func.isRequired,
};

function OptionsForm(props) {
  const { t } = useTranslation();

  const {
    form: { getFieldDecorator },
    isIdFound,
    handleFileDownload,
  } = props;

  return (
    <div className={styles.OptionsForm}>
      <Form layout="vertical">
        <Form.Item label="ID_IHM" className={styles.input}>
          {getFieldDecorator('idIhm', {
            getValueFromEvent: (e) =>
              removeNonHexCharacters(sanitizeValue(e.currentTarget.value, 8)),
          })(
            <Input
              name="idIhm"
              placeholder={t('Options_page.Input.Placeholder')}
            />
          )}
        </Form.Item>
        <Form.Item
          label={t('Options_page.Customer_name')}
          className={styles.input}
        >
          {getFieldDecorator('customerName')(
            <Input
              name="customerName"
              placeholder={t('Options_page.Customer_name')}
            />
          )}
        </Form.Item>
        <Form.Item className={styles.input}>
          {getFieldDecorator('isAfterSale')(
            <Checkbox name="isAfterSale">
              {t('Options_page.After_sale')}
            </Checkbox>
          )}
        </Form.Item>
        <Form.Item>
          <Button
            className={styles.button}
            type="primary"
            onClick={() => handleFileDownload(REVERSIBLE_TYPES.T_ONE_LOAD)}
            disabled={!isIdFound}
          >
            {t('Options_page.Download_t_one_button')}
          </Button>
          <Button
            type="primary"
            onClick={() => handleFileDownload(REVERSIBLE_TYPES.AQUA_AIR_LOAD)}
            disabled={!isIdFound}
          >
            {t('Options_page.Download_aqua_air_button')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

OptionsForm.propTypes = propTypes;

const WrappedOptionsForm = Form.create({
  name: 'optionsForm',
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
  mapPropsToFields(props) {
    const { fields } = props;

    return {
      idIhm: Form.createFormField({
        ...fields.idIhm,
        value: fields.idIhm.value,
      }),
      customerName: Form.createFormField({
        ...fields.customerName,
        value: fields.customerName.value,
      }),
      isAfterSale: Form.createFormField({
        ...fields.isAfterSale,
        value: fields.isAfterSale.value,
      }),
    };
  },
})(OptionsForm);

export default WrappedOptionsForm;
