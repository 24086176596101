import React, { useState, useEffect, useCallback } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import { debounce, mapListToObject } from 'helpers/common';

import {
  getProjectsRequest,
  updateProjectsRequest,
  downloadAllProjectsFileRequest,
  downloadProjectsFileByIdsRequest,
} from 'ducks/projects/actions';
import { updateMachinesRequest } from 'ducks/machines/actions';

import { ROLES } from '../../constants';
import { PROJECTS_DEFAULT_SORTING } from '../../constants/tables';

import {
  mapProjectsToRecords,
  mapMachinesToRecords,
} from '../../utils/tableUtils';

import { ProjectsTable } from './ProjectsTable';
import { ProjectsFilters } from './ProjectsFilters';

const propTypes = {
  users: PropTypes.object.isRequired,
  userName: PropTypes.string.isRequired,
  projectsCount: PropTypes.number.isRequired,
  projects: PropTypes.object.isRequired,
  projectsIds: PropTypes.array.isRequired,
  PARAMS: PropTypes.object.isRequired,
  isUpdatingProjects: PropTypes.bool.isRequired,
  isRequestingProjects: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export function ProjectsTabPane({
  users,
  userName,
  projects,
  projectsIds,
  projectsCount,
  PARAMS,
  isUpdatingProjects,
  isRequestingProjects,
  isAdmin,
}) {
  /* Hooks */
  const dispatch = useDispatch();

  const initialFilters = {
    searchTerm: '',
    installer: '',
    isShowDeleted: false,
  };
  const initialSorting = {
    sortedInfo: PROJECTS_DEFAULT_SORTING,
  };

  /* States */
  const [projectRecords, setProjectRecords] = useState([]);
  const [machineRecords, setMachineRecords] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [sorting, setSorting] = useState(initialSorting);
  // const [nestedSorting, setNestedSorting] = useState({
  //   sortedInfo: {},
  // });
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [selectedRecords, setSelectedRecords] = useState({
    ids: [],
    records: [],
  });

  /* Select inputs' option lists */
  const installers = Object.values(users).filter(
    ({ roles }) =>
      roles.includes(ROLES.INSTALLER) || roles.includes(ROLES.ADMIN)
  );

  const selectedInstallerId = installers.find(
    ({ username }) => username === filters.installer
  )?.id;

  /* Effects */
  useEffect(() => {
    const records = mapProjectsToRecords(
      projects,
      isAdmin,
      userName,
      projectsIds
    );
    const allNestedMachines = mapListToObject(
      records.reduce(
        (acc, project) => [
          ...acc,
          ...project.machines.map((machine) => ({
            ...machine,
            projectId: project.id,
            projectReference: project.reference,
          })),
        ],
        []
      )
    );

    setProjectRecords(records);
    setMachineRecords(
      mapMachinesToRecords(
        allNestedMachines,
        PARAMS.REGULATION_TYPES,
        PARAMS.MODES
      )
    );
  }, [
    projects,
    isAdmin,
    userName,
    filters,
    PARAMS.REGULATION_TYPES,
    PARAMS.MODES,
  ]);

  const debouncedProjectsRequest = useCallback(
    debounce((params) => dispatch(getProjectsRequest(params)), 300),
    [dispatch, getProjectsRequest]
  );

  useEffect(() => {
    if (isUpdatingProjects === false) {
      const { field, order } = sorting.sortedInfo;
      const orderName = order === 'ascend' ? 'asc' : 'desc';

      debouncedProjectsRequest({
        page: currentPage,
        perPage: recordsPerPage,
        isDeleted: filters.isShowDeleted,
        installerId: selectedInstallerId,
        searchTerm: filters.searchTerm,
        sortByType: field === 'type' ? orderName : null,
        sortByReference: field === 'reference' ? orderName : null,
        sortByCreation: field === 'createdAt' ? orderName : null,
        sortByCity: field === 'cityName' ? orderName : null,
      });
    }
  }, [
    dispatch,
    isUpdatingProjects,
    currentPage,
    recordsPerPage,
    filters.isShowDeleted,
    selectedInstallerId,
    filters.searchTerm,
    sorting.sortedInfo,
  ]);

  useEffect(() => {
    if (
      !sorting.sortedInfo.order &&
      sorting.sortedInfo.columnKey !== 'createdAt'
    ) {
      setSorting({
        sortedInfo: PROJECTS_DEFAULT_SORTING,
      });
    }
  }, [sorting]);

  /* Table config */
  const rowSelection = {
    selectedRowKeys: selectedRecords.ids,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRecords({
        ids: selectedRowKeys,
        records: selectedRows,
      });
    },
  };

  /* Event handlers */
  const handleTableChange = useCallback((page, f, sorter) => {
    setCurrentPage(1);
    setSorting({ sortedInfo: sorter });
  }, []);

  const handleSearchInput = useCallback((event) => {
    const { value: currentSearchTerm } = event.target;

    setCurrentPage(1);
    setFilters((prevState) => ({
      ...prevState,
      searchTerm: currentSearchTerm,
    }));
  }, []);

  const handleInstallerInput = useCallback((currentInstaller) => {
    setCurrentPage(1);
    setFilters((prevState) => ({
      ...prevState,
      installer: currentInstaller,
    }));
  }, []);

  const handleShowDeletedInput = useCallback((event) => {
    setCurrentPage(1);
    setFilters((prevState) => ({
      ...prevState,
      isShowDeleted: event.target.checked,
    }));
  }, []);

  const handleRecordsPerPageInput = useCallback((currentRecordsPerPage) => {
    setCurrentPage(1);
    setRecordsPerPage(currentRecordsPerPage);
  }, []);

  const handleDeleteProjects = () => {
    const today = moment().toISOString();
    const machinesToMarkAsDeleted = machineRecords
      .filter(({ projectId }) => selectedRecords.ids.includes(projectId))
      .map(({ id }) => ({
        id,
        fields: { deletedAt: today },
      }));
    const projectsToMarkAsDeleted = selectedRecords.ids.map((id) => ({
      id,
      fields: { deletedAt: today },
    }));

    dispatch(
      updateMachinesRequest({
        machines: machinesToMarkAsDeleted,
      })
    );
    dispatch(
      updateProjectsRequest({
        projects: projectsToMarkAsDeleted,
      })
    );
  };

  const handleDownloadCSV = () => {
    const { ids } = selectedRecords;

    if (ids.length) {
      dispatch(downloadProjectsFileByIdsRequest({ projectIds: ids }));
      return;
    }

    dispatch(downloadAllProjectsFileRequest());
  };

  const resetSelectedRecords = () => {
    setSelectedRecords({
      ids: [],
      records: [],
    });
  };

  const infiniteRef = useInfiniteScroll({
    loading: isRequestingProjects,
    hasNextPage:
      projectsCount > 0 &&
      Math.ceil(projectsCount / recordsPerPage) !== currentPage,
    onLoadMore: () => setCurrentPage((page) => page + 1),
  });

  return (
    <div ref={infiniteRef}>
      <ProjectsFilters
        filters={filters}
        installers={installers}
        recordsPerPage={recordsPerPage}
        selectedRecords={selectedRecords}
        resetSelectedRecords={resetSelectedRecords}
        handleSearchInput={handleSearchInput}
        handleInstallerInput={handleInstallerInput}
        handleShowDeletedInput={handleShowDeletedInput}
        handleRecordsPerPageInput={handleRecordsPerPageInput}
        handleDeleteProjects={handleDeleteProjects}
        handleDownloadCSV={handleDownloadCSV}
        isUpdatingProjects={isUpdatingProjects}
        isAdmin={isAdmin}
      />
      <ProjectsTable
        projectRecords={projectRecords}
        machineRecords={machineRecords}
        rowSelection={rowSelection}
        filters={filters}
        sorting={sorting}
        handleTableChange={handleTableChange}
        isRequestingProjects={isRequestingProjects}
        isAdmin={isAdmin}
      />
    </div>
  );
}

ProjectsTabPane.propTypes = propTypes;
