const en = {
  translations: {
    Common_terms: {
      Measurements: {
        Airflow: 'm³/h',
        Airspeed: 'm/s',
        Pressure: 'Pa',
        Temperature: '°C',
      },
      Hints: {
        Editable: '*editable',
      },
      Type_labels: {
        // TODO: Important! These translations should be
        // kept in sync with the backend ones for the table
        // search feature to work as expected.
        House_or_flat: 'House or flat',
        Subdivision_or_building: 'Housing subdivision or building',
      },
    },
    Header: {
      Breadcrumbs: {
        Root: 'Home',
        Projects: 'Projects',
        Project_details: 'Project details',
        Project_machine_settings: 'Machine settings',
        Machine_settings: 'Machine settings',
        Project_new: 'New project',
        Options: 'Options',
      },
    },
    Footer: {
      Links: {
        TOne_file: 'T.One V/H software file V08',
        TOne_manual: 'T.One V/H user guide',
        Aqua_air_file: 'T.One AquaAIR/AIR software file V15',
        Aqua_air_manual: 'T.One AquaAIR/AIR user guide',
      },
    },
    Login_form: {
      Inputs: {
        Login: {
          Placeholder: 'Login',
          Messages: {
            Required: 'Please enter your login!',
            Min: 'Must be at least 4 characters long',
          },
        },
        Password: {
          Placeholder: 'Password',
          Messages: {
            Required: 'Please enter your password!',
            Min: 'Must be at least 4 characters long',
          },
        },
        Remember: { Label: 'Remember me' },
      },
      Login_button: 'Connect',
      Forgot_link: 'Forgot your password?',
      Error_message: 'Incorrect login or password',
    },
    Recovery_form: {
      Intro: {
        Title: 'Password recovery',
        Content:
          'Enter your login in the field below to receive the instructions by email.',
      },
      Submit_button: 'Submit',
      Cancel_button: 'Cancel',
      Recaptcha: {
        Messages: {
          Required: 'Please confirm you are a human.',
        },
      },
      Forgot_link: 'Forgot your login?',
      Back_link: 'Go back',
      Contact_message:
        'It’s impossible to recover your password automatically. Please reach out to your local Aldes Group representative in order to regain access to your account.',
      Success_message:
        'If you have a valid email address associated with your account, a temporary password and further instructions have just been sent to that address.',
    },
    Reset_password_form: {
      Intro: {
        Title: 'Reset password',
        Content: 'Enter your new password below',
      },
      Inputs: {
        Password_1: { Placeholder: 'New password' },
        Password_2: { Placeholder: 'Re-enter new password' },
        Match_error: 'Passwords do not match',
      },
      Submit_button: 'Submit',
      Error_message: 'Something went wrong. Please reload the page.',
      Success_message: 'Your password has been successfully updated!',
      Login_link: 'Log in here',
    },
    Home_page: {
      Main_menu: {
        Projects: 'Projects',
        New_project: 'New project',
        Options: 'Options',
      },
    },
    Projects_page: {
      Tab_titles: {
        Projects: 'Projects',
        Machines: 'Machines',
      },
    },
    Projects_filters: {
      Inputs: {
        Search_term: { Placeholder: 'Search by keywords' },
        Installer: { Placeholder: 'Installer' },
        Show_deleted: { Label: 'Display deleted projects' },
        Per_page: 'records per page',
      },
      Delete_button: 'Delete',
      Download_button: 'Download .csv',
      Config_button: 'ID Config',
      Modals: {
        Delete: {
          Title: 'Delete projects',
          OK_button: 'Confirm',
          Cancel_button: 'Cancel',
          Content: 'Are you sure to delete: ',
        },
        Config: {
          Title: 'ID Config',
          OK_button: 'OK',
          Content: `The installation T.One file name created by this website is
defined as the following example:
Example:
V01_321_DUPONT_RDC_TOULOUSE.bin
- T.One Regulation software version = V01
- ID_INSTALLATION (REGISTER KEY) = 321
- Accomodation, Customer name / Housing, Operation name = DUPONT
- Indication (if more than one installation) = RDC
- Town = TOULOUSE
Don't rename the file!`,
        },
      },
      Row_selection_hint: 'You have selected {{count}} project',
      Row_selection_hint_plural: 'You have selected {{count}} projects',
    },
    Projects_table: {
      Column_titles: {
        Type: 'Type',
        Reference: 'Project Reference',
        Machine_reference: 'Machine reference',
        Created_at: 'Creation Date',
        Installer: 'Installer',
        Id_installer: 'ID Installer',
        Address: 'Address',
        Postcode: 'Postcode',
        City_name: 'City',
      },
      Empty_text: 'No records found',
    },
    Project_details: {
      General_info: {
        Title: 'General information',
        Inputs: {
          Installer: {
            Placeholder: 'Select the installer',
          },
          Address: { Placeholder: 'Address' },
          House_number: { Placeholder: 'N°' },
          Project_ref: {
            Placeholder: 'Project reference',
            Messages: {
              Required: 'This field is required',
            },
          },
          Postcode: {
            Placeholder: 'Postcode',
            Messages: {
              Required: 'This field is required',
              Min: 'Enter 5 digits',
            },
          },
          City: {
            Placeholder: 'City',
            Messages: {
              Required: 'This field is required',
            },
          },
          Country: {
            Placeholder: 'Country',
          },
          Show_deleted: { Label: 'Display deleted machines' },
        },
        GDPR: 'Do not enter the personal information that violates GDPR',
      },
      Buttons: {
        Save: 'Save changes',
        Add: 'Add new machine',
        Delete: 'Delete',
      },
      Modals: {
        Add: {
          Title: 'Create new machine',
          Input: {
            Placeholder: 'Enter label',
            Required_message: 'This field is required',
          },
          OK_button: 'Confirm',
          Cancel_button: 'Cancel',
        },
        Delete: {
          Title: 'Delete machines',
          Content: 'Are you sure to delete: ',
        },
      },
      Row_selection_hint: 'You have selected {{count}} machine',
      Row_selection_hint_plural: 'You have selected {{count}} machines',
    },
    Machines_filters: {
      Inputs: {
        Search_term: { Placeholder: 'Search by label' },
        Search_project_id: { Placeholder: 'Search by id' },
        Installer: { Placeholder: 'Installer' },
        Units_ref: { Placeholder: 'Air to Air units ref.' },
        Show_deleted: { Label: 'Display deleted machines' },
        Per_page: 'records per page',
      },
      Delete_button: 'Delete',
      Download_button: 'Download .csv',
      Delete_modal: {
        Title: 'Delete machines',
        OK_button: 'Confirm',
        Cancel_button: 'Cancel',
        Content: 'Are you sure to delete: ',
        Unlabeled_stub: 'UNLABELED [id:{{id}}]',
      },
      Row_selection_hint: 'You have selected {{count}} machine',
      Row_selection_hint_plural: 'You have selected {{count}} machines',
    },
    Machines_table: {
      Column_titles: {
        Duplicate: 'Duplicate',
        Config: 'Configure',
        Type: 'Type',
        Client: 'Client',
        Id: 'ID',
        Label: 'Label',
        Saved_at: 'Save Date',
        Downloaded_at: 'Downloading Date',
        Installer: 'Installer',
        Regulation_type: 'Regulation Type',
        Mode_available: 'Mode Available',
        Units_ref: 'Air to Air Units Ref',
        Config_file: 'Configuration File',
        Downloads: 'N° Downloads',
      },
      Empty_text: 'No records found',
    },
    Machine_settings: {
      Go_to_project_button: 'Go to project',
      Print_button: {
        Label: 'Print',
        Tooltip:
          'Enable Background graphics and select Landscape layout on the print settings screen, if necessary!',
      },
      Save_button: 'Save changes',
      Download_button: 'Download .bin',
      Popover: {
        text: 'To download the file, complete the form first',
      },
      Leave_modal: {
        Title: 'Are you sure you want to leave the page?',
        Content:
          'All unsaved modifications will be lost. Do you wish to continue?',
      },
      Air_heat_pump_section: {
        Title: 'Air to Air Heat Pump',
        Inputs: {
          Installation_type: { Label: 'Installation type' },
          Units_reference: { Label: 'Air to Air units reference' },
          Airflow_low: { Label: 'Airflow (low noise)' },
          Airflow_boost: { Label: 'Airflow (boost mode)' },
        },
      },
      Air_distribution_section: {
        Title: 'Air distribution',
        Inputs: {
          Type: { Label: 'Type' },
          Type_1: { Label: 'Standard duct - distribution airspeed < 1.5 m/s' },
          Type_2: {
            Label:
              'Rectangular ducts (Fib’Air) - distribution airspeed max. 3 m/s',
          },
          Type_3: { Label: 'Other cases (ducts) / ESP to define' },
          Nominal_airflow: { Label: 'Nominal airflow' },
          Minimal_airflow: { Label: 'Minimal airflow (1 outlet open)' },
          External_pressure: { Label: 'External static pressure' },
          Airflow_therm_off: { Label: 'Airflow with thermostat OFF' },
          Airflow_defrost_cycle: {
            Label: 'Airflow during defrost cycle',
          },
          Airduct_leakage: { Label: 'Air duct distribution leakage' },
          External_pressure_avaliable: {
            Label: 'External static pressure available (thermostat OFF)',
          },
        },
      },
      Settings_modes_section: {
        Advanced_input: 'Advanced settings',
        Automatic_button: 'Use automatic settings',
      },
      Rooms_config_section: {
        Inputs: {
          Number_of_channels: { Label: 'Number of channels' },
          Room_name: { Label: 'Room name' },
          Treated_surface: { Label: 'Treated surface (m²)' },
          Ceiling_height: { Label: 'Ceiling height (m)' },
          Airgrill: { Label: 'Selected airgrill dimension' },
          Airleak: {
            Label: 'Airleak status',
            Tooltip:
              'For the system to work properly, leakage rates from the living room vents are required.',
          },
          Minimal_indoor_airflow: {
            Label: 'Minimal indoor unit airflow with compressor ON',
          },
        },
        Text: {
          Airflow_low_noise: {
            Title: 'Airflow (low noise)',
            Desc1: 'Total airflow: {{value}} (m³/h)',
            Desc2: 'Air speed: {{value}} m/s (with all airgrills opened)',
          },
          Airflow_boost_mode: {
            Title: 'Airflow (boost mode)',
            Desc1: 'Total airflow: {{value}} (m³/ h)',
            Desc2: 'Air speed: {{value}} m/s (with all airgrills opened)',
          },
        },
        Advanced_mode: {
          Inputs: {
            Treated_volume: { Label: 'Treated volume (m³)' },
            Airflow_with_4: { Label: 'Airflow with 4 Vol/h (m³/h)' },
            Effective_opened_airgrill: {
              Label: 'Effective opened airgrill surface (cm²)',
            },
            Effective_closed_airgrill: {
              Label: 'Effective closed airgrill surface (cm²)',
            },
            Grills_airflow: { Label: 'Grills airflow (m³/h)' },
            Rate_of_volume: { Label: 'Rate of volume per hour (vol/h)' },
            Air_jet_heating: {
              Label: 'Air jet length in heating operation with 20°',
            },
            Air_jet_cooling: {
              Label: 'Air jet length in cooling operation with 20°',
            },
          },
        },
      },
      Specific_options_section: {
        Title: 'Specific options',
        Inputs: {
          History_step: { Label: 'History step (minutes)' },
          Standard_airspeed: {
            Label: 'Standard airspeed at the airgrills outlet (m/s)',
          },
          Maximal_airspeed: {
            Label: 'Maximal airspeed at the aitgrills outlet (m/s)',
          },
          Maximal_set_heating: {
            Label: 'Maximal set temperature in heating operation (16-31 °C)',
          },
          Minimal_set_heating: {
            Label: 'Minimal set temperature in heating operation (16-31 °C)',
          },
          Maximal_set_cooling: {
            Label: 'Maximal set temperature in cooling operation (16-31 °C)',
          },
          Minimal_set_cooling: {
            Label: 'Minimal set temperature in cooling operation (16-31 °C)',
          },
        },
      },
      Reserved_options_section: {
        Title: 'Options reserved for ALDES',
        Inputs: {
          Minimal_airflow: { Label: 'Minimal airflow with compressor ON' },
          Low_airflow: { Label: 'Low noise airflow' },
          Boost_airflow: { Label: 'Mode boost airflow' },
          Diff_st_high: { Label: 'Diff ST High (°C)' },
          Diff_st_low: { Label: 'Diff ST Low (°C)' },
          Opening_outlet_time: { Label: 'Opening outlet time (s)' },
          Closing_outlet_time: { Label: 'Closing outlet time (s)' },
        },
      },
      Room_names: {
        Living: 'Living',
        Office: 'Office',
        RO1: 'RO1',
        RO2: 'RO2',
        RO3: 'RO3',
        RO4: 'RO4',
        Lounge: 'Lounge',
        Mezzanine: 'Mezzanine',
      },
      Error_message: 'This field is required',
      Minimal_Airleak_Error: 'Insufficient air leak flow',
      Field_error: 'Incorrect values ',
      Folder_reference: 'Folder reference',
    },
    New_project_form: {
      Section_titles: {
        General: 'General information',
        Client: 'Client-related information',
      },
      Inputs: {
        Type: { Label: 'Type' },
        Reference: {
          Label: 'Project reference (proper to the installer)',
          Extra: 'Do not enter the personal information that violates GDPR',
          Placeholder: 'Enter project reference',
          Messages: {
            Required: 'This field is required',
            Min: 'The reference must contain at least 2 characters',
          },
        },
        Installer: {
          Label: 'Installer',
        },
        Installation_type: {
          Label: 'Installation type',
        },
        Address: {
          Label: 'Address',
          Placeholder: 'Enter street name',
          Messages: {
            Min: 'The address must contain at least 1 character',
          },
        },
        House_number: {
          Label: 'Nº',
          Placeholder: 'House Nº',
        },
        Postcode: {
          Label: 'Postcode',
          Placeholder: 'Enter postal code',
          Messages: {
            Required: 'This field is required',
            Min: 'The postcode must contain 5 digits',
          },
        },
        City: {
          Label: 'City',
          Placeholder: 'Enter city name',
          Messages: {
            Required: 'This field is required',
            Min: 'The city name must contain at least 2 characters',
          },
        },
        Country: {
          Label: 'Country',
          Placeholder: 'Enter country name',
          Messages: {
            Required: 'This field is required',
          },
        },
      },
      Create_button: 'Create project',
      Blank_machine_label: 'Blank 1',
    },
    Options_page: {
      Title: 'Download machine configuration (reversible mode)',
      Input: {
        Placeholder: 'Enter machine ID',
        Messages: {
          Min: 'The machine ID must contain at least 1 character',
        },
      },
      Table: {
        Empty_text: 'No records found',
        Column_titles: {
          Date: 'Date / time',
          User_account: 'User account',
          T_one_family: 'T.One family',
          Id_ihm: 'ID_IHM',
          Customer_name: 'Customer name',
          After_sale_request: 'After sale request',
        },
      },
      Customer_name: 'Customer name',
      After_sale: 'Request after sale',
      Download_t_one_button: 'Download T.One V/H .bin file',
      Download_aqua_air_button: 'Download T.One AquaAIR/AIR .bin file',
    },
    Not_found_page: {
      Message: 'PAGE NOT FOUND',
      Go_back_button: 'Go back',
    },
  },
};

export default en;
