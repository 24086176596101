import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography, Form, Button } from 'antd';
import PropTypes from 'prop-types';

import { ROUTES } from 'constants/index';

import { PasswordField } from 'components/ui/PasswordField';

import styles from './PasswordResetForm.module.css';

const { Paragraph, Title, Text } = Typography;

const propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFields: PropTypes.func,
  }).isRequired,
  fields: PropTypes.shape({
    password1: PropTypes.object,
    password2: PropTypes.object,
  }).isRequired,
  isLoginFailed: PropTypes.bool,
  handleReset: PropTypes.func.isRequired,
};

function PasswordResetForm({
  form: { getFieldDecorator, validateFields, setFields },
  fields,
  isLoginFailed,
  handleReset,
}) {
  const { t } = useTranslation();

  const [isResultMessageActive, setIsResultMessageActive] = useState(false);

  const handleClearErrors = (event) => {
    if (event.key !== 'Backspace') {
      return;
    }

    if (
      fields.password1.error === t('Reset_password_form.Inputs.Match_error') ||
      fields.password2.error === t('Reset_password_form.Inputs.Match_error')
    ) {
      setFields({
        password1: {
          ...fields.password1,
          error: null,
        },
        password2: {
          ...fields.password2,
          error: null,
        },
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (fields.password1.value !== fields.password2.value) {
      setFields({
        password1: {
          ...fields.password1,
          error: t('Reset_password_form.Inputs.Match_error'),
        },
        password2: {
          ...fields.password2,
          error: t('Reset_password_form.Inputs.Match_error'),
        },
      });

      return;
    }

    setFields({
      password1: {
        ...fields.password1,
        error: null,
      },
      password2: {
        ...fields.password2,
        error: null,
      },
    });

    validateFields()
      .then((values) => {
        handleReset({
          password1: values.password1,
          password2: values.password2,
        });

        window.setTimeout(() => setIsResultMessageActive(true), 500);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('submitError', err);
      });
  };

  return (
    <div className={styles.PasswordResetForm}>
      <Form role="form" className={styles.formCard}>
        {isResultMessageActive && (
          <Paragraph className={styles.resultMessage}>
            {isLoginFailed ? (
              <Text>{t('Reset_password_form.Error_message')}</Text>
            ) : (
              <>
                <Text>{t('Reset_password_form.Success_message')}</Text>
                <Text underline>
                  <Link to={ROUTES.LOGIN}>
                    {t('Reset_password_form.Login_link')}
                  </Link>
                </Text>
              </>
            )}
          </Paragraph>
        )}
        {!isResultMessageActive && (
          <>
            <Paragraph className={styles.intro}>
              <Title className={styles.introTitle}>
                {t('Reset_password_form.Intro.Title')}
              </Title>
              <Text>{t('Reset_password_form.Intro.Content')}</Text>
            </Paragraph>
            <Row>
              <Col>
                <PasswordField
                  name="password1"
                  isError={!!fields.password1.error}
                  getFieldDecorator={getFieldDecorator}
                  handleKeyDown={handleClearErrors}
                  placeholder={t(
                    'Reset_password_form.Inputs.Password_1.Placeholder'
                  )}
                  inputClassName={styles.formInput}
                />
                <PasswordField
                  name="password2"
                  isError={!!fields.password2.error}
                  help={fields.password2.error}
                  getFieldDecorator={getFieldDecorator}
                  handleKeyDown={handleClearErrors}
                  placeholder={t(
                    'Reset_password_form.Inputs.Password_2.Placeholder'
                  )}
                  inputClassName={styles.formInput}
                />
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col>
                <Button htmlType="submit" type="primary" onClick={handleSubmit}>
                  {t('Reset_password_form.Submit_button')}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </div>
  );
}

PasswordResetForm.propTypes = propTypes;

export const WrappedPasswordResetForm = Form.create({
  name: 'password_reset_form',
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
  mapPropsToFields(props) {
    const { fields } = props;

    return {
      password1: Form.createFormField({
        ...fields.password1,
        value: fields.password1.value,
      }),
      password2: Form.createFormField({
        ...fields.password2,
        value: fields.password2.value,
      }),
    };
  },
})(PasswordResetForm);
