import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import styles from './RoomsConfigTable.module.css';

const propTypes = {
  advanced: PropTypes.bool.isRequired,
  mainAirflowParams: PropTypes.shape({
    airSpeedLowNoise: PropTypes.number,
    airSpeedBoostMode: PropTypes.number,
    totalAirflowLowNoise: PropTypes.number,
    totalAirflowBoostMode: PropTypes.number,
  }),
};

export function TableRowsNames({ advanced, mainAirflowParams }) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.captionCol}>
        <div className={styles.rowName}>
          {t('Machine_settings.Rooms_config_section.Inputs.Room_name.Label')}
        </div>
        <div className={styles.rowName}>
          {t(
            'Machine_settings.Rooms_config_section.Inputs.Treated_surface.Label'
          )}
        </div>
        <div className={styles.rowName}>
          {t(
            'Machine_settings.Rooms_config_section.Inputs.Ceiling_height.Label'
          )}
        </div>
        {advanced && (
          <>
            <div className={styles.rowName}>
              {t(
                'Machine_settings.Rooms_config_section.Advanced_mode.Inputs.Treated_volume.Label'
              )}
            </div>
            <div className={styles.rowName}>
              {t(
                'Machine_settings.Rooms_config_section.Advanced_mode.Inputs.Airflow_with_4.Label'
              )}
            </div>
          </>
        )}
        <div className={styles.rowName}>
          {t('Machine_settings.Rooms_config_section.Inputs.Airgrill.Label')}
        </div>
        <div className={styles.rowName}>
          {t('Machine_settings.Rooms_config_section.Inputs.Airleak.Label')}
        </div>
      </div>
      <div style={{ height: `${67}px` }} />
      {advanced && (
        <>
          <div className={styles.additionalFirstRow}>
            <div className={styles.rowName}>
              {t(
                'Machine_settings.Rooms_config_section.Advanced_mode.Inputs.Effective_opened_airgrill.Label'
              )}
            </div>
            <div className={styles.rowName}>
              {t(
                'Machine_settings.Rooms_config_section.Advanced_mode.Inputs.Effective_closed_airgrill.Label'
              )}
            </div>
          </div>
          <div className={styles.dataBlock}>
            <b>
              {' '}
              {t(
                'Machine_settings.Rooms_config_section.Text.Airflow_low_noise.Title'
              )}
            </b>
            <div>
              {t(
                'Machine_settings.Rooms_config_section.Text.Airflow_low_noise.Desc1',
                {
                  value: parseInt(mainAirflowParams.totalAirflowLowNoise, 10),
                }
              )}
            </div>
            <div>
              {t(
                'Machine_settings.Rooms_config_section.Text.Airflow_low_noise.Desc2',
                {
                  value: Number(mainAirflowParams.airSpeedLowNoise).toFixed(1),
                }
              )}
            </div>
          </div>
          <div>
            <div className={styles.rowName}>
              {t(
                'Machine_settings.Rooms_config_section.Advanced_mode.Inputs.Grills_airflow.Label'
              )}
            </div>
            <div className={styles.rowName}>
              {t(
                'Machine_settings.Rooms_config_section.Advanced_mode.Inputs.Rate_of_volume.Label'
              )}
            </div>
            <div className={styles.rowName}>
              {t(
                'Machine_settings.Rooms_config_section.Advanced_mode.Inputs.Air_jet_heating.Label'
              )}
            </div>
            <div className={styles.rowName}>
              {t(
                'Machine_settings.Rooms_config_section.Advanced_mode.Inputs.Air_jet_cooling.Label'
              )}
            </div>
          </div>
          <div className={styles.dataBlock}>
            <b>
              {t(
                'Machine_settings.Rooms_config_section.Text.Airflow_boost_mode.Title'
              )}
            </b>
            <div>
              {t(
                'Machine_settings.Rooms_config_section.Text.Airflow_boost_mode.Desc1',
                {
                  value: parseInt(mainAirflowParams.totalAirflowBoostMode, 10),
                }
              )}
            </div>
            <div>
              {t(
                'Machine_settings.Rooms_config_section.Text.Airflow_boost_mode.Desc2',
                {
                  value: Number(mainAirflowParams.airSpeedBoostMode).toFixed(1),
                }
              )}
            </div>
          </div>
          <div>
            <div className={styles.rowName}>
              {t(
                'Machine_settings.Rooms_config_section.Advanced_mode.Inputs.Grills_airflow.Label'
              )}
            </div>
            <div className={styles.rowName}>
              {t(
                'Machine_settings.Rooms_config_section.Advanced_mode.Inputs.Rate_of_volume.Label'
              )}
            </div>
            <div className={styles.rowName}>
              {t(
                'Machine_settings.Rooms_config_section.Advanced_mode.Inputs.Air_jet_heating.Label'
              )}
            </div>
            <div className={styles.rowName}>
              {t(
                'Machine_settings.Rooms_config_section.Advanced_mode.Inputs.Air_jet_cooling.Label'
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

TableRowsNames.propTypes = propTypes;
