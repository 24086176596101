import { createSelector } from 'reselect';

const getMachinesState = (state) => state.getIn(['machines']);

export const machinesCountSelector = createSelector(
  [getMachinesState],
  (machines) => machines.get('machinesCount')
);

export const machinesSelector = createSelector([getMachinesState], (machines) =>
  machines.get('machines').toJS()
);

export const machinesIdsSelector = createSelector(
  [getMachinesState],
  (machines) => machines.get('machinesIds').toJS()
);

export const machineSelector = createSelector([getMachinesState], (machines) =>
  machines.get('machine').toJS()
);

export const isRequestingMachinesSelector = createSelector(
  [getMachinesState],
  (machines) => machines.get('isRequesting')
);

export const isUpdatingMachinesSelector = createSelector(
  [getMachinesState],
  (machines) => machines.get('isUpdating')
);
