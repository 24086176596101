import { createSelector } from 'reselect';
import { getIdFromEndpoint } from 'helpers/common';

const machineSettingsSelector = (state) =>
  state.getIn(['machineSettings']).toJS();

export const settingsSelector = createSelector(
  [machineSettingsSelector],
  (machineSettings) => machineSettings.data
);

export const roomSettingsSelector = createSelector(
  [machineSettingsSelector],
  (machineSettings) => machineSettings.roomConfigurations
);

export const distributionTypesSelector = createSelector(
  [machineSettingsSelector],
  (machineSettings) => machineSettings.distributionTypes
);

export const installationTypesSelector = createSelector(
  [machineSettingsSelector],
  (machineSettings) => machineSettings.installationTypes
);

export const referenceUnitsSelector = createSelector(
  [machineSettingsSelector],
  (machineSettings) => machineSettings.referenceUnits
);

export const outletsSelector = createSelector(
  [machineSettingsSelector],
  (machineSettings) => machineSettings.outlets
);

export const roomNamesSelector = createSelector(
  [machineSettingsSelector],
  (machineSettings) => machineSettings.roomNames
);

export const isUpdatingSettingsSelector = createSelector(
  [machineSettingsSelector],
  (machineSettings) => machineSettings.isUpdating
);

export const isRequestingSelector = createSelector(
  [machineSettingsSelector],
  (machineSettings) => machineSettings.isRequesting
);

const getProjectMachines = (state) =>
  state.getIn(['projects', 'project', 'machines']).toJS();

export const settingsIdSelector = (machineId) =>
  createSelector([getProjectMachines], (machines) => {
    if (machines.length) {
      const selectedMachine = machines.find(
        (machine) => machine.id === machineId
      );
      if (selectedMachine?.setting) {
        return getIdFromEndpoint(selectedMachine.setting);
      }
    }
    return null;
  });
