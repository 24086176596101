import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Row, Col, Spin } from 'antd';
import PropTypes from 'prop-types';

import { getIdFromEndpoint } from 'helpers/common';

import { projectSelector } from 'ducks/projects/selectors';
import { machineSelector } from 'ducks/machines/selectors';
import { usePrompt } from 'react-router-dom';
import { validationSchema } from './validationSchema';

import { ProjectInfo } from './ProjectInfo';
import { TopButtonsPanel } from './TopButtonsPanel';
import { BottomButtonsPanel } from './BottomButtonsPannel';
import { AirHeatPumpSettings } from './AirHeatPumpSettings';
import { AirDistributionSettings } from './AirDistributionSettings';
import { RoomsConfigTable } from './RoomsConfigTable';
import { MinimalAirflowSettings } from './MinimalAirflowSettings';
import { SpecificOptions } from './SpecificOptions';
import { ReservedOptions } from './ReservedOptions';

import {
  airHeatPumpSettings,
  airDistributionSettings,
  roomsSettings,
  specificOptions,
  reservedOptions,
} from './optionsMappers';

import styles from './MachineSettings.module.css';

const defaultUnit = {
  airflowLowNoise: 765,
  airflowBoostMode: 900,
  nominalAirflow: 900,
  minimalAirflowOneOutletOpened: 200,
  referenceUnitValues: [
    {
      externalStaticPressureNominal: 20,
      externalStaticPressureOneOutletOpen: 10,
    },
  ],
};

const historyOptions = [-1, 0, 1, 2, 5, 10, 30, 60, 120, 240];

const getValuesFromProps = (settings, rooms, referenceUnits) => {
  const otherSettingsValues = {
    nominalAirflow: settings.nominalAirflow,
    minimalAirflowOneOutletOpened: settings.minimalAirflowOneOutletOpen,
    referenceUnitValues: [
      {
        externalStaticPressureNominal: 20,
        externalStaticPressureOneOutletOpen: 10,
      },
    ],
  };

  const selectedInstallationType = settings?.installationType
    ? getIdFromEndpoint(settings.installationType)
    : 2;

  const selectedAirUnitId = settings.airToAirUnitsReference
    ? getIdFromEndpoint(settings.airToAirUnitsReference)
    : null;

  const selectedTypeId = settings.typeRegulationCp;
  const selectedUnit = referenceUnits.length
    ? referenceUnits.find((unit) => unit.id === selectedAirUnitId)
    : defaultUnit;
  const selectedReferenceUnitValues =
    referenceUnits.length && selectedTypeId < 3
      ? selectedUnit?.referenceUnitValues?.[selectedTypeId - 1]
      : otherSettingsValues;

  const { typeRegulationCp } = settings;

  // Air distr params
  const nominalAirflow =
    typeRegulationCp === 3
      ? settings?.nominalAirflow
      : selectedUnit?.nominalAirflow;
  const minimalAirflowOneOutletOpen =
    typeRegulationCp === 3
      ? settings?.minimalAirflowOneOutletOpen
      : selectedUnit?.minimalAirflowOneOutletOpened;
  const nominalAirflowExternalStaticPressure =
    typeRegulationCp === 3
      ? settings?.nominalAirflowExternalStaticPressure
      : selectedReferenceUnitValues?.externalStaticPressureNominal;
  const minimalAirflowOneOutletOpenExternalStaticPressure =
    typeRegulationCp === 3
      ? settings?.minimalAirflowOneOutletOpenExternalStaticPressure
      : selectedReferenceUnitValues?.externalStaticPressureOneOutletOpen;

  // Reserved options
  const lowNoiseAirflow =
    settings.lowNoiseAirflow || selectedUnit?.airflowLowNoise;
  const modeBoostAirflow =
    settings.modeBoostAirflow || selectedUnit?.modeBoostAirflow;
  const minimalAirflowWithCompressorOn =
    settings.minimalAirflowWithCompressorOn ||
    selectedUnit?.minimalIndoorUnitAirflowWithCompressorOn;

  const updatedRooms = rooms.map((room) => {
    const updatedRoom = {
      ...room,
      selectedAirgrillDimension: getIdFromEndpoint(
        room.selectedAirgrillDimension || '1'
      ),
    };

    if (room.secondChannelSelectedAirgrillDimension) {
      updatedRoom.secondChannelSelectedAirgrillDimension = getIdFromEndpoint(
        room.secondChannelSelectedAirgrillDimension || '1'
      );
    }
    return updatedRoom;
  });

  return {
    installationType: selectedInstallationType,

    // Air Unit
    airToAirUnitsReference: selectedAirUnitId,

    // Air distrib
    typeRegulationCp: selectedTypeId,
    nominalAirflow,
    nominalAirflowExternalStaticPressure,
    minimalAirflowOneOutletOpen,
    minimalAirflowOneOutletOpenExternalStaticPressure,
    airflowDuringDefrostCycle: settings.airflowDuringDefrostCycle,
    externalStaticPressureAvailableThermostatOff:
      settings.externalStaticPressureAvailableThermostatOff,
    airDuctDistributionLeakage: settings.airDuctDistributionLeakage,
    airflowWithThermostatOff: settings.airflowWithThermostatOff,

    // Rooms params
    rooms: updatedRooms,
    channelsUsed: rooms.length,

    // Specific opts
    historyStepMinutes: settings.historyStepMinutes,
    minimalSetTemperatureInHeatingOperation:
      settings.minimalSetTemperatureInHeatingOperation,
    maximalSetTemperatureInHeatingOperation:
      settings.maximalSetTemperatureInHeatingOperation,
    minimalSetTemperatureInCoolingOperation:
      settings.minimalSetTemperatureInCoolingOperation,
    maximalSetTemperatureInCoolingOperation:
      settings.maximalSetTemperatureInCoolingOperation,
    standardAirspeedSetpointAtAirgrillsOutlet:
      settings.standardAirspeedSetpointAtAirgrillsOutlet,
    maximalAirspeedSetpointAtTheAirgrillsOutlet:
      settings.maximalAirspeedSetpointAtTheAirgrillsOutlet,

    // Reserved opts
    lowNoiseAirflow,
    modeBoostAirflow,
    minimalAirflowWithCompressorOn,
    diffStH: settings.diffStH,
    diffStL: settings.diffStL,
    openingOutletTime: settings.openingOutletTime,
    closingOutletTime: settings.closingOutletTime,
  };
};

const propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired,
  installationTypes: PropTypes.array.isRequired,
  distributionTypes: PropTypes.array.isRequired,
  referenceUnits: PropTypes.array.isRequired,
  roomNames: PropTypes.array.isRequired,
  rooms: PropTypes.array.isRequired,
  outlets: PropTypes.array.isRequired,
  machineId: PropTypes.number.isRequired,
  parentProject: PropTypes.object.isRequired,
  saveSettings: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool,
  isUpdatingSettings: PropTypes.bool,
  isDownloadDisabled: PropTypes.bool,
};

/**
 * Main settings form
 * @param {Object} values
 * @param {Object} errors
 */
export function MachineSettings({
  isAdmin,
  settings,
  rooms,
  outlets,
  installationTypes,
  distributionTypes,
  referenceUnits,
  roomNames,
  saveSettings,
  machineId,
  parentProject,
  isRequesting,
  isUpdatingSettings,
  isDownloadDisabled,
}) {
  const formData = useFormik({
    enableReinitialize: true,
    initialValues: getValuesFromProps(settings, rooms, referenceUnits),
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (!Object.values(errors).filter((item) => item).length) {
        saveSettings(values, resetForm, true);
      }
    },
  });
  const airSpeedParams = {
    standart: Number(formData.values.standardAirspeedSetpointAtAirgrillsOutlet),
    maximum: Number(
      formData.values.maximalAirspeedSetpointAtTheAirgrillsOutlet
    ),
    minIndoorUnitAirFlow: Number(
      formData.values.minimalAirflowWithCompressorOn
    ),
  };

  /**
   * State
   */
  const [errors, setErrors] = useState({});
  const [dropdownsChanged, setDropdownsChanged] = useState(false);
  const [additionalErrors, setAdditionalErrors] = useState({
    secondChannelTreatedSurface: false,
    secondChannelCeilingHeight: false,
  });
  const [mainUnitId, setMainUnitId] = useState(1);
  const [regulationTypeId, setRegulationTypeId] = useState(1);
  const [mainAirflowParams, setMainAirflowParams] = useState({
    airSpeedLowNoise: 3.0,
    airSpeedBoostMode: 3.6,
    totalAirflowLowNoise: 765,
    totalAirflowBoostMode: 900,
  });
  const [minimalAirleakError, setMinimalAirleakError] = useState(0);
  const [isAdvancedChecked, setIsAdvancedChecked] = useState(false);
  const [spinning, setSpinning] = useState(true);

  const [firstRoomChannels, setFirstRoomChannels] = useState(2);
  const { t } = useTranslation();
  const details = useSelector(projectSelector);
  const machine = useSelector(machineSelector);

  /**
   * Callbacks
   */
  const saveForm = useCallback(() => {
    if (!Object.values(errors).filter((item) => item).length) {
      saveSettings(formData.values, formData.resetForm, false);
    }
  }, [formData]);

  const handleAirflowParamsChange = useCallback(
    ({
      airSpeedLowNoise,
      airSpeedBoostMode,
      totalAirflowLowNoise,
      totalAirflowBoostMode,
    }) => {
      const airSpeedLowNoiseCalculated =
        airSpeedLowNoise >=
        formData.values.standardAirspeedSetpointAtAirgrillsOutlet
          ? formData.values.standardAirspeedSetpointAtAirgrillsOutlet
          : airSpeedLowNoise;
      const airflowBoostModeCalculated =
        airSpeedBoostMode >=
        formData.values.maximalAirspeedSetpointAtTheAirgrillsOutlet
          ? formData.values.maximalAirspeedSetpointAtTheAirgrillsOutlet
          : airSpeedBoostMode;
      setMainAirflowParams({
        airSpeedLowNoise: airSpeedLowNoiseCalculated,
        airSpeedBoostMode: airflowBoostModeCalculated,
        totalAirflowLowNoise: Number(totalAirflowLowNoise),
        totalAirflowBoostMode: Number(totalAirflowBoostMode),
      });
    },
    [formData.values, setMainAirflowParams]
  );

  const handleValueChange = useCallback(
    (name, val) => {
      const { setFieldValue } = formData;
      setFieldValue(name, val);
    },
    [formData]
  );

  const handleMainUnitChange = useCallback(
    (id) => {
      const unit =
        referenceUnits?.find((item) => item.id === id) || defaultUnit;
      let referenceUnitValues;
      if (regulationTypeId !== 3) {
        referenceUnitValues = unit.referenceUnitValues[regulationTypeId - 1];
      } else {
        referenceUnitValues = {
          externalStaticPressureNominal: unit.externalStaticPressure,
          externalStaticPressureOneOutletOpen:
            unit.externalStaticPressureOneOutletOpened,
        };
      }
      setMainUnitId(id);
      formData.setFieldValue('airToAirUnitsReference', id);
      formData.setFieldValue('lowNoiseAirflow', unit.airflowLowNoise);
      formData.setFieldValue('modeBoostAirflow', unit.airflowBoostMode);
      formData.setFieldValue(
        'minimalAirflowWithCompressorOn',
        unit.minimalIndoorUnitAirflowWithCompressorOn
      );
      updateAirSettings(unit, referenceUnitValues);
    },
    [formData, referenceUnits, regulationTypeId]
  );

  const handleRegulationTypeChange = useCallback(
    (typeId) => {
      const { setFieldValue } = formData;
      const unit = referenceUnits.find((item) => item.id === mainUnitId);
      let referenceUnitValues;

      if (typeId === 3) {
        // referenceUnitValues = unit.referenceUnitValues[regulationTypeId - 1];
        updateAirSettings(unit, {
          externalStaticPressureNominal: unit?.externalStaticPressure,
          externalStaticPressureOneOutletOpen:
            unit?.externalStaticPressureOneOutletOpened,
        });
      } else {
        setRegulationTypeId(typeId);
        referenceUnitValues = unit.referenceUnitValues[typeId - 1];
        updateAirSettings(unit, referenceUnitValues);
      }
      setFieldValue('typeRegulationCp', typeId);
    },
    [mainUnitId, regulationTypeId, referenceUnits]
  );

  const handleAdvancedSettings = useCallback(
    (checked) => {
      setIsAdvancedChecked(checked);
    },
    [setIsAdvancedChecked]
  );

  const updateAirSettings = useCallback(
    (unit, referenceUnitValues) => {
      const { setFieldValue } = formData;
      setFieldValue('nominalAirflow', unit?.nominalAirflow);
      setFieldValue(
        'nominalAirflowExternalStaticPressure',
        referenceUnitValues?.externalStaticPressureNominal
      );
      setFieldValue(
        'minimalAirflowOneOutletOpen',
        unit?.minimalAirflowOneOutletOpened
      );
      setFieldValue(
        'minimalAirflowOneOutletOpenExternalStaticPressure',
        referenceUnitValues?.externalStaticPressureOneOutletOpen
      );
    },
    [formData]
  );

  const updateRooms = useCallback(
    (roomsNumber) => {
      let updatedRooms;
      const { values } = formData;
      if (values.rooms.length > roomsNumber && values.rooms.length > 1) {
        updatedRooms = formData.values.rooms.slice(0, roomsNumber);
      } else {
        updatedRooms = [
          ...formData.values.rooms,
          ...Array(roomsNumber - formData.values.rooms.length).fill({
            label: ' ',
            airleakStatus: false,
            treatedSurface: 0,
            ceilingHeight: formData.getFieldProps('rooms[0].ceilingHeight')
              .value,
            effectiveCloseAirgrillSurfaceWithAirLeakStatusOn: 0,
            effectiveCloseAirgrillSurfaceWithAirLeakStatusOff: 0,
            airJetLengthInCooling: 0,
            airJetLengthInHeating: 0,
            selectedAirgrillDimension: 0,
          }),
        ];
      }
      formData.setFieldValue('rooms', updatedRooms);
    },
    [formData.values, formData.getFieldProps]
  );

  const handleErrorSet = useCallback(
    (name, value) => {
      setErrors((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [setErrors]
  );

  const setGlobalCeilingHeight = useCallback(
    (value, mainRoomChannels) => {
      const updatedRooms = formData.values.rooms.map((room) => ({
        ...room,
        ceilingHeight: value,
      }));
      if (mainRoomChannels === 2) {
        updatedRooms[0].secondChannelCeilingHeight = value;
      }
      formData.setFieldValue('rooms', updatedRooms);
    },
    [formData.values, rooms]
  );

  /**
   * Effects
   */

  useEffect(() => {
    const newId = formData.values.airToAirUnitsReference;

    if (newId !== mainUnitId) {
      setMainUnitId(newId);
    }
  }, [
    mainUnitId,
    referenceUnits,
    formData.values,
    formData.setFieldValue,
    formData.values.typeRegulationCp,
  ]);

  useEffect(() => {
    setRegulationTypeId(formData.values.typeRegulationCp);
  }, [formData.values.typeRegulationCp]);

  useEffect(() => {
    if (formData.dirty) {
      setDropdownsChanged(true);
    } else {
      setDropdownsChanged(false);
    }
  }, [
    formData.dirty,
    formData.values.installationType,
    formData.values.airToAirUnitsReference,
    formData.values.typeRegulationCp,
    formData.values.historyStepMinutes,
  ]);

  useEffect(() => {
    setSpinning(isRequesting);
  }, [isRequesting]);

  useEffect(() => {
    if (
      firstRoomChannels === 2 &&
      +formData.values.rooms[0].secondChannelCeilingHeight === 0
      // RWU : || formData.values.rooms[0].secondChannelCeilingHeight === ''
    ) {
      setAdditionalErrors((state) => ({
        ...state,
        secondChannelCeilingHeight: true,
      }));
    } else {
      setAdditionalErrors((state) => ({
        ...state,
        secondChannelCeilingHeight: false,
      }));
    }
    if (
      firstRoomChannels === 2 &&
      +formData.values.rooms[0].secondChannelTreatedSurface === 0
      // RWU : || formData.values.rooms[0].secondChannelTreatedSurface === ''
    ) {
      setAdditionalErrors((state) => ({
        ...state,
        secondChannelTreatedSurface: true,
      }));
    } else {
      setAdditionalErrors((state) => ({
        ...state,
        secondChannelTreatedSurface: false,
      }));
    }
  }, [firstRoomChannels, formData.values.rooms[0]]);

  const isReallyDirty =
    formData.dirty &&
    (Boolean(Object.keys(formData.touched).length) || dropdownsChanged);

  usePrompt(t('Machine_settings.Leave_modal.Content'), isReallyDirty);

  return (
    <div className={styles.MachineSettings}>
      <ProjectInfo
        reference={{
          lbl: t('Projects_table.Column_titles.Reference'),
          value: details.reference,
        }}
        machineReference={{
          lbl: t('Projects_table.Column_titles.Machine_reference'),
          value: machine.label,
        }}
        address={{
          lbl: t('Projects_table.Column_titles.Address'),
          value: details.address,
        }}
        postCode={{
          lbl: t('Projects_table.Column_titles.Postcode'),
          value: details.postcode,
        }}
        city={{
          lbl: t('Projects_table.Column_titles.City_name'),
          value: details.cityName,
        }}
        installer={{
          lbl: t('Projects_table.Column_titles.Installer'),
          value: details.installer.name,
        }}
      />
      <div>
        <form onSubmit={formData.handleSubmit}>
          <TopButtonsPanel parentProject={parentProject} />

          <Spin spinning={spinning}>
            <Row className={styles.row}>
              <AirHeatPumpSettings
                airUnitId={
                  formData.getFieldProps('airToAirUnitsReference').value
                }
                values={{
                  airToAirUnitsReference:
                    formData.values.airToAirUnitsReference,
                  installationType: formData.values.installationType,
                }}
                errors={airHeatPumpSettings(formData.errors)}
                referenceUnits={referenceUnits}
                installationTypes={installationTypes}
                setFieldValue={formData.setFieldValue}
                setFieldTouched={formData.setFieldTouched}
                setMainUnit={handleMainUnitChange}
              />
              <AirDistributionSettings
                isAdmin={isAdmin}
                distributionTypes={distributionTypes}
                values={airDistributionSettings(formData.values)}
                errors={airDistributionSettings(formData.errors)}
                setError={handleErrorSet}
                selectedUnit={referenceUnits.find(
                  (unit) => unit.id === mainUnitId
                )}
                setFieldValue={formData.setFieldValue}
                setFieldTouched={formData.setFieldTouched}
                handleChange={formData.handleChange}
                handleRegulationType={handleRegulationTypeChange}
              />
            </Row>

            <RoomsConfigTable
              values={roomsSettings(formData.values)}
              errors={roomsSettings(formData.errors)}
              additionalErrors={additionalErrors}
              installationType={formData.values.installationType}
              selectedUnit={referenceUnits.find(
                (unit) => unit.id === mainUnitId
              )}
              airSpeed={airSpeedParams}
              mainAirflowParams={mainAirflowParams}
              outlets={outlets}
              roomNames={roomNames}
              updateRooms={updateRooms}
              handleChange={formData.handleChange}
              handleBlur={formData.handleBlur}
              handleValueChange={handleValueChange}
              handleAdvancedSettings={handleAdvancedSettings}
              handleAirflowParamsChange={handleAirflowParamsChange}
              setFirstRoomChannels={setFirstRoomChannels}
              setMinimalAirleakError={setMinimalAirleakError}
              setGlobalCeilingHeight={setGlobalCeilingHeight}
              setFieldValue={formData.setFieldValue}
              setFieldTouched={formData.setFieldTouched}
            >
              <MinimalAirflowSettings
                title={t(
                  'Machine_settings.Rooms_config_section.Inputs.Minimal_indoor_airflow.Label'
                )}
                name="minimalIndoorUnitAirflowWithCompressorOn"
                value={formData.values.minimalAirflowWithCompressorOn}
                error={minimalAirleakError}
                disabled
              />
            </RoomsConfigTable>

            {!isAdvancedChecked && (
              <Row className={clsx(styles.row, styles.section)}>
                <Col span={6}>
                  <div>
                    <b>
                      {t(
                        'Machine_settings.Rooms_config_section.Text.Airflow_low_noise.Title'
                      )}
                    </b>
                    <div>
                      {t(
                        'Machine_settings.Rooms_config_section.Text.Airflow_low_noise.Desc1',
                        {
                          value: parseInt(
                            mainAirflowParams.totalAirflowLowNoise,
                            10
                          ),
                        }
                      )}
                    </div>
                    <div>
                      {t(
                        'Machine_settings.Rooms_config_section.Text.Airflow_low_noise.Desc2',
                        {
                          value: Number(
                            mainAirflowParams.airSpeedLowNoise
                          ).toFixed(1),
                        }
                      )}
                    </div>
                  </div>
                </Col>
                <Col span={1} />
                <Col span={6}>
                  <div>
                    <b>
                      {t(
                        'Machine_settings.Rooms_config_section.Text.Airflow_boost_mode.Title'
                      )}
                    </b>
                    <div>
                      {t(
                        'Machine_settings.Rooms_config_section.Text.Airflow_boost_mode.Desc1',
                        {
                          value: parseInt(
                            mainAirflowParams.totalAirflowBoostMode,
                            10
                          ),
                        }
                      )}
                    </div>
                    <div>
                      {t(
                        'Machine_settings.Rooms_config_section.Text.Airflow_boost_mode.Desc2',
                        {
                          value: Number(
                            mainAirflowParams.airSpeedBoostMode
                          ).toFixed(1),
                        }
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            {isAdmin && isAdvancedChecked && (
              <SpecificOptions
                historyOptions={historyOptions}
                values={specificOptions(formData.values)}
                errors={specificOptions(formData.errors)}
                setError={handleErrorSet}
                handleChange={formData.handleChange}
                setFieldValue={formData.setFieldValue}
                setFieldTouched={formData.setFieldTouched}
              />
            )}
            {isAdmin && (
              <ReservedOptions
                values={reservedOptions(formData.values)}
                errors={reservedOptions(formData.errors)}
                setError={handleErrorSet}
                handleChange={formData.handleChange}
                setFieldTouched={formData.setFieldTouched}
                t={t}
              />
            )}
          </Spin>
          <BottomButtonsPanel
            settings={settings}
            machineId={machineId}
            isReallyDirty={isReallyDirty}
            isErrors={
              Boolean(Object.keys(formData.errors).length)
              // ||
              // Boolean(
              //   Object.keys(additionalErrors).filter(
              //     (field) => additionalErrors[field]
              //   ).length
              // )
            }
            parentProject={parentProject}
            isUpdatingSettings={isUpdatingSettings}
            isDownloadDisabled={isDownloadDisabled}
            // downloadConfig={downloadSettings}saveForm
            saveForm={saveForm}
          />
        </form>
      </div>
    </div>
  );
}

MachineSettings.propTypes = propTypes;
