import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ROUTES } from 'constants/index';

import { HomePage } from 'components/HomePage';
import { ProjectsPage } from 'containers/ProjectsPage';
import { NewProjectPage } from 'containers/NewProjectPage';
import { OptionsPage } from 'containers/OptionsPage';
import { ProjectDetailsPage } from 'containers/ProjectDetailsPage';
import { MachineSettingsPage } from 'containers/MachineSettingsPage';
import { LoginPage } from 'containers/LoginPage';
import { PasswordResetPage } from 'containers/PasswordResetPage';
import { NotFoundPage } from 'components/NotFoundPage';

import { PrivateRoute } from './PrivateRoute';

const propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export function AppRouter({ isLoggedIn, isAdmin }) {
  return (
    <Routes>
      <Route path="*" element={<NotFoundPage />} />
      <Route
        path={ROUTES.ROOT}
        element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <HomePage isAdmin={isAdmin} />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.PROJECTS}
        element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <ProjectsPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.PROJECT_NEW}
        element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <NewProjectPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.OPTIONS}
        element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            {isAdmin ? <OptionsPage /> : <Navigate to={ROUTES.ROOT} />}
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.PROJECT_DETAILS}
        element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <ProjectDetailsPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.PROJECT_MACHINE_SETTINGS}
        element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <MachineSettingsPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.MACHINE_SETTINGS}
        element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <MachineSettingsPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.LOGOUT}
        element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <Navigate to={ROUTES.ROOT} />
          </PrivateRoute>
        }
      />
      <Route path={ROUTES.LOGIN} element={<LoginPage />} />
      <Route
        path={ROUTES.PASSWORD_RECOVERY}
        element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <PasswordResetPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

AppRouter.propTypes = propTypes;
