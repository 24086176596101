import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Input, Select, Checkbox, Button, Modal } from 'antd';
import PropTypes from 'prop-types';

import { RECORDS_PER_PAGE_OPTIONS } from '../../../constants/tables';

import styles from './MachinesFilters.module.css';

const { Search } = Input;
const { Option } = Select;

const propTypes = {
  filters: PropTypes.object.isRequired,
  installers: PropTypes.arrayOf(PropTypes.object).isRequired,
  unitsRefs: PropTypes.arrayOf(PropTypes.object).isRequired,
  recordsPerPage: PropTypes.number.isRequired,
  selectedRecords: PropTypes.object.isRequired,
  resetSelectedRecords: PropTypes.func.isRequired,
  handleSearchInput: PropTypes.func.isRequired,
  handleSearchByIdInput: PropTypes.func.isRequired,
  handleInstallerInput: PropTypes.func.isRequired,
  handleUnitsRefInput: PropTypes.func.isRequired,
  handleShowDeletedInput: PropTypes.func.isRequired,
  handleRecordsPerPageInput: PropTypes.func.isRequired,
  handleDeleteMachines: PropTypes.func.isRequired,
  handleDownloadCSV: PropTypes.func.isRequired,
  isUpdatingMachines: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export function MachinesFilters({
  filters,
  installers,
  unitsRefs,
  recordsPerPage,
  selectedRecords,
  resetSelectedRecords,
  handleSearchInput,
  handleSearchByIdInput,
  handleInstallerInput,
  handleUnitsRefInput,
  handleShowDeletedInput,
  handleRecordsPerPageInput,
  handleDeleteMachines,
  handleDownloadCSV,
  isUpdatingMachines,
  isAdmin,
}) {
  /* Hooks */
  const { t } = useTranslation();

  /* States */
  const [deleteModal, setDeleteModal] = useState({
    visible: false,
    confirmLoading: false,
  });

  /* Effects */
  useEffect(() => {
    // Close the modal if it's currently opened
    // and the updating process has just completed.
    if (deleteModal.visible && !isUpdatingMachines) {
      setDeleteModal({
        confirmLoading: false,
        visible: false,
      });
      resetSelectedRecords();
    }
  }, [isUpdatingMachines, setDeleteModal, resetSelectedRecords]);

  /* Event handlers */
  const openDeleteModal = useCallback(
    () => setDeleteModal((prevState) => ({ ...prevState, visible: true })),
    []
  );
  const closeDeleteModal = useCallback(
    () => setDeleteModal((prevState) => ({ ...prevState, visible: false })),
    []
  );
  const handleDeleteModalOkButton = useCallback(() => {
    setDeleteModal((prevState) => ({
      ...prevState,
      confirmLoading: true,
    }));

    handleDeleteMachines();
  }, [handleDeleteMachines]);

  // Other
  const isRecordsSelected = !!selectedRecords.ids.length;

  return (
    <div className={styles.MachinesFilters}>
      <Row type="flex" justify="space-between" gutter={[0, 16]}>
        <Col>
          <Search
            name="searchTerm"
            placeholder={t('Machines_filters.Inputs.Search_term.Placeholder')}
            allowClear
            value={filters.searchTerm}
            onChange={handleSearchInput}
            className={styles.searchInput}
          />
          <Search
            name="searchMachineId"
            placeholder={t(
              'Machines_filters.Inputs.Search_project_id.Placeholder'
            )}
            allowClear
            value={filters.searchMachineId}
            onChange={handleSearchByIdInput}
            className={styles.searchInput}
          />
          {isAdmin && (
            <Select
              name="installer"
              placeholder={t('Machines_filters.Inputs.Installer.Placeholder')}
              allowClear
              showSearch
              loading={!installers.length}
              value={filters.installer || undefined}
              onChange={handleInstallerInput}
              dropdownMenuStyle={{ maxHeight: '165px' }}
              className={styles.installerInput}
              filterOption={(inputValue, { props: { searchkey } }) =>
                searchkey.toLowerCase().includes(inputValue.toLowerCase())
              }
            >
              {installers.map(({ id, username, name }) => (
                <Option key={id} value={username} searchkey={name}>
                  {name}
                </Option>
              ))}
            </Select>
          )}
          <Select
            name="unitsRef"
            placeholder={t('Machines_filters.Inputs.Units_ref.Placeholder')}
            allowClear
            showSearch
            value={filters.unitsRef || undefined}
            onChange={handleUnitsRefInput}
            dropdownMenuStyle={{ maxHeight: '165px' }}
            className={styles.unitsRefsInput}
          >
            {unitsRefs.map(({ id, model }) => (
              <Option key={id} value={model}>
                {model}
              </Option>
            ))}
          </Select>
          <Checkbox
            name="showDeleted"
            disabled={!isAdmin}
            checked={filters.isShowDeleted}
            onChange={handleShowDeletedInput}
          >
            {t('Machines_filters.Inputs.Show_deleted.Label')}
          </Checkbox>
        </Col>
        <Col>
          <Button
            icon="delete"
            disabled={!isRecordsSelected || filters.isShowDeleted}
            onClick={openDeleteModal}
            className={styles.button}
          >
            {t('Machines_filters.Delete_button')}
          </Button>
          <Modal
            title={t('Machines_filters.Delete_modal.Title')}
            okText={t('Machines_filters.Delete_modal.OK_button')}
            cancelText={t('Machines_filters.Delete_modal.Cancel_button')}
            closable={false}
            maskClosable={false}
            visible={deleteModal.visible}
            confirmLoading={deleteModal.confirmLoading}
            onOk={handleDeleteModalOkButton}
            onCancel={closeDeleteModal}
          >
            <p>
              {t('Machines_filters.Delete_modal.Content')}
              {selectedRecords.records
                .map(
                  (row) =>
                    row.label ||
                    t('Machines_filters.Delete_modal.Unlabeled_stub', {
                      id: row.id,
                    })
                )
                .join(', ')}
              ?
            </p>
          </Modal>
          <Button
            type="primary"
            onClick={handleDownloadCSV}
            className={styles.button}
          >
            {t('Machines_filters.Download_button')}
          </Button>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" gutter={[0, 16]}>
        <Col>
          <Select
            name="recordsPerPage"
            value={recordsPerPage || undefined}
            onChange={handleRecordsPerPageInput}
            className={styles.recordsPerPageInput}
          >
            {RECORDS_PER_PAGE_OPTIONS.map((size) => (
              <Option key={size} value={size}>
                {size}
              </Option>
            ))}
          </Select>
          {t('Machines_filters.Inputs.Per_page')}
        </Col>
        <Col>
          {isRecordsSelected &&
            t('Machines_filters.Row_selection_hint', {
              count: selectedRecords.ids.length,
            })}
        </Col>
      </Row>
    </div>
  );
}

MachinesFilters.propTypes = propTypes;
