import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

// import { SETTINGS_CATEGORIES } from 'constants/index';
import { LOGOUT_USER } from '../user/actions';
import {
  GET_SETTINGS_REQUEST,
  SET_INITIAL_DATA,
  SAVE_SETTINGS_REQUEST,
  SAVE_SETTINGS_RESPONSE,
  SAVE_PARAMS_COLLECTIONS,
  SAVE_SETTINGS_ERROR,
} from './actions';

const initialSettings = fromJS({
  // idOptionCp: 987654321,
  diffStH: 0.3,
  diffStL: 0.25,
  historyStepMinutes: 5,
  // historiqueStepSEQCp: 1,
  // effacementCanauxCp: 0,
  // reversibleCp: 0,
  airToAirUnitsReference: 0,
  typeRegulationCp: 1,
  // IDIHM: 0,
  // IDREGULATEUR: 0,
  minimalSetTemperatureInHeatingOperation: 16,
  maximalSetTemperatureInHeatingOperation: 24,
  minimalSetTemperatureInCoolingOperation: 22,
  maximalSetTemperatureInCoolingOperation: 31,
  standardAirspeedSetpointAtAirgrillsOutlet: 3.0,
  maximalAirspeedSetpointAtTheAirgrillsOutlet: 3.6,
  openingOutletTime: 60,
  closingOutletTime: 60,
  channelsUsed: 4,
  airToAirHeatPump: 4,
  nominalAirflow: 1200,
  modeBoostAirflow: 1200,
  minimalAirflowWithCompressorOn: 130,
  lowNoiseAirflow: 1020,
  nominalAirflowExternalStaticPressure: 22,
  minimalAirflowOneOutletOpen: 200,
  minimalAirflowOneOutletOpenExternalStaticPressure: 9,
  airflowDuringDefrostCycle: 0,
  externalStaticPressureAvailableThermostatOff: 10,
  airDuctDistributionLeakage: 100,
  airflowWithThermostatOff: 0,
});

const initialRooms = fromJS([
  {
    label: '',
    airleakStatus: false,
    treatedSurface: 0,
    ceilingHeight: 0,
    selectedAirgrillDimension: '/api/outlets/1',
    effectiveOpenedAirgrillSurface: 0,
    effectiveClosedAirgrillSurface: 0,
  },
  {
    label: '',
    airleakStatus: false,
    treatedSurface: 0,
    ceilingHeight: 0,
    selectedAirgrillDimension: '/api/outlets/1',
    effectiveOpenedAirgrillSurface: 0,
    effectiveClosedAirgrillSurface: 0,
  },
  {
    label: '',
    airleakStatus: false,
    treatedSurface: 0,
    ceilingHeight: 0,
    selectedAirgrillDimension: '/api/outlets/1',
    effectiveOpenedAirgrillSurface: 0,
    effectiveClosedAirgrillSurface: 0,
  },
  {
    label: '',
    airleakStatus: false,
    treatedSurface: 0,
    ceilingHeight: 0,
    selectedAirgrillDimension: '/api/outlets/1',
    effectiveOpenedAirgrillSurface: 0,
    effectiveClosedAirgrillSurface: 0,
  },
]);

const initialState = fromJS({
  data: {
    // idOptionCp: 987654321,
    diffStH: 0.3,
    diffStL: 0.25,
    historyStepMinutes: 5,
    // historiqueStepSEQCp: 1,
    // effacementCanauxCp: 0,
    // reversibleCp: 0,
    airToAirUnitsReference: 0,
    typeRegulationCp: 1,
    // IDIHM: 0,
    // IDREGULATEUR: 0,
    minimalSetTemperatureInHeatingOperation: 16,
    maximalSetTemperatureInHeatingOperation: 24,
    minimalSetTemperatureInCoolingOperation: 22,
    maximalSetTemperatureInCoolingOperation: 31,
    standardAirspeedSetpointAtAirgrillsOutlet: 3.0,
    maximalAirspeedSetpointAtTheAirgrillsOutlet: 3.6,
    openingOutletTime: 60,
    closingOutletTime: 60,
    channelsUsed: 4,
    airToAirHeatPump: 4,
    nominalAirflow: 1200,
    modeBoostAirflow: 1200,
    minimalAirflowWithCompressorOn: 220,
    lowNoiseAirflow: 1020,
    nominalAirflowExternalStaticPressure: 22,
    minimalAirflowOneOutletOpen: 200,
    minimalAirflowOneOutletOpenExternalStaticPressure: 9,
    airflowDuringDefrostCycle: 0,
    externalStaticPressureAvailableThermostatOff: 10,
    airDuctDistributionLeakage: 100,
    airflowWithThermostatOff: 0,
  },
  roomConfigurations: [
    {
      label: '',
      airleakStatus: false,
      treatedSurface: 0,
      ceilingHeight: 0,
      selectedAirgrillDimension: '/api/outlet/1',
      effectiveOpenedAirgrillSurface: 0,
      effectiveClosedAirgrillSurface: 0,
    },
    {
      label: '',
      airleakStatus: false,
      treatedSurface: 0,
      ceilingHeight: 0,
      selectedAirgrillDimension: '/api/outlet/1',
      effectiveOpenedAirgrillSurface: 0,
      effectiveClosedAirgrillSurface: 0,
    },
    {
      label: '',
      airleakStatus: false,
      treatedSurface: 0,
      ceilingHeight: 0,
      selectedAirgrillDimension: '/api/outlet/1',
      effectiveOpenedAirgrillSurface: 0,
      effectiveClosedAirgrillSurface: 0,
    },
    {
      label: '',
      airleakStatus: false,
      treatedSurface: 0,
      ceilingHeight: 0,
      selectedAirgrillDimension: '/api/outlet/1',
      effectiveOpenedAirgrillSurface: 0,
      effectiveClosedAirgrillSurface: 0,
    },
  ],
  distributionTypes: [],
  installationTypes: [],
  referenceUnits: [],
  roomNames: [],
  outlets: [],
  isRequesting: false,
  isUpdating: false,
  error: '',
});

export default handleActions(
  {
    [LOGOUT_USER]: (state) => state.merge(initialState),
    [SET_INITIAL_DATA]: (state) =>
      state
        .set('data', initialSettings)
        .set('roomConfigurations', initialRooms),
    [SAVE_SETTINGS_REQUEST]: (state) => state.set('isUpdating', true),
    [GET_SETTINGS_REQUEST]: (state) => state.set('isRequesting', true),
    [SAVE_SETTINGS_RESPONSE]: (state, { payload }) =>
      state
        .set('data', fromJS(payload.data))
        .set('roomConfigurations', fromJS(payload.roomConfigurations))
        .set('isRequesting', false)
        .set('isUpdating', false),
    [SAVE_PARAMS_COLLECTIONS]: (state, { payload }) =>
      state
        .set('installationTypes', fromJS(payload.installationTypes))
        .set('distributionTypes', fromJS(payload.distributionTypes))
        .set('outlets', fromJS(payload.outlets))
        .set('referenceUnits', fromJS(payload.referenceUnits))
        .set('roomNames', fromJS(payload.roomNames)),
    [SAVE_SETTINGS_ERROR]: (state, { payload }) =>
      state.set('error', payload.error),
  },
  initialState
);
