import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Typography } from 'antd';
import PropTypes from 'prop-types';

import { postCodePattern } from 'constants/index';

import { FormInput } from 'components/ui/FormInput';
import { FormSelect } from 'components/ui/FormSelect';

import { PDMachinesTable } from './PDMachinesTable';

import styles from './ProjectDetails.module.css';

const { Title } = Typography;

const makeValidationSchema = (t) =>
  Yup.object().shape({
    installer: Yup.string(),
    address: Yup.string(),
    reference: Yup.string().required(
      t('Project_details.General_info.Inputs.Project_ref.Messages.Required')
    ),
    postcode: Yup.string()
      .required(
        t('Project_details.General_info.Inputs.Postcode.Messages.Required')
      )
      .min(5, t('Project_details.General_info.Inputs.Postcode.Messages.Min')),
    city: Yup.string().required(
      t('Project_details.General_info.Inputs.City.Messages.Required')
    ),
    country: Yup.string().required(
      t('Project_details.General_info.Inputs.Country.Messages.Required')
    ),
  });

export function ProjectDetails({
  PARAMS,
  details,
  installers,
  saveDetails,
  addMachine,
  cloneMachine,
  deleteMachines,
  isRequestingProjects,
  isAdmin,
}) {
  return (
    <div className={styles.ProjectDetails}>
      <GeneralInfo
        PARAMS={PARAMS}
        details={details}
        installers={installers}
        saveInfo={saveDetails}
        isAdmin={isAdmin}
      />
      <PDMachinesTable
        machines={details.machines}
        addMachine={addMachine}
        cloneMachine={cloneMachine}
        deleteMachines={deleteMachines}
        isRequestingProjects={isRequestingProjects}
        isAdmin={isAdmin}
      />
    </div>
  );
}

ProjectDetails.propTypes = {
  PARAMS: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  installers: PropTypes.arrayOf(PropTypes.object).isRequired,
  saveDetails: PropTypes.func.isRequired,
  addMachine: PropTypes.func.isRequired,
  cloneMachine: PropTypes.func.isRequired,
  deleteMachines: PropTypes.func.isRequired,
  isRequestingProjects: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

function GeneralInfo({ isAdmin, details, saveInfo, installers, PARAMS }) {
  const { t } = useTranslation();

  const formData = useFormik({
    enableReinitialize: true,
    initialValues: {
      installer: details.installer.username,
      address: details.address,
      reference: details.reference,
      postcode: details.postcode,
      city: details.cityName,
      country: details.country,
    },
    validationSchema: makeValidationSchema(t),
    onSubmit: (values) => saveInfo(values),
  });

  const handleSelectionChange = useCallback(
    (name, val) => {
      formData.setFieldValue(name, val);
    },
    [formData]
  );

  return (
    <form onSubmit={formData.handleSubmit}>
      <Row type="flex" align="top" justify="space-between">
        <Col>
          <Title level={4} className={styles.title}>
            {t('Project_details.General_info.Title')}
          </Title>
        </Col>
        <Col>
          <Button type="primary" htmlType="submit">
            {t('Project_details.Buttons.Save')}
          </Button>
        </Col>
      </Row>
      <Row type="flex" align="top" className={styles.firstRow}>
        <Col span={4}>
          <FormSelect
            name="installer"
            value={formData.values.installer}
            optionList={installers}
            idField="username"
            labelField="name"
            searchField="name"
            showSearch
            disabled={!isAdmin}
            placeholder={t(
              'Project_details.General_info.Inputs.Address.Placeholder'
            )}
            error={formData.errors.installer}
            handleChange={handleSelectionChange}
            styles={{
              itemClassName: styles.formItem,
              inputClassName: styles.formInputDescribe,
            }}
          />
        </Col>
        <Col span={4}>
          <FormInput
            name="reference"
            placeholder={t(
              'Project_details.General_info.Inputs.Project_ref.Placeholder'
            )}
            extra={t('Project_details.General_info.GDPR')}
            value={formData.values.reference.toUpperCase()}
            maxLength={30}
            error={formData.errors.reference}
            touched={formData.touched.reference}
            handleChange={formData.handleChange}
            handleBlur={formData.handleBlur}
            styles={{
              itemClassName: styles.formItem,
              inputClassName: styles.formInputDescribe,
            }}
          />
        </Col>
        <Col span={4}>
          <FormInput
            name="postcode"
            placeholder={t(
              'Project_details.General_info.Inputs.Postcode.Placeholder'
            )}
            pattern={postCodePattern}
            value={formData.values.postcode}
            error={formData.errors.postcode}
            touched={formData.touched.postcode}
            maxLength={5}
            handleChange={formData.handleChange}
            handleBlur={formData.handleBlur}
            styles={{
              itemClassName: styles.formItem,
              inputClassName: styles.formInputDescribe,
            }}
          />
        </Col>
      </Row>
      <Row type="flex" align="top" className={styles.lastRow}>
        <Col span={4}>
          <FormInput
            name="address"
            value={formData.values.address}
            maxLength={100}
            placeholder={t(
              'Project_details.General_info.Inputs.Address.Placeholder'
            )}
            handleChange={formData.handleChange}
            handleBlur={formData.handleBlur}
            styles={{
              itemClassName: styles.formItem,
              inputClassName: styles.formInputDescribe,
            }}
          />
        </Col>
        <Col span={4}>
          <FormInput
            name="city"
            placeholder={t(
              'Project_details.General_info.Inputs.City.Placeholder'
            )}
            value={formData.values.city}
            maxLength={30}
            error={formData.errors.city}
            touched={formData.touched.city}
            handleChange={formData.handleChange}
            handleBlur={formData.handleBlur}
            styles={{
              itemClassName: styles.formItem,
              inputClassName: styles.formInputDescribe,
            }}
          />
        </Col>
        <Col span={4}>
          <FormSelect
            name="country"
            value={formData.values.country}
            optionList={Object.values(PARAMS.COUNTRIES)}
            idField="label"
            error={formData.errors.country}
            handleChange={handleSelectionChange}
            styles={{
              itemClassName: styles.formItem,
              inputClassName: styles.formInputDescribe,
            }}
          />
        </Col>
      </Row>
    </form>
  );
}

GeneralInfo.propTypes = {
  details: PropTypes.shape({
    installer: PropTypes.object,
    reference: PropTypes.string,
    postcode: PropTypes.string,
    projectType: PropTypes.string,
    address: PropTypes.string,
    cityName: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  installers: PropTypes.arrayOf(PropTypes.object).isRequired,
  saveInfo: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  PARAMS: PropTypes.object.isRequired,
};
