import React from 'react';
import clsx from 'clsx';
import { Input } from 'antd';
import PropTypes from 'prop-types';

import styles from './Input.module.css';

const propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

/**
    styles.input - default class

    There are states:
    styles.hint - hint
*/

function InputPassword({ className, error, ...props }) {
  return (
    <Input.Password
      className={clsx(className, styles.input, {
        [styles.error]: error,
      })}
      {...props}
    />
  );
}

/**
 * Keep the ref forwarding, it's necessary for
 * MaskedInputNumber functionality
 */

const InputMain = React.forwardRef(({ className, error, ...props }, ref) => (
  <Input
    ref={ref}
    className={clsx(className, styles.input, {
      [styles.error]: error,
    })}
    {...props}
  />
));

InputMain.Password = InputPassword;

InputPassword.propTypes = propTypes;
InputMain.propTypes = propTypes;

export { InputMain as Input };
