import React, { useCallback, useState, useEffect } from 'react';
import { Form, Input, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { debounce } from 'helpers/common';

const getInputObject = (value) => ({
  valueAsNumber: Number.isNaN(Number.parseFloat(value, 10))
    ? 0
    : Number.parseFloat(value, 10),
  valueAsString: value,
});

export function FormInput({
  type = 'text',
  name,
  value,
  pattern,
  minValue = 0,
  maxValue = 999999,
  minLength,
  maxLength,
  error,
  setError,
  autoCorrect = false,
  disabled,
  placeholder = '',
  showError = true,
  showTooltip = false,
  handleChange = () => {},
  handleBlur,
  tabIndex,
  styles: compStyles,
}) {
  const { t } = useTranslation();

  const [tooltipVisibility, setTooltipVisiblility] = useState(false);
  const [ownError, setOwnError] = useState('');

  const schema = Yup.object().shape({
    valueAsNumber: Yup.number()
      .min(Number(minValue), 'min')
      .max(Number(maxValue), 'max'),
    valueAsString: Yup.string().matches(pattern, 'regex'),
  });

  const debouncedCall = useCallback(debounce(handleChange, 700), [
    handleChange,
  ]);

  const handleInputChange = useCallback(
    (event) => {
      event.persist();

      const newEvent = {
        target: {
          name,
          value:
            type === 'number'
              ? event.target.value.trim()
              : event.target.value.trimStart(),
        },
      };

      const { value: newVal } = newEvent.target;

      if (type === 'number' && pattern && newVal !== '') {
        schema
          .validate(getInputObject(newVal))
          .then(() => {
            setOwnError('');
            if (setError) {
              setError(name, '');
            }
            handleChange(newEvent);
            debouncedCall.cancel();
          })
          .catch((e) => {
            setOwnError('error');
            if (setError) {
              setError(name, e.type);
            }
            if (e.type === 'max') {
              handleChange(newEvent);
              if (autoCorrect) {
                debouncedCall({ target: { name, value: maxValue } });
              }
            }
            if (e.type === 'min') {
              handleChange(newEvent);
              if (autoCorrect) {
                debouncedCall({ target: { name, value: minValue } });
              }
            }
          });
      } else {
        handleChange(event);
      }
    },
    [
      name,
      type,
      schema,
      pattern,
      minValue,
      maxValue,
      autoCorrect,
      debouncedCall,
      handleChange,
      setError,
    ]
  );

  const handleBlurChange = useCallback(
    (event) => {
      setTooltipVisiblility((prevState) => !prevState);
      if (handleBlur) {
        handleBlur(event);
      }
    },
    [handleBlur]
  );

  const handleFocusChange = useCallback((event) => {
    setTooltipVisiblility((prevState) => !prevState);
    event.currentTarget.select();
  }, []);

  useEffect(() => {
    if (type === 'number' && pattern && value !== '') {
      schema.validate(getInputObject(value)).then(() => {
        setOwnError('');
      });
    }
  }, [type, pattern, value, schema]);

  return (
    <Form.Item
      validateStatus={(error || ownError) && !disabled ? 'error' : undefined}
      help={showError && t(error)}
      // extra={extra}
      className={compStyles?.itemClassName}
    >
      <Tooltip
        visible={!disabled && showTooltip && tooltipVisibility}
        title={`Number between ${minValue} and ${maxValue}`}
      >
        <Input
          className={compStyles?.inputClassName}
          type={pattern ? undefined : type}
          name={name}
          value={value}
          minLength={minLength}
          maxLength={maxLength}
          disabled={disabled}
          placeholder={placeholder}
          onBlur={handleBlurChange}
          onFocus={handleFocusChange}
          onChange={handleInputChange}
          tabIndex={tabIndex}
        />
      </Tooltip>
    </Form.Item>
  );
}

FormInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pattern: PropTypes.instanceOf(RegExp),
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  tabIndex: PropTypes.number,
  error: PropTypes.string,
  autoCorrect: PropTypes.bool,
  showError: PropTypes.bool,
  showTooltip: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  handleBlur: PropTypes.func,
  setError: PropTypes.func,
  handleChange: PropTypes.func,
  styles: PropTypes.shape({
    itemClassName: PropTypes.string,
    inputClassName: PropTypes.string,
  }),
};
