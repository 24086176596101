import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  installationType: Yup.string().required('Machine_settings.Error_message'),
  airToAirUnitsReference: Yup.string().required(
    'Machine_settings.Error_message'
  ),

  // Air distrib
  typeRegulationCp: Yup.string(),
  nominalAirflow: Yup.number().required('Machine_settings.Error_message'),
  nominalAirflowExternalStaticPressure: Yup.number().required(
    'Machine_settings.Error_message'
  ),
  minimalAirflowOneOutletOpen: Yup.number().required(
    'Machine_settings.Error_message'
  ),
  minimalAirflowOneOutletOpenExternalStaticPressure: Yup.number().required(
    'Machine_settings.Error_message'
  ),
  airflowDuringDefrostCycle: Yup.number().required(
    'Machine_settings.Error_message'
  ),
  externalStaticPressureAvailableThermostatOff: Yup.number().required(
    'Machine_settings.Error_message'
  ),
  airDuctDistributionLeakage: Yup.number().required(
    'Machine_settings.Error_message'
  ),
  airflowWithThermostatOff: Yup.number().required(
    'Machine_settings.Error_message'
  ),

  // Rooms params
  rooms: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      airleakStatus: Yup.boolean(),
      treatedSurface: Yup.number().positive().required(),
      ceilingHeight: Yup.number().positive().required(),
      selectedAirgrillDimension: Yup.string().required(),
      effectiveOpenedAirgrillSurface: Yup.number(),
      effectiveClosedAirgrillSurface: Yup.number(),
      secondChannelAirleakStatus: Yup.boolean(),
      secondChannelCeilingHeight: Yup.number().moreThan(-1),
      secondChannelEffectiveClosedAirgrillSurface: Yup.number(),
      secondChannelEffectiveOpenedAirgrillSurface: Yup.number(),
      secondChannelSelectedAirgrillDimension: Yup.number().nullable(true),
      secondChannelTreatedSurface: Yup.number().moreThan(-1),
    })
  ),
  channelsUsed: Yup.number(),
  ceilingHeight: Yup.number(),

  // Specific opts
  historyStepMinutes: Yup.string(),
  minimalSetTemperatureInHeatingOperation: Yup.number().required(
    'Machine_settings.Error_message'
  ),
  maximalSetTemperatureInHeatingOperation: Yup.number().required(
    'Machine_settings.Error_message'
  ),
  minimalSetTemperatureInCoolingOperation: Yup.number().required(
    'Machine_settings.Error_message'
  ),
  maximalSetTemperatureInCoolingOperation: Yup.number().required(
    'Machine_settings.Error_message'
  ),
  standardAirspeedSetpointAtAirgrillsOutlet: Yup.number().required(
    'Machine_settings.Error_message'
  ),
  maximalAirspeedSetpointAtTheAirgrillsOutlet: Yup.number().required(
    'Machine_settings.Error_message'
  ),

  // Reserved opts
  lowNoiseAirflow: Yup.number().required('Machine_settings.Error_message'),
  modeBoostAirflow: Yup.number().required('Machine_settings.Error_message'),
  minimalAirflowWithCompressorOn: Yup.number().required(
    'Machine_settings.Error_message'
  ),
  diffStH: Yup.number().required('Machine_settings.Error_message'),
  diffStL: Yup.number().required('Machine_settings.Error_message'),
  openingOutletTime: Yup.number().required('Machine_settings.Error_message'),
  closingOutletTime: Yup.number().required('Machine_settings.Error_message'),
});
