import { call, put, takeLatest, select } from 'redux-saga/effects';

import {
  LOGIN_REQUEST,
  LOGOUT,
  PASSWORD_RECOVER_REQUEST,
  PASSWORD_RESET_REQUEST,
  loginError,
  loginResponse,
  setCredentials,
  delCredentials,
} from 'ducks/auth/actions';
import { loggedUserInfoResponse, logoutUser } from 'ducks/user/actions';

import { AUTH_TOKEN_KEY } from 'config';
import { userServices } from 'ducks/user/services';
import { authServices } from './services';
import { userCredentialsSelector } from './selectors';

/**
 * Log in
 */
function* loginSagaWorker({ payload }) {
  try {
    const response = yield call(authServices.loginUser, payload);
    const {
      // eslint-disable-next-line camelcase
      data: { user_id },
    } = response;

    localStorage.setItem('USER_ID', user_id);

    yield put(loginResponse({ response }));

    const { username, name, email, roles } = yield call(
      userServices.getUserInfo,
      user_id
    );

    yield put(loggedUserInfoResponse({ username, name, email, roles }));

    if (payload.rememberCredentials) {
      yield put(setCredentials({ ...payload }));
    } else {
      yield put(
        setCredentials({
          username: '',
          password: '',
          rememberCredentials: payload.rememberCredentials,
        })
      );
    }

    window.location.href = '/';
  } catch (error) {
    yield put(loginError({ error }));
  }
}

function* logoutSagaWorker() {
  const { remember } = yield select(userCredentialsSelector);
  localStorage.removeItem(AUTH_TOKEN_KEY);
  yield put(logoutUser());
  if (!remember) {
    yield put(delCredentials());
  }
  window.location.href = '/login';
}

function* recoverSagaWorker({ payload: { username, recaptcha } }) {
  try {
    yield call(authServices.recoverPassword, {
      username,
      'g-recaptcha-response': recaptcha,
    });
  } catch (error) {
    yield put(loginError({ error }));
  }
}

function* resetPasswordSagaWorker({ payload: { token, password } }) {
  try {
    yield call(authServices.resetPassword, {
      token,
      password,
    });
  } catch (error) {
    yield put(loginError({ error }));
  }
}

export function* authenticationMainSaga() {
  yield takeLatest(LOGIN_REQUEST, loginSagaWorker);
  yield takeLatest(LOGOUT, logoutSagaWorker);
  yield takeLatest(PASSWORD_RECOVER_REQUEST, recoverSagaWorker);
  yield takeLatest(PASSWORD_RESET_REQUEST, resetPasswordSagaWorker);
}
