import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Col, Radio, Switch, Tooltip } from 'antd';

import { FormSelect } from 'components/ui/FormSelect';

import { floatOneDigitPattern } from '../../../constants';

import { TableCell } from './TableCell';
import { AdvancedSettings } from './AdvancedSettings';
import { RoomSelector } from './RoomSelector';

import mainStyles from '../MachineSettings.module.css';
import styles from './RoomsConfigTable.module.css';

const propTypes = {
  children: PropTypes.node,
  values: PropTypes.shape({
    label: PropTypes.string,
    treatedSurface: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ceilingHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selectedAirgrillDimension: PropTypes.number,
    airleakStatus: PropTypes.bool,
    secondChannelTreatedSurface: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    secondChannelCeilingHeight: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    secondChannelSelectedAirgrillDimension: PropTypes.number,
    secondaryChannelAirleakStatus: PropTypes.bool,
    secondChannelAirleakStatus: PropTypes.bool,
    secondChannelEffectiveOpenedAirgrillSurface: PropTypes.number,
    secondChannelEffectiveClosedAirgrillSurface: PropTypes.number,
    secondChannelTotalAirflowLowNoise: PropTypes.number,
    secondChannelRateOfVolume: PropTypes.number,
    secondChannelAirJetLengthInHeating: PropTypes.number,
    secondChannelAirJetLengthInCooling: PropTypes.number,
    secondChannelTotalAirflowBoostMode: PropTypes.number,
  }),
  errors: PropTypes.shape({
    treatedSurface: PropTypes.string,
    ceilingHeight: PropTypes.string,
    secondChannelTreatedSurface: PropTypes.string,
    secondChannelCeilingHeight: PropTypes.string,
  }),
  additionalErrors: PropTypes.shape({
    secondChannelCeilingHeight: PropTypes.bool,
    secondChannelTreatedSurface: PropTypes.bool,
  }),
  installationType: PropTypes.number,
  outlets: PropTypes.array,
  roomNames: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  roomChannels: PropTypes.number,
  roomParams: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  setChannels: PropTypes.func,
  setGlobalCeilingHeight: PropTypes.func,
  advanced: PropTypes.bool,
};

export function TableMainColumn({
  children,
  values,
  errors,
  installationType,
  roomParams,
  handleChange,
  setFieldValue,
  setFieldTouched,
  setChannels,
  setGlobalCeilingHeight,
  outlets,
  roomNames,
  roomChannels,
  advanced = false,
}) {
  const handleMainRoomParamChange = useCallback(
    ({ target: { value } }) => {
      setChannels(value);
    },
    [setChannels]
  );

  const handleSelectChange = useCallback(
    (name, val) => {
      setFieldValue(name, val);
    },
    [setFieldValue]
  );

  const handleAirLeakStatusChange = useCallback(
    (channel, checked) => {
      setFieldValue(
        `rooms[0].${
          channel === 1 ? 'airleakStatus' : 'secondChannelAirleakStatus'
        }`,
        checked
      );
    },
    [setFieldValue]
  );

  const handleAirgrillChange = useCallback(
    (channel, val) => {
      setFieldValue(
        `rooms[0].${
          channel === 1
            ? 'selectedAirgrillDimension'
            : 'secondChannelSelectedAirgrillDimension'
        }`,
        val
      );
    },
    [setFieldValue]
  );

  const secondChannelValues = {
    effectiveOpenedAirgrillSurface:
      roomParams?.secondChannelEffectiveOpenedAirgrillSurface || 0,
    effectiveClosedAirgrillSurface:
      roomParams?.secondChannelEffectiveClosedAirgrillSurface || 0,
    grillsAirflowLowNoise: roomParams?.secondChannelGrillsAirflowLowNoise || 0,
    grillsAirflowBoostMode:
      roomParams?.secondChannelGrillsAirflowBoostMode || 0,
    totalAirflowLowNoise: roomParams?.secondChannelTotalAirflowLowNoise,
    totalAirflowBoostMode: roomParams?.secondChannelTotalAirflowBoostMode,
    airJetLengthInHeatingLowNoise:
      roomParams?.secondChannelAirJetLengthInHeatingLowNoise,
    airJetLengthInCoolingLowNoise:
      roomParams?.secondChannelAirJetLengthInCoolingLowNoise,
    airJetLengthInHeatingBoostMode:
      roomParams?.secondChannelAirJetLengthInHeatingBoostMode,
    airJetLengthInCoolingBoostMode:
      roomParams?.secondChannelAirJetLengthInCoolingBoostMode,
    rateOfVolumeLowNoise: roomParams?.secondChannelRateOfVolumeLowNoise,
    rateOfVolumeBoostMode: roomParams?.secondChannelRateOfVolumeBoostMode,
  };

  useEffect(() => {
    handleAirLeakStatusChange(1, true);
    handleAirLeakStatusChange(2, true);
  }, [values]);

  return (
    <div className={styles.RoomsConfigTable}>
      <Col span={roomChannels === 2 ? 4 : 2} className={styles.mainRoomColumn}>
        <div className={styles.headerPanel}>
          <Radio.Group
            onChange={handleMainRoomParamChange}
            value={roomChannels}
            disabled={installationType === 2}
          >
            K1a <Radio value={1} />
            K1b <Radio value={2} />
          </Radio.Group>
        </div>
        <div className={styles.input}>
          <RoomSelector
            name="rooms[0].label"
            value={values.label}
            params={roomNames.map((room) => room.label)}
            onBlur={() => setFieldTouched('rooms[0].label')}
            onChange={handleSelectChange}
          />
        </div>
        {roomChannels === 2 ? (
          <>
            <Col span={12}>
              <TableCell
                name="rooms[0].treatedSurface"
                type="number"
                value={values.treatedSurface}
                error={errors.treatedSurface}
                pattern={floatOneDigitPattern}
                minValue={0}
                maxValue={200}
                autoCorrect
                setFieldValue={setFieldValue}
                handleBlur={() => setFieldTouched('rooms[0].treatedSurface')}
                handleChange={handleChange}
                showError={false}
                tabIndex={1}
              />
              <TableCell
                name="rooms[0].ceilingHeight"
                type="number"
                value={values.ceilingHeight}
                error={errors.ceilingHeight}
                pattern={floatOneDigitPattern}
                minValue={0}
                maxValue={5.0}
                autoCorrect
                setFieldValue={setFieldValue}
                handleBlur={() => setFieldTouched('rooms[0].ceilingHeight')}
                handleChange={setGlobalCeilingHeight}
                showError={false}
                tabIndex={11}
              />
              {advanced && (
                <>
                  <TableCell
                    value={Number(
                      values.treatedSurface * values.ceilingHeight
                    ).toFixed(2)}
                    name="height"
                    disabled
                    setFieldValue={setFieldValue}
                  />
                  <TableCell
                    value={Number(
                      values.treatedSurface * values.ceilingHeight * 4
                    ).toFixed(2)}
                    name="height"
                    disabled
                    setFieldValue={setFieldValue}
                  />
                </>
              )}
              <div className={styles.input}>
                <FormSelect
                  styles={{
                    itemClassName: mainStyles.formItemTable,
                    inputClassName: mainStyles.formInputDescribe,
                  }}
                  value={values.selectedAirgrillDimension}
                  name="rooms[0].selectedAirgrillDimension"
                  optionList={outlets}
                  labelField="label"
                  handleBlur={() =>
                    setFieldTouched('rooms[0].selectedAirgrillDimension')
                  }
                  handleChange={(_, selected) =>
                    handleAirgrillChange(1, selected)
                  }
                  tabIndex={21}
                />
              </div>
              <div className={styles.cell}>
                <Tooltip
                  title={t(
                    'Machine_settings.Rooms_config_section.Inputs.Airleak.Tooltip'
                  )}
                  placement="topLeft"
                >
                  <Switch
                    tabIndex={31}
                    defaultChecked
                    checked
                    onBlur={() => setFieldTouched('rooms[0].airleakStatus')}
                  />
                </Tooltip>
              </div>
              <div
                style={{
                  width: `935px`,
                  marginTop: `30px`,
                  position: 'relative',
                  right: `${advanced ? 276 : 178}px`,
                }}
              >
                {children}
              </div>
              {advanced && <AdvancedSettings values={roomParams} />}
            </Col>
            <Col span={12}>
              <TableCell
                value={values.secondChannelTreatedSurface}
                required={false}
                allowClear
                name="rooms[0].secondChannelTreatedSurface"
                type="number"
                pattern={floatOneDigitPattern}
                minValue={0}
                maxValue={200}
                autoCorrect
                setFieldValue={setFieldValue}
                handleBlur={() =>
                  setFieldTouched('rooms[0].secondChannelTreatedSurface')
                }
                handleChange={handleChange}
                showError={false}
                tabIndex={2}
              />
              <TableCell
                value={values.secondChannelCeilingHeight}
                required={false}
                allowClear
                name="rooms[0].secondChannelCeilingHeight"
                type="number"
                pattern={floatOneDigitPattern}
                minValue={0}
                maxValue={5.0}
                autoCorrect
                setFieldValue={setFieldValue}
                handleBlur={() =>
                  setFieldTouched('rooms[0].secondChannelCeilingHeight')
                }
                handleChange={handleChange}
                showError={false}
                tabIndex={12}
              />
              {advanced && (
                <>
                  <TableCell
                    value={Number(
                      values.secondChannelTreatedSurface *
                        values.secondChannelCeilingHeight
                    ).toFixed(2)}
                    name="height"
                    disabled
                    setFieldValue={setFieldValue}
                  />
                  <TableCell
                    value={Number(
                      values.secondChannelTreatedSurface *
                        values.secondChannelCeilingHeight *
                        4
                    ).toFixed(2)}
                    name="height"
                    disabled
                    setFieldValue={setFieldValue}
                  />
                </>
              )}
              <div className={styles.input}>
                <FormSelect
                  styles={{
                    itemClassName: mainStyles.formItemTable,
                    inputClassName: mainStyles.formInputDescribe,
                  }}
                  value={values.secondChannelSelectedAirgrillDimension}
                  name="rooms[0].secondChannelSelectedAirgrillDimension"
                  optionList={outlets}
                  labelField="label"
                  required={false}
                  allowClear
                  handleBlur={() =>
                    setFieldTouched(
                      'rooms[0].secondChannelSelectedAirgrillDimension'
                    )
                  }
                  handleChange={(name, selected) =>
                    handleAirgrillChange(2, selected)
                  }
                  tabIndex={22}
                />
              </div>
              <div className={styles.cell}>
                <Tooltip
                  title={t(
                    'Machine_settings.Rooms_config_section.Inputs.Airleak.Tooltip'
                  )}
                  placement="topLeft"
                >
                  <Switch
                    defaultChecked
                    checked
                    onBlur={() =>
                      setFieldTouched('rooms[0].secondChannelAirleakStatus')
                    }
                    tabIndex={32}
                  />
                </Tooltip>
              </div>
              <div style={{ height: `${74}px` }} />
              {advanced && <AdvancedSettings values={secondChannelValues} />}
            </Col>
          </>
        ) : (
          <>
            <TableCell
              name="rooms[0].treatedSurface"
              type="number"
              value={values.treatedSurface}
              error={errors.treatedSurface}
              pattern={floatOneDigitPattern}
              minValue={0}
              maxValue={200}
              autoCorrect
              setFieldValue={setFieldValue}
              handleBlur={() => setFieldTouched('rooms[0].treatedSurface')}
              handleChange={handleChange}
              showError={false}
              tabIndex={1}
            />
            <TableCell
              name="rooms[0].ceilingHeight"
              type="number"
              value={values.ceilingHeight}
              error={errors.ceilingHeight}
              pattern={floatOneDigitPattern}
              minValue={0}
              maxValue={5.0}
              autoCorrect
              setFieldValue={setFieldValue}
              handleBlur={() => setFieldTouched('rooms[0].ceilingHeight')}
              handleChange={setGlobalCeilingHeight}
              showError={false}
              tabIndex={11}
            />
            {advanced && (
              <>
                <TableCell
                  value={Number(
                    values.treatedSurface * values.ceilingHeight
                  ).toFixed(2)}
                  name="height"
                  disabled
                  setFieldValue={setFieldValue}
                />
                <TableCell
                  value={Number(
                    values.treatedSurface * values.ceilingHeight * 4
                  ).toFixed(2)}
                  name="height"
                  disabled
                  setFieldValue={setFieldValue}
                />
              </>
            )}
            <div className={styles.input}>
              <FormSelect
                styles={{
                  itemClassName: mainStyles.formItemTable,
                  inputClassName: mainStyles.formInputDescribe,
                }}
                value={values.selectedAirgrillDimension}
                name="rooms[0].selectedAirgrillDimension"
                optionList={outlets}
                labelField="label"
                handleBlur={() =>
                  setFieldTouched('rooms[0].selectedAirgrillDimension')
                }
                handleChange={(name, selected) =>
                  handleAirgrillChange(1, selected)
                }
                tabIndex={21}
              />
            </div>
            <div className={styles.cell}>
              <Tooltip
                title={t(
                  'Machine_settings.Rooms_config_section.Inputs.Airleak.Tooltip'
                )}
                placement="topLeft"
              >
                <Switch
                  defaultChecked
                  checked
                  onBlur={() => setFieldTouched('rooms[0].airleakStatus')}
                  onChange={() => {
                    handleAirLeakStatusChange(1, true);
                  }}
                  tabIndex={31}
                />
              </Tooltip>
            </div>
            <div
              style={{
                width: `935px`,
                marginTop: `30px`,
                position: 'relative',
                right: `${advanced ? 276 : 178}px`,
              }}
            >
              {children}
            </div>
            {advanced && <AdvancedSettings values={roomParams} />}
          </>
        )}
      </Col>
    </div>
  );
}

TableMainColumn.propTypes = propTypes;
