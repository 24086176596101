import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
// import PropTypes from 'prop-types';

import { useAdminCheck } from 'hooks/useAdminCheck';

import {
  createProjectRequest,
  getProjectsParamsRequest,
} from 'ducks/projects/actions';
import { getAllUsersRequest } from 'ducks/user/actions'; // RWU: replace getUsersRequest by getAllUsersRequests

import { projectsParamsSelector } from 'ducks/projects/selectors';
import {
  isExpertSelector,
  userDataSelector,
  usersSelector,
} from 'ducks/user/selectors';

import { ROLES } from '../../constants';

import WrappedNewProjectForm from '../../components/NewProjectForm';

import styles from './NewProjectPage.module.css';

/* Local Helpers */
const getInstallerOptions = (users, user, isAdmin) => {
  if (!isAdmin) {
    return [user];
  }

  const installers = Object.values(users).filter(
    ({ username, roles }) =>
      username !== user.username &&
      (roles.includes(ROLES.INSTALLER) || roles.includes(ROLES.ADMIN))
  );

  return [user, ...installers];
};

const mapFormToRequestFields = (formFields, users) =>
  Object.entries(formFields).reduce(
    (acc, [name, field]) => {
      let fieldName = name;
      let fieldValue = field.value;

      switch (name) {
        case 'installer': {
          const user = Object.values(users).find(
            ({ username }) => username === fieldValue
          );

          fieldValue = `/api/users/${user.id}`;
          break;
        }

        case 'city':
          fieldName = 'cityName';
          break;

        default:
          break;
      }

      return {
        ...acc,
        [fieldName]: fieldValue,
      };
    },
    {
      type: '',
      reference: '',
      installer: '',
      address: '',
      houseNumber: '',
      cityName: '',
      postcode: '',
      clientFirstName: '',
      clientLastName: '',
    }
  );

export function NewProjectPage() {
  /* Hooks */
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isAdmin = useAdminCheck();
  const isExpert = useSelector(isExpertSelector);

  /* Selectors */
  const users = useSelector(usersSelector);
  const user = useSelector(userDataSelector);
  const PARAMS = useSelector(projectsParamsSelector);

  /* Effects */
  useEffect(() => {
    dispatch(getProjectsParamsRequest());
  }, [dispatch]);

  /* State */
  const installerOptions = useMemo(
    () => getInstallerOptions(users, user, isAdmin || isExpert),
    [users, user, isAdmin, isExpert]
  );

  const initialState = {
    type: {
      selectOptions: Object.values(PARAMS.SUBTYPES),
      value: PARAMS.SUBTYPES.HOUSE_OR_FLAT
        ? PARAMS.SUBTYPES.HOUSE_OR_FLAT.name
        : '',
    },
    reference: { value: '' },
    installer: {
      selectOptions: installerOptions,
      disabled: !(isAdmin || isExpert),
      value: user.username,
    },
    address: { value: '' },
    postcode: { value: '' },
    city: { value: '' },
    country: { selectOptions: Object.values(PARAMS.COUNTRIES), value: '' },
  };

  const [form, setForm] = useState({
    fields: initialState,
  });

  /* Effects */
  useEffect(() => {
    dispatch(getAllUsersRequest());
  }, [dispatch]);

  useEffect(() => {
    setForm(({ fields }) => ({
      fields: {
        ...fields,
        type: {
          ...fields.type,
          selectOptions: Object.values(PARAMS.SUBTYPES),
          value: PARAMS.SUBTYPES.HOUSE_OR_FLAT
            ? PARAMS.SUBTYPES.HOUSE_OR_FLAT.name
            : '',
        },
        installer: {
          ...fields.installer,
          selectOptions: installerOptions,
        },
        country: {
          selectOptions: Object.values(PARAMS.COUNTRIES),
          value: PARAMS.COUNTRIES.FRA ? PARAMS.COUNTRIES.FRA.label : '',
        },
      },
    }));
  }, [users, installerOptions, PARAMS]);

  /* Event handlers */
  const handleFormChange = (changedFields) => {
    setForm(({ fields }) => ({
      fields: {
        ...fields,
        ...changedFields,
      },
    }));
  };

  const handleCreateProject = () => {
    const requestFields = mapFormToRequestFields(form.fields, users);

    dispatch(
      createProjectRequest({
        fields: requestFields,
        blankMachineLabel: t('New_project_form.Blank_machine_label'),
        redirectPath: true,
      })
    );
  };

  return (
    <div className={styles.NewProjectPage}>
      <Row type="flex" justify="center" className={styles.pageRow}>
        <Col xs={24} sm={24} md={22} lg={18} xl={15} xxl={12}>
          <WrappedNewProjectForm
            fields={form.fields}
            onChange={handleFormChange}
            handleCreateProject={handleCreateProject}
          />
        </Col>
      </Row>
    </div>
  );
}

NewProjectPage.propTypes = {};
