import React from 'react';
import PropTypes from 'prop-types';

import { TableCell } from './TableCell';

import styles from './RoomsConfigTable.module.css';

const propTypes = {
  values: PropTypes.object,
};

export function AdvancedSettings({ values }) {
  return (
    <>
      <div className={styles.additionalFirstRow}>
        <TableCell value={values?.effectiveOpenedAirgrillSurface} disabled />
        <TableCell value={values?.effectiveClosedAirgrillSurface} disabled />
      </div>
      <div className={styles.additionalRow}>
        <TableCell value={values?.grillsAirflowLowNoise || 0} disabled />
        <TableCell value={values?.rateOfVolumeLowNoise || 0} disabled />
        <TableCell
          value={values?.airJetLengthInHeatingLowNoise || 0}
          disabled
        />
        <TableCell
          value={values?.airJetLengthInCoolingLowNoise || 0}
          disabled
        />
      </div>
      <div className={styles.additionalRow}>
        <TableCell value={values?.grillsAirflowBoostMode || 0} disabled />
        <TableCell value={values?.rateOfVolumeBoostMode || 0} disabled />
        <TableCell
          value={values?.airJetLengthInHeatingBoostMode || 0}
          disabled
        />
        <TableCell
          value={values?.airJetLengthInCoolingBoostMode || 0}
          disabled
        />
      </div>
    </>
  );
}

AdvancedSettings.propTypes = propTypes;
