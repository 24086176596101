import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { formatStringToIntValues } from 'helpers/common';
import {
  getSettingsRequest,
  GET_SETTINGS_REQUEST,
  GET_COLLECTIONS_REQUEST,
  SAVE_SETTINGS_REQUEST,
  DOWNLOAD_SETTINGS_FILE_REQUEST,
  saveSettingsResponse,
  setParamsCollections,
} from './actions';
import { triggerDownload } from '../../utils';

import { machineSettingsServices } from './services';
import { getFileHistoryRequest } from '../fileHistory/actions';

function* getCollectionsWorker() {
  try {
    // const installationTypes = yield call(
    //   machineSettingsServices.getInstallationTypes
    // );
    // const distributionTypes = yield call(
    //   machineSettingsServices.getAirDistributionTypes
    // );
    // const referenceUnits = yield call(
    //   machineSettingsServices.getReferenceUnits
    // );
    // const roomNames = yield call(machineSettingsServices.getRoomNames);
    // const outlets = yield call(machineSettingsServices.getGrillOutlets);
    const [
      installationTypes,
      distributionTypes,
      referenceUnits,
      roomNames,
      outlets,
    ] = yield all([
      call(machineSettingsServices.getInstallationTypes),
      call(machineSettingsServices.getAirDistributionTypes),
      call(machineSettingsServices.getReferenceUnits),
      call(machineSettingsServices.getRoomNames),
      call(machineSettingsServices.getGrillOutlets),
    ]);

    // console.log(params);
    yield put(
      setParamsCollections({
        installationTypes,
        distributionTypes,
        referenceUnits,
        roomNames,
        outlets,
      })
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function* getSettingsSagaWorker({ payload: { settingsId } }) {
  try {
    const data = yield call(machineSettingsServices.getSettings, settingsId);
    const roomConfigurations = yield all(
      data.roomConfigurations.map((machineRef) =>
        call(machineSettingsServices.getDataByRef, machineRef)
      )
    );

    delete data.roomConfigurations;

    yield put(
      saveSettingsResponse({
        data,
        roomConfigurations,
      })
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* saveSettingsSagaWorker({ payload: { settingsId, data, callback } }) {
  const rooms = [...data.rooms];
  const {
    updateMachineSettings,
    postRoomsSettings,
    patchRoomsSettings,
    deleteRoomsSettings,
  } = machineSettingsServices;
  const totalRoomsList = yield select((state) =>
    state.getIn(['machineSettings', 'roomConfigurations']).toJS()
  );
  const { normalizedData, normalizedRooms } = formatStringToIntValues({
    data,
    rooms,
  });
  const updatedRoomsIds = normalizedRooms.map((room) => room.id);
  const deleteRoomIds = totalRoomsList.filter(
    (room) => !updatedRoomsIds.includes(room.id)
  );

  // eslint-disable-next-line no-param-reassign
  delete normalizedData.rooms;

  try {
    yield call(updateMachineSettings, settingsId, normalizedData);

    for (let i = 0; i < normalizedRooms.length; i += 1) {
      const room = normalizedRooms[i];
      if (room.id) {
        yield call(patchRoomsSettings, room.id, room);
      } else {
        yield call(postRoomsSettings, {
          ...room,
          machineConfiguration: `/api/settings/${settingsId}`,
        });
      }
    }

    if (deleteRoomIds.length) {
      yield all(
        deleteRoomIds.map((room) => call(deleteRoomsSettings, room.id))
      );
    }
    yield put(getSettingsRequest({ settingsId }));

    if (typeof callback === 'function') {
      yield call(callback);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

function* downloadSettingsFileSaga({
  payload: { settingsId, idIhm, type, fileName, customerName, isAfterSale },
}) {
  try {
    const isReversibleFile = typeof settingsId === 'undefined';
    const {
      data: fileData,
      headers: { 'content-disposition': contentDisposition },
    } = isReversibleFile
      ? yield call(machineSettingsServices.getReversibleFile, {
          idIhm,
          type,
          customerName,
          isAfterSale,
        })
      : yield call(machineSettingsServices.getSettingsFile, settingsId);

    const fileNameStart = contentDisposition
      ? contentDisposition.indexOf('filename=') + 9
      : null;
    const fileNameEnd = contentDisposition
      ? contentDisposition.indexOf('.bin') + 4
      : null;

    const serverFileName = contentDisposition
      ? contentDisposition.substring(fileNameStart, fileNameEnd)
      : null;

    triggerDownload(
      fileData,
      'application/octet-stream',
      fileName || serverFileName || 'file'
    );

    if (isReversibleFile) {
      yield put(
        getFileHistoryRequest({
          page: 1,
        })
      );
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function* machineSettingsMainSaga() {
  yield takeLatest(GET_SETTINGS_REQUEST, getSettingsSagaWorker);
  yield takeLatest(GET_COLLECTIONS_REQUEST, getCollectionsWorker);
  yield takeLatest(SAVE_SETTINGS_REQUEST, saveSettingsSagaWorker);
  yield takeLatest(DOWNLOAD_SETTINGS_FILE_REQUEST, downloadSettingsFileSaga);
}
