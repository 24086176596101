import { createAction } from 'redux-actions';

import { getTypeCreator } from 'utils';

export const getType = getTypeCreator('@@app/machineSettings');

export const SET_INITIAL_DATA = getType('SET_INITIAL_DATA');
export const setInitialData = createAction(SET_INITIAL_DATA);

export const GET_SETTINGS_REQUEST = getType('GET_SETTINGS_REQUEST');
export const getSettingsRequest = createAction(GET_SETTINGS_REQUEST);

export const GET_SETTINGS_RESPONSE = getType('GET_SETTINGS_RESPONSE');
export const getSettingsResponse = createAction(GET_SETTINGS_RESPONSE);

export const GET_SETTINGS_ERROR = getType('GET_SETTINGS_ERROR');
export const getSettingsError = createAction(GET_SETTINGS_ERROR);

export const SAVE_SETTINGS_REQUEST = getType('SAVE_SETTINGS_REQUEST');
export const saveSettingsRequest = createAction(SAVE_SETTINGS_REQUEST);

export const SAVE_SETTINGS_RESPONSE = getType('SAVE_SETTINGS_RESPONSE');
export const saveSettingsResponse = createAction(SAVE_SETTINGS_RESPONSE);

export const SAVE_SETTINGS_ERROR = getType('SAVE_SETTINGS_ERROR');
export const saveSettingsError = createAction(SAVE_SETTINGS_ERROR);

export const GET_COLLECTIONS_REQUEST = getType('GET_COLLECTIONS_REQUEST');
export const getSettingsCollections = createAction(GET_COLLECTIONS_REQUEST);

export const SAVE_PARAMS_COLLECTIONS = getType('SAVE_PARAMS_COLLECTIONS');
export const setParamsCollections = createAction(SAVE_PARAMS_COLLECTIONS);

// GET SETTINGS FILE BY ID
export const DOWNLOAD_SETTINGS_FILE_REQUEST = getType(
  'DOWNLOAD_SETTINGS_FILE_REQUEST'
);
export const downloadSettingsFileRequest = createAction(
  DOWNLOAD_SETTINGS_FILE_REQUEST
);
