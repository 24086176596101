import React from 'react';
import PropTypes from 'prop-types';

import { FormInput } from 'components/ui/FormInput';

import mainStyles from '../MachineSettings.module.css';
import styles from './RoomsConfigTable.module.css';

export function TableCell({
  name,
  value,
  error,
  type,
  pattern,
  minValue,
  maxValue,
  handleChange,
  handleBlur,
  placeholder,
  disabled,
  autoCorrect,
  showError,
  tabIndex,
}) {
  return (
    <div className={styles.input}>
      <FormInput
        styles={{
          itemClassName: mainStyles.formItemTable,
          inputClassName: mainStyles.formInputDescribe,
        }}
        name={name || 'no name'}
        value={value}
        error={error}
        showError={showError}
        type={type}
        pattern={pattern}
        minValue={minValue}
        maxValue={maxValue}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled={disabled}
        autoCorrect={autoCorrect}
        placeholder={placeholder}
        tabIndex={tabIndex}
      />
    </div>
  );
}

TableCell.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.string,
  type: PropTypes.string,
  pattern: PropTypes.instanceOf(RegExp),
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  placeholder: PropTypes.string,
  autoCorrect: PropTypes.bool,
  disabled: PropTypes.bool,
  showError: PropTypes.bool,
  tabIndex: PropTypes.number,
};
