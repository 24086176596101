import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { generatePath } from 'react-router';
import moment from 'moment';
import { Row, Col } from 'antd';
// import PropTypes from 'prop-types';

import { useAdminCheck } from 'hooks/useAdminCheck';

import { getAllUsersRequest } from 'ducks/user/actions';

import { isExpertSelector, usersSelector } from 'ducks/user/selectors';
import {
  projectSelector,
  projectsParamsSelector,
  isRequestingProjectsSelector,
} from 'ducks/projects/selectors';

import {
  getProjectByIdRequest,
  updateProjectsRequest,
  getProjectsParamsRequest,
} from 'ducks/projects/actions';
import {
  createMachineRequest,
  cloneMachineRequest,
  updateMachinesRequest,
} from 'ducks/machines/actions';

import { ProjectDetails } from 'components/ProjectDetails';

import { ROLES, ENDPOINTS } from 'constants/index';

import styles from './ProjectDetailsPage.module.css';

const mapFormToRequestFields = (formFields, installers) =>
  Object.entries(formFields).reduce(
    (acc, [name, value]) => {
      let fieldName = name;
      let fieldValue = value;

      switch (name) {
        case 'installer': {
          const targetInstaller = installers.find(
            (installer) => installer.username === fieldValue
          );

          // Create iri reference
          fieldValue = generatePath(`api/${ENDPOINTS.USER}`, {
            id: targetInstaller.id,
          });

          break;
        }

        case 'city':
          fieldName = 'cityName';
          break;

        default:
          break;
      }

      return {
        ...acc,
        [fieldName]: fieldValue,
      };
    },
    {
      reference: '',
      installer: '',
      houseNumber: '',
      address: '',
      cityName: '',
      postcode: '',
      country: '',
    }
  );

export function ProjectDetailsPage() {
  /* Hooks */
  const dispatch = useDispatch();

  const { id } = useParams();
  const isAdmin = useAdminCheck();
  const isExpert = useSelector(isExpertSelector);

  /* Selectors */
  const PARAMS = useSelector(projectsParamsSelector);
  const details = useSelector(projectSelector);
  const users = useSelector(usersSelector);
  const isRequestingProjects = useSelector(isRequestingProjectsSelector);

  const projectId = Number(id);
  const installers = Object.values(users).filter(
    ({ roles }) =>
      roles.includes(ROLES.INSTALLER) || roles.includes(ROLES.ADMIN)
  );

  /* Effects */
  useEffect(() => {
    dispatch(getAllUsersRequest());
    dispatch(getProjectsParamsRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProjectByIdRequest({ projectId }));
  }, [dispatch, projectId]);

  /* Event handlers */
  const saveDetails = useCallback(
    (values) => {
      const requestFields = mapFormToRequestFields(values, installers);
      const toSave = [
        {
          id: projectId,
          fields: requestFields,
        },
      ];

      dispatch(
        updateProjectsRequest({
          projects: toSave,
        })
      );
    },
    [dispatch, installers, projectId]
  );

  const deleteMachines = useCallback(
    (selectedMachines) => {
      const today = moment().toISOString();
      const toMarkAsDeleted = selectedMachines.map((machineId) => ({
        id: machineId,
        fields: { deletedAt: today },
      }));

      dispatch(
        updateMachinesRequest({
          machines: toMarkAsDeleted,
          parentProjectId: projectId,
        })
      );
    },
    [dispatch, projectId]
  );

  const addMachine = useCallback(
    ({ label }) => {
      dispatch(
        createMachineRequest({
          fields: {
            project: generatePath(`api/${ENDPOINTS.PROJECT}`, {
              id: projectId,
            }),
            label,
          },
          parentProjectId: projectId,
        })
      );
    },
    [dispatch, projectId]
  );

  const cloneMachine = useCallback(
    ({ id: machineId }) => {
      dispatch(
        cloneMachineRequest({
          machineId,
          parentProjectId: projectId,
        })
      );
    },
    [dispatch, projectId]
  );

  return (
    <div className={styles.ProjectDetailsPage}>
      <Row type="flex" justify="center" className={styles.pageRow}>
        <Col xs={24} sm={23}>
          <ProjectDetails
            PARAMS={PARAMS}
            details={details}
            installers={installers}
            saveDetails={saveDetails}
            addMachine={addMachine}
            cloneMachine={cloneMachine}
            deleteMachines={deleteMachines}
            isRequestingProjects={isRequestingProjects}
            isAdmin={isAdmin || isExpert}
          />
        </Col>
      </Row>
    </div>
  );
}

ProjectDetailsPage.propTypes = {};
