import { generatePath } from 'react-router';

import { request } from 'utils/request';
import { ENDPOINTS } from '../../constants';

/**
 * Get MACHINES resource
 * @param {number} page
 */
const getMachines = ({
  page = 1,
  perPage = 30,
  searchLabel,
  searchMachineId,
  installerId,
  unitsRefId,
  isDeleted = false,
  sortByType,
  sortByRef,
  sortBySaveDate,
  sortByDownloadDate,
}) =>
  request.get(ENDPOINTS.MACHINES, {
    params: {
      page,
      items_per_page: perPage,
      label: searchLabel,
      id: searchMachineId,
      'project.installer.id': installerId,
      'cardParamsBag.airToAirUnitsReference.id': unitsRefId,
      'exists[deletedAt]': isDeleted,
      'order[project.type]': sortByType,
      'order[project.reference]': sortByRef,
      'order[updatedAt]': sortBySaveDate,
      'order[cardParamsBag.downloadedAt]': sortByDownloadDate,
      // TODO: Add sorting for the rest columns?
    },
    headers: { accept: 'application/ld+json' },
  });

const getMachinesFile = (page = 1, itemsPerPage = 30) =>
  request.get(ENDPOINTS.MACHINES, {
    params: { page, items_per_page: itemsPerPage },
    headers: { accept: 'text/csv' },
    responseType: 'blob',
  });

/**
 * Get MACHINE resource by Id
 * @param {number} id
 */
const getMachine = (id) => request.get(generatePath(ENDPOINTS.MACHINE, { id }));

const getMachinesFileByIds = (ids) =>
  request.get(generatePath(ENDPOINTS.MACHINES), {
    params: { id: ids },
    headers: { accept: 'text/csv' },
    responseType: 'blob',
  });

/**
 * Update MACHINE resource
 * @param {number} id
 */
const updateMachine = (id, fields) =>
  request.patch(generatePath(ENDPOINTS.MACHINE, { id }), fields, {
    headers: { 'content-type': 'application/merge-patch+json' },
  });

/**
 * Create MACHINE resource
 * @param {number} id
 */
const createMachine = (data) => request.post(ENDPOINTS.MACHINES, data);

/**
 * Clone MACHINE resource
 * @param {number} id
 */
const cloneMachine = (id) =>
  request.post(generatePath(ENDPOINTS.MACHINE_CLONE, { id }));

export const machinesServices = {
  getMachines,
  getMachinesFile,
  getMachine,
  getMachinesFileByIds,
  updateMachine,
  createMachine,
  cloneMachine,
};
