import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';

import { SiteHeader } from 'containers/SiteHeader';
import { SiteFooter } from 'containers/SiteFooter';

import styles from './AppLayout.module.css';

const { Header, Content, Footer } = Layout;

const propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export function AppLayout({ isLoggedIn, children }) {
  return (
    <div className={styles.AppLayout}>
      <Layout className={styles.layout}>
        <Header className={styles.header}>
          <SiteHeader isLoggedIn={isLoggedIn} />
        </Header>
        <Content className={styles.content}>{children}</Content>
        <Footer className={styles.footer}>
          <SiteFooter />
        </Footer>
      </Layout>
    </div>
  );
}

AppLayout.propTypes = propTypes;
