import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
  GET_MACHINES_REQUEST,
  GET_MACHINE_BY_ID_REQUEST,
  DOWNLOAD_ALL_MACHINES_FILE_REQUEST,
  DOWNLOAD_MACHINES_FILE_BY_IDS_REQUEST,
  UPDATE_MACHINES_REQUEST,
  CREATE_MACHINE_REQUEST,
  CLONE_MACHINE_REQUEST,
  getMachinesResponse,
  getMachinesError,
  getMachineByIdResponse,
  getMachineByIdError,
  updateMachinesResponse,
  updateMachinesError,
  createMachineResponse,
  createMachineError,
} from 'ducks/machines/actions';
import { getProjectByIdRequest } from 'ducks/projects/actions';

import { machinesServices } from 'ducks/machines/services';

import { triggerDownload } from 'utils';

const DEFAULT_MACHINES_COUNT = 4000;

function* getMachinesSaga(action) {
  try {
    const page = action.payload && action.payload.page;
    const perPage = action.payload && action.payload.perPage;
    const searchLabel = action.payload && action.payload.searchLabel;
    const searchMachineId = action.payload && action.payload.searchMachineId;
    const installerId = action.payload && action.payload.installerId;
    const unitsRefId = action.payload && action.payload.unitsRefId;
    const isDeleted = action.payload && action.payload.isDeleted;
    const sortByType = action.payload && action.payload.sortByType;
    const sortByRef = action.payload && action.payload.sortByRef;
    const sortBySaveDate = action.payload && action.payload.sortBySaveDate;
    const sortByDownloadDate =
      action.payload && action.payload.sortByByDownloadDate;

    const machines = yield call(machinesServices.getMachines, {
      page,
      perPage,
      searchLabel,
      searchMachineId,
      installerId,
      unitsRefId,
      isDeleted,
      sortByType,
      sortByRef,
      sortBySaveDate,
      sortByDownloadDate,
    });

    yield put(getMachinesResponse({ machines, page }));
  } catch (error) {
    yield put(getMachinesError({ error }));
  }
}

function* getMachineByIdSaga({ payload: { machineId } }) {
  try {
    const machine = yield call(machinesServices.getMachine, machineId);

    yield put(getMachineByIdResponse({ machine }));
  } catch (error) {
    yield put(getMachineByIdError({ error }));
  }
}

function* downloadMachinesFileByIdsSaga({ payload: { machineIds } }) {
  try {
    const file = yield call(machinesServices.getMachinesFileByIds, machineIds);

    // TODO: The file name is TBD
    yield call(triggerDownload, file, 'text/csv', 'selected_machines.csv');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* downloadAllMachinesFileSaga() {
  try {
    const file = yield call(
      machinesServices.getMachinesFile,
      1,
      DEFAULT_MACHINES_COUNT
    );

    // TODO: The file name is TBD
    yield call(triggerDownload, file, 'text/csv', 'all_machines.csv');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* updateMachinesSaga({ payload: { machines, parentProjectId } }) {
  try {
    const updatedMachines = yield all(
      machines.map(({ id, fields }) =>
        call(machinesServices.updateMachine, id, fields)
      )
    );

    yield put(updateMachinesResponse({ machines: updatedMachines }));

    if (parentProjectId) {
      yield put(getProjectByIdRequest({ projectId: parentProjectId }));
    }
  } catch (error) {
    yield put(updateMachinesError({ error }));
  }
}

function* createMachineSaga({ payload: { fields, parentProjectId } }) {
  try {
    const newMachine = yield call(machinesServices.createMachine, fields);

    yield put(createMachineResponse({ machine: newMachine }));

    if (parentProjectId) {
      yield put(getProjectByIdRequest({ projectId: parentProjectId }));
    }
  } catch (error) {
    yield put(createMachineError({ error }));
  }
}

function* cloneMachineSaga({ payload: { machineId, parentProjectId } }) {
  try {
    yield call(machinesServices.cloneMachine, machineId);

    if (parentProjectId) {
      yield put(getProjectByIdRequest({ projectId: parentProjectId }));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* machinesMainSaga() {
  yield takeLatest(GET_MACHINES_REQUEST, getMachinesSaga);
  yield takeLatest(GET_MACHINE_BY_ID_REQUEST, getMachineByIdSaga);
  yield takeLatest(
    DOWNLOAD_MACHINES_FILE_BY_IDS_REQUEST,
    downloadMachinesFileByIdsSaga
  );
  yield takeLatest(
    DOWNLOAD_ALL_MACHINES_FILE_REQUEST,
    downloadAllMachinesFileSaga
  );
  yield takeLatest(UPDATE_MACHINES_REQUEST, updateMachinesSaga);
  yield takeLatest(CREATE_MACHINE_REQUEST, createMachineSaga);
  yield takeLatest(CLONE_MACHINE_REQUEST, cloneMachineSaga);
}
