import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';

import {
  loginErrorResetRequest,
  passwordResetRequest,
} from 'ducks/auth/actions';

import { isLoginFailedSelector } from 'ducks/auth/selectors';

import { Title } from 'components/Title';
import { WrappedPasswordResetForm } from 'components/PasswordResetForm';

import styles from './PasswordResetPage.module.css';

export function PasswordResetPage() {
  /* Hooks */
  const dispatch = useDispatch();

  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');

  const isLoginFailed = useSelector(isLoginFailedSelector);

  /* State */
  const initialState = {
    password1: { value: '' },
    password2: { value: '' },
  };

  const [form, setForm] = useState({
    fields: initialState,
  });

  useEffect(() => {
    dispatch(loginErrorResetRequest());
  }, [dispatch]);

  const handleFormChange = (changedFields) => {
    setForm(({ fields }) => ({
      fields: {
        ...fields,
        ...changedFields,
      },
    }));
  };

  const handleReset = useCallback(
    ({ password1 }) => {
      dispatch(
        passwordResetRequest({
          token,
          password: password1,
        })
      );
    },
    [dispatch, token]
  );

  const { fields } = form;

  return (
    <div className={styles.PasswordResetPage}>
      <Row
        type="flex"
        justify="center"
        align="middle"
        className={styles.pageRow}
      >
        <Col>
          <Title />
          <WrappedPasswordResetForm
            fields={fields}
            isLoginFailed={isLoginFailed}
            onChange={handleFormChange}
            handleReset={handleReset}
          />
        </Col>
      </Row>
    </div>
  );
}
