import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { store } from 'store';
import { App } from 'containers/App';

import './i18n';
import 'moment/locale/fr';
import 'moment/locale/es';

import 'antd/dist/antd.css';

import './assets/styles/index.css';
import './assets/styles/print.css';
import './assets/styles/variables.css';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
