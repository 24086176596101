import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Input, Divider, Button } from 'antd';

import mainStyles from '../MachineSettings.module.css';

const { Option } = Select;

export function RoomSelector({
  name,
  value,
  onChange,
  onBlur,
  params,
  tabIndex,
}) {
  const [options, setOptions] = useState(params);
  const [newOption, setNewOption] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOptions(params);
  }, [params, setOptions]);

  const onInputChange = useCallback((event) => {
    setNewOption(event.target.value);
  }, []);

  const addItem = useCallback(() => {
    if (newOption) {
      setOptions((prevState) => [...prevState, newOption]);
      setNewOption('');
      onChange(name, newOption);
      setOpen(false);
    }
  }, [setOptions, setNewOption, newOption]);

  const onSelectChange = useCallback(
    (selected) => {
      onChange(name, selected);
      setOpen(false);
    },
    [onChange, name]
  );

  return (
    <Form.Item className={mainStyles.formItemTable}>
      <Select
        value={value}
        onBlur={onBlur}
        onChange={onSelectChange}
        onFocus={() => setOpen(true)}
        onMouseEnter={() => setOpen(false)}
        open={open}
        tabIndex={tabIndex}
        dropdownRender={(menu) => (
          <>
            <div style={{ padding: 8 }}>
              <Input value={newOption} onChange={onInputChange} />
              <div style={{ padding: '8px' }}>
                <Button onClick={() => addItem()}>Ajout</Button>
              </div>
            </div>
            <Divider />
            {menu}
          </>
        )}
      >
        {options.map((option) => (
          <Option key={option}>{option}</Option>
        ))}
      </Select>
    </Form.Item>
  );
}

RoomSelector.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  tabIndex: PropTypes.number,
  params: PropTypes.arrayOf(PropTypes.string),
};
