import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography, Form, Button } from 'antd';
import PropTypes from 'prop-types';

import { CAPTCHA_KEY } from 'config';

import { LoginField } from 'components/ui/LoginField';

import styles from './RecoveryForm.module.css';

const { Paragraph, Title, Text } = Typography;

const propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFields: PropTypes.func,
  }).isRequired,
  fields: PropTypes.shape({
    username: PropTypes.object,
    password: PropTypes.object,
    recaptcha: PropTypes.object,
  }).isRequired,
  isLoginFailed: PropTypes.bool.isRequired,
  handleRecover: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

function RecoveryForm({
  form: { getFieldDecorator, validateFields, setFields },
  fields,
  isLoginFailed,
  handleRecover,
  handleCancel,
}) {
  const { t } = useTranslation();

  const [isContactMessageActive, setIsContactMessageActive] = useState(false);
  const [isResultMessageActive, setIsResultMessageActive] = useState(false);

  useEffect(() => {
    setFields({
      username: {
        value: '',
        error: null,
      },
      recaptcha: {
        value: '',
        error: null,
      },
    });
  }, [setFields]);

  /* Event handlers */
  const toggleContactMessage = () => {
    setIsContactMessageActive((prevState) => !prevState);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!fields.recaptcha.value.length) {
      setFields({
        recaptcha: {
          ...fields.recaptcha,
          error: t('Recovery_form.Recaptcha.Messages.Required'),
        },
      });

      return;
    }

    setFields({
      recaptcha: {
        ...fields.recaptcha,
        error: null,
      },
    });

    validateFields()
      .then((values) => {
        handleRecover({
          username: values.username,
          recaptcha: values.recaptcha,
        });

        window.setTimeout(() => setIsResultMessageActive(true), 500);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('submitError', err);
      });
  };

  const { username } = fields;

  return (
    <div className={styles.RecoveryForm}>
      <Form className={styles.formCard}>
        {isContactMessageActive && !isResultMessageActive && (
          <Paragraph className={styles.contactMessage}>
            <Text>{t('Recovery_form.Contact_message')}</Text>
          </Paragraph>
        )}
        {isResultMessageActive && !isContactMessageActive && (
          <Paragraph className={styles.contactMessage}>
            <Text>
              {isLoginFailed
                ? t('Something went wrong. Please reload the page.')
                : t('Recovery_form.Success_message')}
            </Text>
          </Paragraph>
        )}
        {!isContactMessageActive && !isResultMessageActive && (
          <>
            <Paragraph className={styles.intro}>
              <Title className={styles.introTitle}>
                {t('Recovery_form.Intro.Title')}
              </Title>
              <Text>{t('Recovery_form.Intro.Content')}</Text>
            </Paragraph>
            <LoginField
              username={username.value}
              getFieldDecorator={getFieldDecorator}
              inputClassName={styles.formInput}
            />
            <Row type="flex" justify="center">
              <Col span={14} className={styles.buttonColumn}>
                <Button onClick={handleCancel}>
                  {t('Recovery_form.Cancel_button')}
                </Button>
                <Button type="primary" onClick={handleSubmit}>
                  {t('Recovery_form.Submit_button')}
                </Button>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col>
                <Form.Item
                  validateStatus={fields.recaptcha.error ? 'error' : undefined}
                  help={fields.recaptcha.error || undefined}
                >
                  {getFieldDecorator('recaptcha')(
                    <ReCAPTCHA sitekey={CAPTCHA_KEY} />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
      <Row type="flex" justify="center">
        <Col>
          {!isResultMessageActive && (
            <Button type="link" onClick={toggleContactMessage}>
              <span className={styles.link}>
                {isContactMessageActive
                  ? t('Recovery_form.Back_link')
                  : t('Recovery_form.Forgot_link')}
              </span>
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
}

RecoveryForm.propTypes = propTypes;

export const WrappedRecoveryForm = Form.create({
  name: 'recovery_form',
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
  mapPropsToFields(props) {
    const { fields } = props;

    return {
      username: Form.createFormField({
        ...fields.username,
        value: fields.username.value,
      }),
      recaptcha: Form.createFormField({
        ...fields.recaptcha,
        value: fields.recaptcha.value,
      }),
    };
  },
})(RecoveryForm);
