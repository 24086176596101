import { request } from 'utils/request';
import { ENDPOINTS } from '../../constants';

/**
 * Get FileHistoryEntries resource
 * @param {number} page
 */
const getFileHistoryEntries = ({ page = 1, perPage = 30, sorting }) =>
  request.get(ENDPOINTS.FILE_HISTORY_ENTRIES, {
    params: {
      page,
      items_per_page: perPage,
      [`order[${sorting.field}]`]: sorting.order,
      'exists[idIhm]': true,
    },
    headers: { accept: 'application/ld+json' },
  });

export const fileHistoryServices = {
  getFileHistoryEntries,
};
