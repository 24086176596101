import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';

import { getProjectByIdRequest } from 'ducks/projects/actions';
import { getMachineByIdRequest } from 'ducks/machines/actions';
import {
  setInitialData,
  getSettingsRequest,
  getSettingsCollections,
  saveSettingsRequest,
  downloadSettingsFileRequest,
} from 'ducks/machineSettings/actions';

import { adminRoleSelector, isExpertSelector } from 'ducks/user/selectors';
import { projectsSelector } from 'ducks/projects/selectors';
import { machineSelector } from 'ducks/machines/selectors';
import {
  settingsSelector,
  settingsIdSelector,
  roomSettingsSelector,
  distributionTypesSelector,
  outletsSelector,
  installationTypesSelector,
  referenceUnitsSelector,
  roomNamesSelector,
  isUpdatingSettingsSelector,
  isRequestingSelector,
} from 'ducks/machineSettings/selectors';

import { MachineSettings } from 'components/MachineSettings';

import styles from './MachineSettingsPage.module.css';

export function MachineSettingsPage() {
  const dispatch = useDispatch();

  const { id } = useParams();
  const machineId = Number(id);

  /* Selectors */
  const isAdmin = useSelector(adminRoleSelector);
  const isExpert = useSelector(isExpertSelector);
  const isUpdatingSettings = useSelector(isUpdatingSettingsSelector);
  const isRequesting = useSelector(isRequestingSelector);

  const projects = useSelector(projectsSelector);
  const currentMachine = useSelector(machineSelector);

  const settings = useSelector(settingsSelector);
  const settingsId = useSelector(settingsIdSelector(machineId));

  const installationTypes = useSelector(installationTypesSelector);
  const distributionTypes = useSelector(distributionTypesSelector);
  const referenceUnits = useSelector(referenceUnitsSelector);
  const roomSettings = useSelector(roomSettingsSelector);
  const roomNames = useSelector(roomNamesSelector);
  const outlets = useSelector(outletsSelector);

  const parentProject = projects[currentMachine?.projectId] || {};

  /* Effects */
  useEffect(() => {
    dispatch(getSettingsCollections());
  }, []);

  useEffect(() => {
    dispatch(getMachineByIdRequest({ machineId }));
  }, [dispatch, machineId]);

  useEffect(() => {
    if (currentMachine?.projectId) {
      dispatch(getProjectByIdRequest({ projectId: currentMachine.projectId }));
    }
  }, [dispatch, currentMachine.projectId]);

  useEffect(() => {
    if (settingsId) {
      dispatch(getSettingsRequest({ settingsId }));
    } else {
      dispatch(setInitialData());
    }
  }, [dispatch, settingsId]);

  /* Handlers */
  const saveSettings = useCallback(
    (values, resetForm, downloadAfterSaving) => {
      dispatch(
        saveSettingsRequest({
          settingsId,
          data: {
            ...values,
          },
          callback: () => {
            // resetForm();
            if (downloadAfterSaving) {
              handleFileDownload();
            }
            dispatch(getMachineByIdRequest({ machineId }));
            dispatch(getSettingsRequest({ settingsId }));
          },
        })
      );
    },
    [dispatch, machineId, settingsId]
  );

  const handleFileDownload = useCallback(() => {
    dispatch(
      downloadSettingsFileRequest({
        settingsId,
      })
    );
  }, [dispatch, settingsId]);

  const isDownloadDisabled =
    !currentMachine?.updatedAt ||
    // TODO: Hotfix to exclude settings without unitsRef
    !currentMachine?.airToAirUnitsReference;

  return (
    <div className={styles.MachineSettingsPage}>
      <Row type="flex" justify="center" className={styles.pageRow}>
        <Col xs={24} xl={24} xxl={24}>
          <MachineSettings
            isAdmin={isAdmin || isExpert}
            isUpdatingSettings={isUpdatingSettings}
            isRequesting={isRequesting}
            isDownloadDisabled={isDownloadDisabled}
            parentProject={parentProject}
            settings={settings}
            installationTypes={installationTypes}
            distributionTypes={distributionTypes}
            referenceUnits={referenceUnits}
            roomNames={roomNames}
            rooms={roomSettings}
            outlets={outlets}
            machineId={machineId}
            downloadSettings={handleFileDownload}
            saveSettings={saveSettings}
          />
        </Col>
      </Row>
    </div>
  );
}

MachineSettingsPage.propTypes = {};
