const airHeatPumpSettings = (obj) => ({
  installationType: obj.installationType,
  airToAirUnitsReference: obj.airToAirUnitsReference,

  airToAirHeatPump: obj.airToAirHeatPump,
  param1: obj.param1,
  param2: obj.param2,
});

const airDistributionSettings = (obj) => ({
  typeRegulationCp: obj.typeRegulationCp, // ?
  nominalAirflow: obj.nominalAirflow,
  nominalAirflowExternalStaticPressure:
    obj.nominalAirflowExternalStaticPressure,
  minimalAirflowOneOutletOpen: obj.minimalAirflowOneOutletOpen,
  minimalAirflowOneOutletOpenExternalStaticPressure:
    obj.minimalAirflowOneOutletOpenExternalStaticPressure,
  airflowDuringDefrostCycle: obj.airflowDuringDefrostCycle,
  externalStaticPressureAvailableThermostatOff:
    obj.externalStaticPressureAvailableThermostatOff,
  airDuctDistributionLeakage: obj.airDuctDistributionLeakage,
  airflowWithThermostatOff: obj.airflowWithThermostatOff,
});

const roomsSettings = (obj) => ({
  rooms: obj.rooms,
  channelsUsed: obj.channelsUsed,
  ceilingHeight: obj.ceilingHeight,
});

const specificOptions = (obj) => ({
  historyStepMinutes: obj.historyStepMinutes,
  minimalSetTemperatureInHeatingOperation:
    obj.minimalSetTemperatureInHeatingOperation,
  maximalSetTemperatureInHeatingOperation:
    obj.maximalSetTemperatureInHeatingOperation,
  minimalSetTemperatureInCoolingOperation:
    obj.minimalSetTemperatureInCoolingOperation,
  maximalSetTemperatureInCoolingOperation:
    obj.maximalSetTemperatureInCoolingOperation,
  standardAirspeedSetpointAtAirgrillsOutlet:
    obj.standardAirspeedSetpointAtAirgrillsOutlet,
  maximalAirspeedSetpointAtTheAirgrillsOutlet:
    obj.maximalAirspeedSetpointAtTheAirgrillsOutlet,
});

const reservedOptions = (obj) => ({
  lowNoiseAirflow: obj.lowNoiseAirflow,
  modeBoostAirflow: obj.modeBoostAirflow,
  minimalAirflowWithCompressorOn: obj.minimalAirflowWithCompressorOn,
  ModeBolowNoiseAirflowost: obj.lowNoiseAirflow,
  diffStH: obj.diffStH,
  diffStL: obj.diffStL,
  openingOutletTime: obj.openingOutletTime,
  closingOutletTime: obj.closingOutletTime,
});

export {
  airHeatPumpSettings,
  airDistributionSettings,
  roomsSettings,
  specificOptions,
  reservedOptions,
};
