import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  GET_FILE_HISTORY_REQUEST,
  getFileHistoryResponse,
  getFileHistoryError,
} from './actions';

import { fileHistoryServices } from './services';
import { fileHistorySortingSelector } from './selectors';

function* getFileHistoryEntriesSaga({ payload: { page, sorting } = {} }) {
  try {
    const savedSorting = yield select(fileHistorySortingSelector);
    const entries = yield call(fileHistoryServices.getFileHistoryEntries, {
      page,
      sorting: sorting || savedSorting,
    });

    yield put(getFileHistoryResponse({ entries, page }));
  } catch (error) {
    yield put(getFileHistoryError({ error }));
  }
}

export function* fileHistoryMainSaga() {
  yield takeLatest(GET_FILE_HISTORY_REQUEST, getFileHistoryEntriesSaga);
}
