import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

import { floatDoubleDigitPattern, integerPattern } from 'constants/index';

import { FormInput } from 'components/ui/FormInput';

import styles from './MachineSettings.module.css';

const propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func,
};

export function ReservedOptions({
  values,
  errors,
  handleChange,
  setFieldTouched,
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.section}>
      <Row className={styles.narrowRow}>
        <div className={styles.columnLabel}>
          {t('Machine_settings.Reserved_options_section.Title')}
        </div>
      </Row>
      <Row className={styles.row}>
        <Col span={7} lg={7} xl={6} xxl={5}>
          <div className={styles.field}>
            <div className={styles.formLabel}>
              {t(
                'Machine_settings.Reserved_options_section.Inputs.Minimal_airflow.Label'
              )}
            </div>
            <FormInput
              styles={{
                itemClassName: styles.formItem,
                inputClassName: styles.formInputDescribe,
              }}
              name="minimalAirflowWithCompressorOn"
              type="number"
              value={values.minimalAirflowWithCompressorOn}
              error={errors.minimalAirflowWithCompressorOn}
              pattern={integerPattern}
              handleBlur={() =>
                setFieldTouched('minimalAirflowWithCompressorOn')
              }
              handleChange={handleChange}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.formLabel}>
              {t(
                'Machine_settings.Reserved_options_section.Inputs.Low_airflow.Label'
              )}
            </div>
            <FormInput
              styles={{
                itemClassName: styles.formItem,
                inputClassName: styles.formInputDescribe,
              }}
              name="lowNoiseAirflow"
              type="number"
              pattern={integerPattern}
              value={values.lowNoiseAirflow}
              error={errors.lowNoiseAirflow}
              handleBlur={() => setFieldTouched('lowNoiseAirflow')}
              handleChange={handleChange}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.formLabel}>
              {t(
                'Machine_settings.Reserved_options_section.Inputs.Boost_airflow.Label'
              )}
            </div>
            <FormInput
              styles={{
                itemClassName: styles.formItem,
                inputClassName: styles.formInputDescribe,
              }}
              name="modeBoostAirflow"
              type="number"
              pattern={integerPattern}
              value={values.modeBoostAirflow}
              error={errors.modeBoostAirflow}
              handleBlur={() => setFieldTouched('modeBoostAirflow')}
              handleChange={handleChange}
            />
          </div>
        </Col>
        <Col span={4} lg={4} xl={3} xxl={3} className={styles.col}>
          <div className={styles.field}>
            <div className={styles.formLabel}>
              {t(
                'Machine_settings.Reserved_options_section.Inputs.Diff_st_high.Label'
              )}
            </div>
            <FormInput
              styles={{
                itemClassName: styles.formItem,
                inputClassName: styles.formInputDescribe,
              }}
              name="diffStH"
              type="number"
              value={values.diffStH}
              error={errors.diffStH}
              pattern={floatDoubleDigitPattern}
              handleBlur={() => setFieldTouched('diffStH')}
              handleChange={handleChange}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.formLabel}>
              {t(
                'Machine_settings.Reserved_options_section.Inputs.Diff_st_low.Label'
              )}
            </div>
            <FormInput
              styles={{
                itemClassName: styles.formItem,
                inputClassName: styles.formInputDescribe,
              }}
              name="diffStL"
              type="number"
              value={values.diffStL}
              error={errors.diffStL}
              pattern={floatDoubleDigitPattern}
              handleBlur={() => setFieldTouched('diffStL')}
              handleChange={handleChange}
            />
          </div>
        </Col>
        <Col span={5} lg={5} xl={4} xxl={4} className={styles.col}>
          <div className={styles.field}>
            <div className={styles.formLabel}>
              {t(
                'Machine_settings.Reserved_options_section.Inputs.Opening_outlet_time.Label'
              )}
            </div>
            <FormInput
              styles={{
                itemClassName: styles.formItem,
                inputClassName: styles.formInputDescribe,
              }}
              name="openingOutletTime"
              type="number"
              value={values.openingOutletTime}
              error={errors.openingOutletTime}
              pattern={integerPattern}
              handleBlur={() => setFieldTouched('openingOutletTime')}
              handleChange={handleChange}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.formLabel}>
              {t(
                'Machine_settings.Reserved_options_section.Inputs.Closing_outlet_time.Label'
              )}
            </div>
            <FormInput
              styles={{
                itemClassName: styles.formItem,
                inputClassName: styles.formInputDescribe,
              }}
              name="closingOutletTime"
              type="number"
              value={values.closingOutletTime}
              error={errors.closingOutletTime}
              pattern={integerPattern}
              handleBlur={() => setFieldTouched('closingOutletTime')}
              handleChange={handleChange}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

ReservedOptions.propTypes = propTypes;
