import { handleActions, combineActions } from 'redux-actions';
import { fromJS } from 'immutable';

import { mapListToObject } from 'helpers/common';
import { merger } from 'ducks/projects/reducer';

import { LOGOUT_USER } from '../user/actions';
import {
  GET_MACHINES_REQUEST,
  GET_MACHINES_RESPONSE,
  GET_MACHINES_ERROR,
  GET_MACHINE_BY_ID_REQUEST,
  GET_MACHINE_BY_ID_RESPONSE,
  UPDATE_MACHINES_REQUEST,
  UPDATE_MACHINES_RESPONSE,
  UPDATE_MACHINES_ERROR,
  CREATE_MACHINE_RESPONSE,
  CREATE_MACHINE_ERROR,
} from './actions';

const initialState = fromJS({
  machinesCount: 0,
  machines: {},
  machinesIds: [],
  machine: {},
  isRequesting: false,
  isUpdating: false,
  error: '',
});

export default handleActions(
  {
    [LOGOUT_USER]: (state) => state.merge(initialState),
    [combineActions(GET_MACHINES_REQUEST, GET_MACHINE_BY_ID_REQUEST)]: (
      state
    ) => state.set('isRequesting', true),
    [GET_MACHINES_RESPONSE]: (state, action) => {
      const { 'hydra:member': collection, 'hydra:totalItems': count } =
        action.payload.machines;
      const requestedMachines = mapListToObject(collection);
      const machinesIds = collection.map(({ id }) => id);

      return state
        .updateIn(['machines'], (machines) =>
          action.payload.page === 1
            ? fromJS(requestedMachines)
            : machines.merge(requestedMachines)
        )
        .updateIn(['machinesIds'], (ids) =>
          action.payload.page === 1
            ? fromJS(machinesIds)
            : ids.concat(machinesIds)
        )
        .set('machinesCount', count)
        .set('isRequesting', false);
    },
    [GET_MACHINE_BY_ID_RESPONSE]: (state, action) =>
      state
        .set('machine', fromJS(action.payload.machine))
        .set('isRequesting', false),
    [UPDATE_MACHINES_REQUEST]: (state) => state.set('isUpdating', true),
    [UPDATE_MACHINES_RESPONSE]: (state, action) => {
      const updatedMachines = mapListToObject(action.payload.machines);

      return state
        .mergeWith(merger, fromJS({ machines: updatedMachines }))
        .set('isUpdating', false);
    },
    [CREATE_MACHINE_RESPONSE]: (state, action) => {
      const newMachine = action.payload.machine;

      return state.mergeWith(
        merger,
        fromJS({ machines: { [newMachine.id]: newMachine } })
      );
    },
    [combineActions(
      GET_MACHINES_ERROR,
      UPDATE_MACHINES_ERROR,
      CREATE_MACHINE_ERROR
    )]: (state, action) => state.set('error', action.payload.error),
  },
  initialState
);
