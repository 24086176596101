import { handleActions, combineActions } from 'redux-actions';
import { fromJS, List } from 'immutable';

import { mapListToObject } from '../../helpers/common';

import { LOGOUT_USER } from '../user/actions';
import {
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_RESPONSE,
  GET_PROJECTS_ERROR,
  GET_PROJECT_BY_ID_REQUEST,
  GET_PROJECT_BY_ID_RESPONSE,
  UPDATE_PROJECTS_REQUEST,
  UPDATE_PROJECTS_RESPONSE,
  UPDATE_PROJECTS_ERROR,
  CREATE_PROJECT_ERROR,
  GET_PROJECTS_PARAMS_RESPONSE,
  GET_PROJECTS_PARAMS_ERROR,
} from './actions';

const { isList } = List;
// Custom 'deep' merger that replaces lists
// instead of concatenating them deceitfully.
export function merger(a, b) {
  if (a && a.mergeWith && !isList(a) && !isList(b)) {
    return a.mergeWith(merger, b);
  }
  return b;
}

const initialState = fromJS({
  projectsCount: 0,
  projects: {},
  projectsIds: [],
  project: {
    id: 0,
    machines: [],
    installer: {},
    address: '',
    houseNumber: '',
    reference: '',
    postcode: '',
    city: '',
    airRef: '',
    country: '',
  },
  params: {
    SUBTYPES: {},
    REGULATION_TYPES: {},
    MODES: {},
    UNITS_REFERENCES: {},
    COUNTRIES: {},
  },
  isRequesting: false,
  isUpdating: false,
  error: '',
});

export default handleActions(
  {
    [LOGOUT_USER]: (state) => state.merge(initialState),
    [combineActions(GET_PROJECTS_REQUEST, GET_PROJECT_BY_ID_REQUEST)]: (
      state
    ) => state.set('isRequesting', true),
    [GET_PROJECTS_RESPONSE]: (state, action) => {
      const { 'hydra:member': collection, 'hydra:totalItems': count } =
        action.payload.projects;
      const requestedProjects = mapListToObject(collection);
      const projectsIds = collection.map(({ id }) => id);

      return state
        .updateIn(['projects'], (projects) =>
          action.payload.page === 1
            ? fromJS(requestedProjects)
            : projects.merge(requestedProjects)
        )
        .updateIn(['projectsIds'], (ids) =>
          action.payload.page === 1
            ? fromJS(projectsIds)
            : ids.concat(projectsIds)
        )
        .set('projectsCount', count)
        .set('isRequesting', false);
    },
    [GET_PROJECT_BY_ID_RESPONSE]: (state, action) =>
      state
        .set('project', fromJS(action.payload.project))
        .set('isRequesting', false),
    [UPDATE_PROJECTS_REQUEST]: (state) => state.set('isUpdating', true),
    [UPDATE_PROJECTS_RESPONSE]: (state, action) => {
      const updatedProjects = mapListToObject(action.payload.projects);

      return state
        .mergeWith(merger, fromJS({ projects: updatedProjects }))
        .set('isUpdating', false);
    },
    [GET_PROJECTS_PARAMS_RESPONSE]: (state, action) =>
      state.mergeWith(merger, fromJS({ params: action.payload.params })),
    [combineActions(
      GET_PROJECTS_ERROR,
      UPDATE_PROJECTS_ERROR,
      CREATE_PROJECT_ERROR,
      GET_PROJECTS_PARAMS_ERROR
    )]: (state, action) => state.set('error', action.payload.error),
  },
  initialState
);
