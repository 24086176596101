import { request } from 'utils/request';
import { ENDPOINTS } from '../../constants';

const loginUser = ({ username, password }) =>
  request.post(ENDPOINTS.LOGIN, {
    username,
    password,
  });

const recoverPassword = (data) =>
  request.post(ENDPOINTS.RECOVER, data, {
    headers: { 'content-type': 'application/json' },
  });

const resetPassword = (data) =>
  request.post(ENDPOINTS.RESET_PASSWORD, data, {
    headers: { 'content-type': 'application/json' },
  });

export const authServices = {
  loginUser,
  recoverPassword,
  resetPassword,
};
