import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';

import { sanitizeValue } from 'utils/formUtils';

const propTypes = {
  username: PropTypes.string,
  isError: PropTypes.bool,
  getFieldDecorator: PropTypes.func.isRequired,
  itemClassName: PropTypes.string,
  inputClassName: PropTypes.string,
};

export function LoginField({
  username,
  isError,
  getFieldDecorator,
  itemClassName,
  inputClassName,
}) {
  const { t } = useTranslation();

  // The special chars set is TBD
  const testRegex = /^[.,!?_"@#$%^&*:;(){}|<>0-9a-zA-Z]*$/gm;

  return (
    <Form.Item
      validateStatus={isError ? 'error' : undefined}
      className={itemClassName}
    >
      {getFieldDecorator('username', {
        getValueFromEvent: (e) => {
          const newValue = sanitizeValue(e.currentTarget.value, 30);

          return testRegex.test(newValue) ? newValue : username;
        },
        validateFirst: true,
        rules: [
          {
            required: true,
            message: t('Login_form.Inputs.Login.Messages.Required'),
          },
        ],
      })(
        <Input
          placeholder={t('Login_form.Inputs.Login.Placeholder')}
          maxLength={30}
          className={inputClassName}
        />
      )}
    </Form.Item>
  );
}

LoginField.propTypes = propTypes;
