import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';

const propTypes = {
  name: PropTypes.string,
  help: PropTypes.string,
  isError: PropTypes.bool,
  placeholder: PropTypes.string,
  getFieldDecorator: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func,
  itemClassName: PropTypes.string,
  inputClassName: PropTypes.string,
};

export function PasswordField({
  name,
  help,
  isError,
  placeholder,
  getFieldDecorator,
  handleKeyDown,
  itemClassName,
  inputClassName,
}) {
  const { t } = useTranslation();

  return (
    <Form.Item
      validateStatus={isError ? 'error' : undefined}
      help={help || undefined}
      className={itemClassName}
    >
      {getFieldDecorator(name || 'password', {
        validateFirst: true,
        rules: [
          {
            required: true,
            message: t('Login_form.Inputs.Password.Messages.Required'),
          },
          {
            min: 4,
            message: t('Login_form.Inputs.Password.Messages.Min'),
          },
        ],
      })(
        <Input.Password
          type="password"
          name={name || 'password'}
          maxLength={30}
          placeholder={placeholder}
          className={inputClassName}
          onKeyDown={handleKeyDown}
        />
      )}
    </Form.Item>
  );
}

PasswordField.propTypes = propTypes;
