import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './MachineSettings.module.css';

export function ProjectInfo(props) {
  const { t } = useTranslation();
  return (
    <div className={styles.project_info_block}>
      <div className={styles.columnLabel}>
        {t('Machine_settings.Folder_reference')}
      </div>
      <div>
        {Object.values(props).map((prop) => {
          const { lbl, value } = prop;

          return (
            <div key={`${lbl}${value}`}>
              {lbl}: {value}
            </div>
          );
        })}
      </div>
    </div>
  );
}

ProjectInfo.defaultProps = {
  reference: '',
  address: '',
  postCode: '',
  city: '',
  installer: '',
};
