import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

import { LOGOUT_USER } from '../user/actions';
import {
  SET_CREDENTIALS,
  LOGIN_ERROR,
  LOGIN_ERROR_RESET_REQUEST,
  DELETE_CREDENTIALS,
} from './actions';

const initialState = fromJS({
  credentials: {
    username: '',
    password: '',
    remember: false,
  },
  error: {
    statusCode: null,
    error: '',
  },
});

export default handleActions(
  {
    [LOGOUT_USER]: (state) => state.merge(initialState),
    [SET_CREDENTIALS]: (state, { payload }) =>
      state
        .setIn(['credentials', 'username'], payload.username)
        .setIn(['credentials', 'password'], payload.password)
        .setIn(['credentials', 'remember'], payload.rememberCredentials),
    [DELETE_CREDENTIALS]: (state) =>
      state
        .setIn(['credentials', 'username'], '')
        .setIn(['credentials', 'password'], ''),
    [LOGIN_ERROR]: (state, { payload }) =>
      state
        .setIn(['error', 'statusCode'], payload.error.statusCode)
        .setIn(['error', 'error'], payload.error.error),
    [LOGIN_ERROR_RESET_REQUEST]: (state) =>
      state.setIn(['error', 'statusCode'], null).setIn(['error', 'error'], ''),
  },
  initialState
);
