const MAX_AIRLEAK_FLOW = 5.5;

export const grillEffectiveSurfaces = [
  {
    id: 1,
    label: '200*100',
    effectiveOpenedAirgrillSurface: 91,
  },
  {
    id: 2,
    label: '300*100',
    effectiveOpenedAirgrillSurface: 153,
  },
  {
    id: 3,
    label: '400*100',
    effectiveOpenedAirgrillSurface: 216,
  },
  {
    id: 4,
    label: '500*100',
    effectiveOpenedAirgrillSurface: 279,
  },
  {
    id: 5,
    label: '600*100',
    effectiveOpenedAirgrillSurface: 342,
  },
  {
    id: 6,
    label: '700*100',
    effectiveOpenedAirgrillSurface: 405,
  },
];

export function calculateRoomParams(
  room,
  index,
  { selectedAirGrill, secondChannelSelectedAirGrill }
) {
  const calculated = {
    id: index,
    dimension: selectedAirGrill?.dimension || 0,
    treatedVolume: room.treatedSurface * room.ceilingHeight || 0,
    treatedSurface: Number.parseFloat(room.treatedSurface, 10) || 0,
    totalAirflowLowNoise: selectedAirGrill?.totalAirflowLowNoise || 0,
    totalAirflowBoostMode: selectedAirGrill?.totalAirflowBoostMode || 0,
    effectiveOpenedAirgrillSurface:
      grillEffectiveSurfaces.find(
        (grill) => grill.id === room.selectedAirgrillDimension
      )?.effectiveOpenedAirgrillSurface || 0,
    effectiveClosedAirgrillSurface: room.airleakStatus
      ? selectedAirGrill?.effectiveCloseAirgrillSurfaceWithAirLeakStatusOn || 0
      : selectedAirGrill?.effectiveCloseAirgrillSurfaceWithAirLeakStatusOff ||
        0,
    autoSettingParam:
      Math.round(
        (room.treatedSurface * room.ceilingHeight * 4 * 2.4) / 3.0 / 36
      ) * 100,
  };
  calculated.secondChannelTreatedSurface =
    room.secondChannelTreatedSurface || 0;
  calculated.secondChannelCeilingHeight = room.secondChannelCeilingHeight || 0;
  if (!index && secondChannelSelectedAirGrill) {
    calculated.secondChannelDimension = secondChannelSelectedAirGrill.dimension;
    calculated.secondChannelTreatedSurface =
      room.secondChannelTreatedSurface || 0;
    calculated.secondChannelCeilingHeight =
      room.secondChannelCeilingHeight || 0;
    calculated.secondChannelTreatedVolume =
      room.secondChannelTreatedSurface * room.secondChannelCeilingHeight || 0;
    calculated.secondChannelTotalAirflowLowNoise =
      secondChannelSelectedAirGrill?.totalAirflowLowNoise || 0;
    calculated.secondChannelTotalAirflowBoostMode =
      secondChannelSelectedAirGrill?.totalAirflowBoostMode || 0;
    calculated.secondChannelEffectiveOpenedAirgrillSurface =
      grillEffectiveSurfaces.find(
        (grill) => grill.id === room.secondChannelSelectedAirgrillDimension
      )?.effectiveOpenedAirgrillSurface || 0;
    calculated.secondChannelEffectiveClosedAirgrillSurface =
      room.secondChannelAirleakStatus
        ? secondChannelSelectedAirGrill.effectiveCloseAirgrillSurfaceWithAirLeakStatusOn ||
          0
        : secondChannelSelectedAirGrill.effectiveCloseAirgrillSurfaceWithAirLeakStatusOff ||
          0;
    calculated.secondChannelAutoSettingParam =
      Math.round(
        (calculated.secondChannelTreatedSurface *
          room.secondChannelCeilingHeight *
          4 *
          2.4) /
          3.0 /
          36
      ) * 100;
  }
  return calculated;
}

export function calculateMainParams(
  rooms,
  { standartAirSpeed, maxAirSpeed, minIndoorUnitAirFlow },
  selectedUnit
) {
  let totalAirflowLowNoise = 0;
  let totalAirflowBoostMode = 0;
  let airSpeedLowNoise = 0;
  let airSpeedBoostMode = 0;
  const sumOfEffectiveOpenedAirgrillSurface =
    rooms.reduce((acc, room) => acc + room.effectiveOpenedAirgrillSurface, 0) +
    (rooms[0].secondChannelEffectiveOpenedAirgrillSurface || 0);

  const sumOfEffectiveClosedAirgrillSurface =
    rooms.reduce((acc, room) => acc + room.effectiveClosedAirgrillSurface, 0) +
    (rooms[0].secondChannelEffectiveClosedAirgrillSurface || 0);

  const sumOfTotalAirflowLowNoise = parseFloat(
    rooms.reduce((acc, room) => acc + room.totalAirflowLowNoise, 0) +
      (rooms[0].secondChannelTotalAirflowLowNoise || 0)
  );

  if (sumOfTotalAirflowLowNoise > Number(selectedUnit?.airflowLowNoise)) {
    totalAirflowLowNoise = parseFloat(
      Number(selectedUnit?.airflowLowNoise).toFixed(1)
    );
  } else {
    totalAirflowLowNoise = parseFloat(
      Number(
        (sumOfEffectiveOpenedAirgrillSurface * standartAirSpeed * 3600) / 10000
      ).toFixed(1)
    );
  }

  const sumOfTotalAirflowBoostMode = parseFloat(
    rooms.reduce((acc, room) => acc + room.totalAirflowBoostMode, 0) +
      (rooms[0].secondChannelTotalAirflowBoostMode || 0)
  );

  if (sumOfTotalAirflowBoostMode > Number(selectedUnit?.airflowBoostMode)) {
    totalAirflowBoostMode = parseFloat(
      Number(selectedUnit?.airflowBoostMode).toFixed(1)
    );
  } else {
    totalAirflowBoostMode = parseFloat(
      Number(
        (sumOfEffectiveOpenedAirgrillSurface * maxAirSpeed * 3600) / 10000
      ).toFixed(1)
    );
  }
  if (sumOfEffectiveOpenedAirgrillSurface) {
    airSpeedLowNoise = parseFloat(
      Number(
        (totalAirflowLowNoise / sumOfEffectiveOpenedAirgrillSurface / 3600) *
          10000
      ).toFixed(3)
    );
    airSpeedBoostMode = parseFloat(
      Number(
        (totalAirflowBoostMode / sumOfEffectiveOpenedAirgrillSurface / 3600) *
          10000
      ).toFixed(3)
    );
  }

  airSpeedLowNoise =
    airSpeedLowNoise > standartAirSpeed ? standartAirSpeed : airSpeedLowNoise;
  airSpeedBoostMode =
    airSpeedBoostMode > maxAirSpeed ? maxAirSpeed : airSpeedBoostMode;

  const calculatedRooms = rooms.map((room, index) => {
    const {
      dimension = 0,
      treatedVolume = 0,
      effectiveOpenedAirgrillSurface,
      effectiveClosedAirgrillSurface,
      secondChannelDimension = 0,
      secondChannelEffectiveOpenedAirgrillSurface,
      secondChannelTreatedVolume,
    } = room;

    const airJetLengthInCoolingLowNoise = Number(
      (-0.0000022 * dimension + 0.0041) * dimension * airSpeedLowNoise
    ).toFixed(1);
    const airJetLengthInHeatingLowNoise = Number(
      (-0.0000032 * dimension + 0.006) * dimension * airSpeedLowNoise
    ).toFixed(1);
    const airJetLengthInCoolingBoostMode = Number(
      (-0.0000022 * dimension + 0.0041) * dimension * airSpeedBoostMode
    ).toFixed(1);
    const airJetLengthInHeatingBoostMode = Number(
      (-0.0000032 * dimension + 0.006) * dimension * airSpeedBoostMode
    ).toFixed(1);
    const grillsAirflowLowNoise = parseFloat(
      Number(
        (+effectiveOpenedAirgrillSurface * airSpeedLowNoise * 3600) / 10000
      ).toFixed(1)
    );
    const grillsAirflowBoostMode = parseFloat(
      Number(
        (+effectiveOpenedAirgrillSurface * airSpeedBoostMode * 3600) / 10000
      ).toFixed(1)
    );
    const airLeakSurface =
      sumOfEffectiveClosedAirgrillSurface -
      effectiveClosedAirgrillSurface +
      effectiveOpenedAirgrillSurface;

    let airLeak = 0;
    if (airLeakSurface) {
      airLeak = (minIndoorUnitAirFlow / 3600 / airLeakSurface) * 10000;
    }
    let rateOfVolumeLowNoise = 0;
    let rateOfVolumeBoostMode = 0;

    if (treatedVolume) {
      rateOfVolumeLowNoise = Number(
        grillsAirflowLowNoise / treatedVolume
      ).toFixed(1);
      rateOfVolumeBoostMode = Number(
        grillsAirflowBoostMode / treatedVolume
      ).toFixed(1);
    }
    const secondChannel = {};

    if (!index) {
      secondChannel.secondChannelAirJetLengthInCoolingLowNoise = Number(
        (-0.0000022 * secondChannelDimension + 0.0041) *
          secondChannelDimension *
          airSpeedLowNoise
      ).toFixed(1);
      secondChannel.secondChannelAirJetLengthInHeatingLowNoise = Number(
        (-0.0000032 * secondChannelDimension + 0.006) *
          secondChannelDimension *
          airSpeedLowNoise
      ).toFixed(1);
      secondChannel.secondChannelAirJetLengthInCoolingBoostMode = Number(
        (-0.0000022 * secondChannelDimension + 0.0041) *
          secondChannelDimension *
          airSpeedBoostMode
      ).toFixed(1);
      secondChannel.secondChannelAirJetLengthInHeatingBoostMode = Number(
        (-0.0000032 * secondChannelDimension + 0.006) *
          secondChannelDimension *
          airSpeedBoostMode
      ).toFixed(1);
      secondChannel.secondChannelGrillsAirflowLowNoise = parseFloat(
        Number(
          (secondChannelEffectiveOpenedAirgrillSurface *
            airSpeedLowNoise *
            3600) /
            10000
        ).toFixed(1)
      );
      secondChannel.secondChannelGrillsAirflowBoostMode = parseFloat(
        Number(
          (secondChannelEffectiveOpenedAirgrillSurface *
            airSpeedBoostMode *
            3600) /
            10000
        ).toFixed(1)
      );
      if (secondChannelTreatedVolume) {
        secondChannel.secondChannelRateOfVolumeLowNoise = Number(
          secondChannel.secondChannelGrillsAirflowLowNoise /
            secondChannelTreatedVolume
        ).toFixed(1);
        secondChannel.secondChannelRateOfVolumeBoostMode = Number(
          secondChannel.secondChannelGrillsAirflowBoostMode /
            secondChannelTreatedVolume
        ).toFixed(1);
      }
    }

    return {
      ...room,
      ...secondChannel,
      airJetLengthInCoolingLowNoise,
      airJetLengthInHeatingLowNoise,
      airJetLengthInCoolingBoostMode,
      airJetLengthInHeatingBoostMode,
      grillsAirflowLowNoise,
      grillsAirflowBoostMode,
      airLeak,
      rateOfVolumeLowNoise,
      rateOfVolumeBoostMode,
    };
  });

  const insufficientAirleak = calculatedRooms.find(
    (room) => room.airLeak > MAX_AIRLEAK_FLOW
  );

  return {
    calculatedRoomParams: calculatedRooms,
    insufficientAirleak: parseFloat(
      Number(insufficientAirleak?.airLeak || 0).toFixed(1)
    ),
    airSpeedLowNoise: parseFloat(airSpeedLowNoise),
    airSpeedBoostMode: parseFloat(airSpeedBoostMode),
    totalAirflowLowNoise,
    totalAirflowBoostMode,
  };
}

export function selectAirgrillByAutoParam(autoSettingParam, { min, max }) {
  let selectedGrill;
  if (!autoSettingParam) {
    selectedGrill = null;
  }
  if (autoSettingParam < min) {
    selectedGrill = '200*100';
  }
  if (autoSettingParam > max) {
    selectedGrill = '700*100';
  } else if (autoSettingParam >= min && autoSettingParam <= max) {
    selectedGrill = `${autoSettingParam}*100`;
  }

  return selectedGrill;
}
