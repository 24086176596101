import { createAction } from 'redux-actions';

import { getTypeCreator } from 'utils';

const getType = getTypeCreator('@@app/userInfo');

// GET LOGGED-IN USER INFO
export const LOGGED_USER_INFO_REQUEST = getType('LOGGED_USER_INFO_REQUEST');
export const loggedUserInfoRequest = createAction(LOGGED_USER_INFO_REQUEST);

export const LOGGED_USER_INFO_RESPONSE = getType('LOGGED_USER_INFO_RESPONSE');
export const loggedUserInfoResponse = createAction(LOGGED_USER_INFO_RESPONSE);

export const LOGGED_USER_INFO_ERROR = getType('LOGGED_USER_INFO_ERROR');
export const loggedUserInfoError = createAction(LOGGED_USER_INFO_ERROR);

export const LOGOUT_USER = getType('LOGOUT_USER');
export const logoutUser = createAction(LOGOUT_USER);

// GET USERS IN CHUNKS (PAGES)
export const GET_USERS_REQUEST = getType('GET_USERS_REQUEST');
export const getUsersRequest = createAction(GET_USERS_REQUEST);

export const GET_USERS_RESPONSE = getType('GET_USERS_RESPONSE');
export const getUsersResponse = createAction(GET_USERS_RESPONSE);

export const GET_USERS_ERROR = getType('GET_USERS_ERROR');
export const getUsersError = createAction(GET_USERS_ERROR);

// GET ALL USERS
export const GET_ALL_USERS_REQUEST = getType('GET_ALL_USERS_REQUEST');
export const getAllUsersRequest = createAction(GET_ALL_USERS_REQUEST);

export const GET_ALL_USERS_RESPONSE = getType('GET_ALL_USERS_RESPONSE');
export const getAllUsersResponse = createAction(GET_ALL_USERS_RESPONSE);

export const GET_ALL_USERS_ERROR = getType('GET_ALL_USERS_ERROR');
export const getAllUsersError = createAction(GET_ALL_USERS_ERROR);

// GET USERS COUNT
export const GET_USERS_COUNT_REQUEST = getType('GET_USERS_COUNT_REQUEST');
export const getUsersCountRequest = createAction(GET_USERS_COUNT_REQUEST);

export const GET_USERS_COUNT_RESPONSE = getType('GET_USERS_COUNT_RESPONSE');
export const getUsersCountResponse = createAction(GET_USERS_COUNT_RESPONSE);

export const GET_USERS_COUNT_ERROR = getType('GET_USERS_COUNT_ERROR');
export const getUsersCountError = createAction(GET_USERS_COUNT_ERROR);
