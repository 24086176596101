import { createAction } from 'redux-actions';

import { getTypeCreator } from 'utils';

const getType = getTypeCreator('@@app/authentication');

/**
 * Register
 */
export const REGISTER_REQUEST = getType('REGISTER_REQUEST');
export const registerRequest = createAction(REGISTER_REQUEST);

export const REGISTER_RESPONSE = getType('REGISTER_RESPONSE');
export const registerResponse = createAction(REGISTER_RESPONSE);

export const REGISTER_ERROR = getType('REGISTER_ERROR');
export const registerError = createAction(REGISTER_ERROR);

/**
 * Log in
 */
export const LOGIN_REQUEST = getType('LOGIN_REQUEST');
export const loginRequest = createAction(LOGIN_REQUEST);

export const LOGIN_RESPONSE = getType('LOGIN_RESPONSE');
export const loginResponse = createAction(LOGIN_RESPONSE);

export const LOGIN_ERROR = getType('LOGIN_ERROR');
export const loginError = createAction(LOGIN_ERROR);

export const LOGIN_ERROR_RESET_REQUEST = getType('LOGIN_ERROR_RESET_REQUEST');
export const loginErrorResetRequest = createAction(LOGIN_ERROR_RESET_REQUEST);

export const SET_CREDENTIALS = getType('SET_CREDENTIALS');
export const setCredentials = createAction(SET_CREDENTIALS);

export const DELETE_CREDENTIALS = getType('DELETE_CREDENTIALS');
export const delCredentials = createAction(DELETE_CREDENTIALS);

/**
 * Log out
 */
export const LOGOUT = getType('LOGOUT');
export const logout = createAction(LOGOUT);

/**
 * Recover password
 */
export const PASSWORD_RECOVER_REQUEST = getType('PASSWORD_RECOVER_REQUEST');
export const passwordRecoverRequest = createAction(PASSWORD_RECOVER_REQUEST);

export const PASSWORD_RESET_REQUEST = getType('PASSWORD_RESET_REQUEST');
export const passwordResetRequest = createAction(PASSWORD_RESET_REQUEST);
