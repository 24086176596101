export const LOGIN = 'token';
export const RECOVER = 'users/password-recover';
export const RESET_PASSWORD = 'users/password-change';

export const USERS = 'users';
export const USER = 'users/:id';

export const PROJECTS = 'projects';
export const PROJECT = 'projects/:id';

export const FILE_HISTORY_ENTRIES = 'file_history_entries';

export const MACHINES = 'machines';
export const MACHINE = 'machines/:id';
export const MACHINE_CLONE = 'machines/:id/clone';

export const MACHINES_SETTINGS = 'settings';
export const MACHINE_SETTINGS = 'settings/:id';
export const MACHINE_SETTINGS_FILE = 'settings/:id/file';

export const ROOMS_CONFIGURATIONS = 'room_configurations';
export const ROOM_CONFIGURATIONS = 'room_configurations/:id';
export const AIR_DISTRIBUTION = 'air_distribution_types';
export const CONFIG_OPTIONS = 'configuration_options';
export const DEFAULT_VALUES = 'default_values';
export const USER_INFO = 'users/:id';
export const AIRGRILL_OUTLETS = 'outlets';
export const INSTALLATION_TYPES = 'installation_types';
export const REFERENCE_UNITS = 'reference_units';
export const ROOM_NAMES = 'room_names';

/**
 * @param {REVERSIBLE_TYPES} type [t_one_load, aqua_air_load]
 * @param {String} id_ihm
 */
export const REVERSIBLE_BINARY = '/binary-file/reversible';

// export const PROJECTS_TYPES = 'project_types';
export const PROJECTS_SUBTYPES = 'project_subtypes';
export const REGULATION_TYPES = 'regulation_types';
export const MODES = 'modes';
export const COUNTRIES = 'countries';
