const fr = {
  translations: {
    Common_terms: {
      Measurements: {
        Airflow: 'm³/h',
        Airspeed: 'm/s',
        Pressure: 'Pa',
        Temperature: '°C',
      },
      Hints: {
        Editable: '*modifiable',
      },
      Type_labels: {
        // TODO: Important! These translations should be
        // kept in sync with the backend ones for the table
        // search feature to work as expected.
        House_or_flat: 'Maison ou appartement',
        Subdivision_or_building: `Complexe résidentiel ou bâtiment`,
      },
    },
    Header: {
      Breadcrumbs: {
        Root: 'Accueil',
        Projects: 'Projets',
        Project_details: 'Détails du projet',
        Project_machine_settings: 'Paramètres de la machine',
        Machine_settings: 'Paramètres de la machine',
        Project_new: 'Nouveau projet',
        Options: 'Les options',
      },
    },
    Footer: {
      Links: {
        TOne_file: 'Fichier SOFT T.One V/H V08',
        TOne_manual: 'Guide utilisateur T.One V/H',
        Aqua_air_file: 'Fichier SOFT T.One AquaAIR/AIR V15',
        Aqua_air_manual: 'Guide utilisateur T.One AquaAIR/AIR',
      },
    },
    Login_form: {
      Inputs: {
        Login: {
          Placeholder: 'Identifiant',
          Messages: {
            Required: 'Saisissez votre identifiant',
            Min: '4 caractères minimum',
          },
        },
        Password: {
          Placeholder: 'Mot de passe',
          Messages: {
            Required: 'Saisissez votre mot de passe',
            Min: '4 caractères minimum',
          },
        },
        Remember: { Label: 'Se souvenir de moi' },
      },
      Login_button: 'Se connecter',
      Forgot_link: 'Mot de passe oublié ?',
      Error_message: 'Identifiant ou mot de passe incorrect',
    },
    Recovery_form: {
      Intro: {
        Title: 'Récupération du mot de passe',
        Content:
          'Saisissez votre identifiant afin de recevoir les instructions par e.mail',
      },
      Submit_button: 'Envoyer',
      Cancel_button: 'Annuler',
      Recaptcha: {
        Messages: {
          Required: 'Je ne suis pas un robot.',
        },
      },
      Forgot_link: 'Identifiant oublié ?',
      Back_link: 'Retour',
      Contact_message:
        'Il est impossible de récupérer votre mot de passe automatiquement. Veuillez contacter votre interlocuteur du Groupe Aldes afin de retrouver l’accès à votre compte.',
      Success_message:
        'Si vous avez une adresse e-mail valide associée à votre compte, un mot de passe temporaire et d’autres instructions viennent d’être envoyés à cette adresse.',
    },
    Reset_password_form: {
      Intro: {
        Title: 'Réinitialiser le mot de passe',
        Content: 'Entrez votre nouveau mot de passe ci-dessous',
      },
      Inputs: {
        Password_1: { Placeholder: 'Nouveau mot de passe' },
        Password_2: { Placeholder: 'Nouveau mot de passe' },
        Match_error: 'Mots de passe saisis différents',
      },
      Submit_button: 'Envoyer',
      Error_message: `Une erreur s'est produite.Veuillez recharger la page.`,
      Success_message: 'Votre mot de passe a été changé !',
      Login_link: 'Connectez vous ici',
    },
    Home_page: {
      Main_menu: {
        Projects: 'Mes projets',
        New_project: 'Nouveau projet',
        Options: 'Options',
      },
    },
    Projects_page: {
      Tab_titles: {
        Projects: 'Projets',
        Machines: 'Machines',
      },
    },
    Projects_filters: {
      Inputs: {
        Search_term: { Placeholder: 'Recherche par mot clés' },
        Installer: { Placeholder: 'Installateur' },
        Show_deleted: { Label: 'Afficher les projets supprimés' },
        Per_page: 'Recherches par page',
      },
      Delete_button: 'Effacer',
      Download_button: 'Extraction au format Excel',
      Config_button: 'ID Config',
      Modals: {
        Delete: {
          Title: 'Effacer les projets',
          OK_button: 'Confirmer',
          Cancel_button: 'Annuler',
          Content: 'Êtes vous sûr de vouloir supprimer: ',
        },
        Config: {
          Title: 'ID Config',
          OK_button: 'OK',
          Content: `Le nom du fichier de Configuration d’une installation 
T.One® AquaAIR / AIR généré par cette application se décompose
comme dans l’exemple ci-dessous :
Exemple de nom de fichier : V01_321_DUPONT_RDC_TOULOUSE.bin
- VERSION du soft de régulation T.One® AquaAIR / AIR= V01
- ID_INSTALLATION (CLEREGISTRE) = 321
- En diffus, NOM DU CLIENT / en collectif, NOM OPERATION = DUPONT
- REPERE (si plusieurs installations) = RDC
- VILLE = TOULOUSE
NE RENOMMER PAS / NE CHANGER PAS LE NOM DE CE FICHIER !`,
        },
      },
      Row_selection_hint: 'Vous avez sélectionné {{count}} projet',
      Row_selection_hint_plural: 'Vous avez sélectionné {{count}} projets',
    },
    Projects_table: {
      Column_titles: {
        Type: 'Type',
        Reference: 'Référence du projet',
        Machine_reference: 'Référence de la machine',
        Created_at: 'Date de création',
        Installer: 'Installateur',
        Id_installer: 'ID Installateur',
        Address: 'Adresse',
        Postcode: 'Code Postal',
        City_name: 'Ville',
      },
      Empty_text: 'Projet non trouvé',
    },
    Project_details: {
      General_info: {
        Title: 'Informations générales',
        Inputs: {
          Installer: {
            Placeholder: `Choisir l'installateur`,
          },
          Address: { Placeholder: 'Adresse' },
          House_number: { Placeholder: 'N°' },
          Project_ref: {
            Placeholder: 'Référence projet',
            Messages: {
              Required: 'Champ requis',
            },
          },
          Postcode: {
            Placeholder: 'Code Postal',
            Messages: {
              Required: 'Champ requis',
              Min: '5 caractères minimum',
            },
          },
          City: {
            Placeholder: 'Ville',
            Messages: {
              Required: 'Champ requis',
            },
          },
          Country: { Placeholder: 'Pays' },
          Show_deleted: { Label: 'Afficher les machines supprimées' },
        },
        GDPR: 'Ne pas renseigner des informations clients personnelles (RGPD)',
      },
      Buttons: {
        Save: 'Sauvegarder les changements',
        Add: 'Ajouter une machine',
        Delete: 'Supprimer les machines',
      },
      Modals: {
        Add: {
          Title: 'Nouvelle installation',
          Input: {
            Placeholder: 'Saisir Nom',
            Required_message: 'Champ requis',
          },
          OK_button: 'Confirmer',
          Cancel_button: 'Annuler',
        },
        Delete: {
          Title: 'Machines supprimées',
          Content: 'Etes-vous sûr de vouloir les supprimer ?',
        },
      },
      Row_selection_hint: 'Vous avez sélectionné {{count}} machine',
      Row_selection_hint_plural: 'Vous avez sélectionné {{count}} machines',
    },
    Machines_filters: {
      Inputs: {
        Search_term: { Placeholder: 'Recherche par libellé' },
        Search_project_id: { Placeholder: 'Recherche par identifiant' },
        Installer: { Placeholder: 'Installateur' },
        Units_ref: { Placeholder: 'Référence PAC AIR/AIR' },
        Show_deleted: { Label: 'Afficher les machines supprimés' },
        Per_page: 'Recherches par page',
      },
      Delete_button: 'Effacer',
      Download_button: 'Extraction au format Excel',
      Delete_modal: {
        Title: 'Effacer les machines',
        OK_button: 'Confirmer',
        Cancel_button: 'Annuler',
        Content: 'Êtes vous sûr de vouloir supprimer: ',
        Unlabeled_stub: 'UNLABELED [id:{{id}}]',
      },
      Row_selection_hint: 'Vous avez sélectionné {{count}} machine',
      Row_selection_hint_plural: 'Vous avez sélectionné {{count}} machines',
    },
    Machines_table: {
      Column_titles: {
        Duplicate: 'Dupliquer',
        Config: 'Paramétrer',
        Type: 'Type',
        Client: 'Référence chantier',
        Id: 'ID',
        Label: 'Libellé',
        Saved_at: 'Date de sauvegarde',
        Downloaded_at: 'Date de téléchargement fichier',
        Installer: 'Installateur',
        Regulation_type: 'Type de régulation',
        Mode_available: 'Réversibilité',
        Units_ref: 'Référence PAC AIR/AIR',
        Config_file: 'Fichier de paramétrage',
        Downloads: 'Téléchargements',
      },
      Empty_text: 'Projet non trouvé',
    },
    Machine_settings: {
      Go_to_project_button: 'Revenir au projet',
      Print_button: {
        Label: 'Imprimer',
        Tooltip: `Activez les Graphiques d'arrière-plan et sélectionnez la mise en page Paysage sur l'écran des paramètres d'impression, si nécessaire!`,
      },
      Save_button: 'Sauvegarder les changements',
      Download_button: 'Télécharger .bin',
      Popover: {
        text: `Pour télécharger le fichier, remplissez d'abord le formulaire`,
      },
      Leave_modal: {
        Title: 'Etes-vous sûr de vouloir quitter la page ?',
        Content:
          'Toutes les modifications non sauvegardées seront perdues. Etes-vous sûr de vouloir continuer ?',
      },
      Air_heat_pump_section: {
        Title: 'Pompe à chaleur air/air',
        Inputs: {
          Installation_type: { Label: `Type d'installation` },
          Units_reference: { Label: 'Références pompe à chaleur air/air' },
          Airflow_low: { Label: `Débit d'air UI mode Confort (sil.)` },
          Airflow_boost: { Label: `Débit d'air UI mode Boost (max.)` },
        },
      },
      Air_distribution_section: {
        Title: 'Distribution aéraulique',
        Inputs: {
          Type: { Label: 'Type' },
          Type_1: { Label: 'Plenum Standard - vitesse distribution < 1.5 m/s' },
          Type_2: {
            Label:
              'Gaines rectangulaires (Fib’Air) -  vitesse distribution max. 3 m/s',
          },
          Type_3: {
            Label:
              'Autres cas (gaines) / Perte de charge soufflage et reprise à définir',
          },
          Nominal_airflow: { Label: 'Au débit nominal' },
          Minimal_airflow: { Label: 'A débit mini. (1 bouche ouverte)' },
          External_pressure: { Label: 'Pression statique externe' },
          Airflow_therm_off: { Label: `Débit d'air quand Th. OFF` },
          Airflow_defrost_cycle: {
            Label: `Débit d'air durant le dégivrage`,
          },
          Airduct_leakage: { Label: 'Débit de fuite réseau aéraulique' },
          External_pressure_avaliable: {
            Label: 'Pression statique dispo Th. OFF',
          },
        },
      },
      Settings_modes_section: {
        Advanced_input: 'Paramétrage complet',
        Automatic_button: 'Sélection automatique des bouches',
      },
      Rooms_config_section: {
        Inputs: {
          Number_of_channels: { Label: 'Nombre de canaux' },
          Room_name: { Label: 'Nom des pièces' },
          Treated_surface: { Label: 'Surface traitée (m²)' },
          Ceiling_height: { Label: 'Hauteur sous plafond (m)' },
          Airgrill: { Label: 'Dimension grille choisie' },
          Airleak: {
            Label: 'Présence débit de fuite (fente)',
            Tooltip:
              'Pour le bon fonctionnement du système, les débits de fuites sur les bouches du séjour sont nécessaires.',
          },
          Minimal_indoor_airflow: {
            Label: `Débit d'air mini. soufflé par UI avec compresseur ON`,
          },
        },
        Text: {
          Airflow_low_noise: {
            Title: `Débit d'air UI mode confort (silence)`,
            Desc1: 'Débit soufflé total : {{value}} (m³/h)',
            Desc2:
              'Vitesse de soufflage (toutes bouches ouvertes) : {{value}} m/s',
          },
          Airflow_boost_mode: {
            Title: `Débit d'air UI mode boost (max.)`,
            Desc1: 'Débit soufflé total : {{value}} (m³/ h)',
            Desc2:
              'Vitesse de soufflage (toutes bouches ouvertes) : {{value}} m/s',
          },
        },
        Advanced_mode: {
          Inputs: {
            Treated_volume: { Label: 'Volume traité (m³)' },
            Airflow_with_4: { Label: 'Débit à 4 Vol/h (m³/h)' },
            Effective_opened_airgrill: {
              Label: 'Surface effective bouche ouverte (cm²)',
            },
            Effective_closed_airgrill: {
              Label: 'Surface effective bouche fermée (cm²)',
            },
            Grills_airflow: { Label: 'Débit grille (m³/ h)' },
            Rate_of_volume: { Label: 'Taux de brassage (vol/h)' },
            Air_jet_heating: {
              Label: 'Portée jet chaud / déflection 20° (m)',
            },
            Air_jet_cooling: {
              Label: 'Portée jet froid / déflection 20° (m)',
            },
          },
        },
      },
      Specific_options_section: {
        Title: 'Options de paramétrage',
        Inputs: {
          History_step: { Label: 'Pas historique (minutes)' },
          Standard_airspeed: {
            Label: `Consigne max croisière de vitesse d'air aux bouches (m/s)`,
          },
          Maximal_airspeed: {
            Label: `Consigne max pointe de vitesse d'air aux bouches (m/s)`,
          },
          Maximal_set_heating: {
            Label: 'Température limite haute en chauffage (plage 16-31 °C)',
          },
          Minimal_set_heating: {
            Label: 'Température limite basse en chauffage (plage 16-31 °C)',
          },
          Maximal_set_cooling: {
            Label:
              'Température limite haute en rafraîchissement (plage 16-31 °C)',
          },
          Minimal_set_cooling: {
            Label:
              'Température limite basse en rafraîchissement (plage 16-31 °C)',
          },
        },
      },
      Reserved_options_section: {
        Title: 'Options réservées Aldes',
        Inputs: {
          Minimal_airflow: { Label: `Débit d'air UI min. si COMP ON (m³/ h)` },
          Low_airflow: { Label: `Débit d'air UI confort si COMP ON (m³/ h)` },
          Boost_airflow: { Label: `Débit d'air UI boost si COMP ON (m³/ h)` },
          Diff_st_high: { Label: 'Diff ST Haute (°C)' },
          Diff_st_low: { Label: 'Diff ST Faible (°C)' },
          Opening_outlet_time: { Label: 'Délai ouverture bouche (s)' },
          Closing_outlet_time: { Label: 'Délai fermeture bouche (s)' },
        },
      },
      Room_names: {
        Living: 'Séjour',
        Office: 'Bureau',
        RO1: 'CH1',
        RO2: 'CH2',
        RO3: 'CH3',
        RO4: 'CH4',
        Lounge: 'Salon',
        Mezzanine: 'Mezzanine',
      },
      Error_message: 'Champ obligatoire',
      Minimal_Airleak_Error: `Débit de fuite d'air insuffisant`,
      Field_error: 'Valeurs incorrectes ',
      Folder_reference: 'Référence du dossier',
    },
    New_project_form: {
      Section_titles: {
        General: 'Informations générales',
        Client: 'Informations Client',
      },
      Inputs: {
        Type: { Label: 'Type' },
        Reference: {
          Label: 'Référence du projet "interne installateur"',
          Extra:
            'Ne pas renseigner des informations clients personnelles (RGPD)',
          Placeholder: 'Renseigner la référence projet',
          Messages: {
            Required: 'Champ obligatoire',
            Min: '2 caractères minimum',
          },
        },
        Installer: {
          Label: 'Installateur',
        },
        Installation_type: {
          Label: `Type d'installations`,
        },
        Address: {
          Label: 'Adresse',
          Placeholder: 'Nom de la rue',
          Messages: {
            Min: '2 caractères minimum',
          },
        },
        House_number: {
          Label: 'Nº',
          Placeholder: 'Nº',
        },
        Postcode: {
          Label: 'Code Postal',
          Placeholder: 'Renseigner le code postal',
          Messages: {
            Required: 'Champ obligatoire',
            Min: '5 caractères minimum',
          },
        },
        City: {
          Label: 'Ville',
          Placeholder: 'Renseigner la ville',
          Messages: {
            Required: 'Champ obligatoire',
            Min: '2 caractères minimum',
          },
        },
        Country: {
          Label: 'Pays',
          Placeholder: 'Renseigner le pays',
          Messages: {
            Required: 'Champ obligatoire',
          },
        },
      },
      Create_button: 'Créer projet',
      Blank_machine_label: 'Repere 1',
    },
    Options_page: {
      Title: 'Option réversible',
      Input: {
        Placeholder: 'ID_IHM',
        Messages: {
          Min: '1 caractères minimum',
        },
      },
      Table: {
        Empty_text: 'Aucun enregistrement trouvé',
        Column_titles: {
          Date: 'Date / heure',
          User_account: "Compte d'utilisateur",
          T_one_family: 'T.One famille',
          Id_ihm: 'ID_IHM',
          Customer_name: 'Nom du client',
          After_sale_request: 'SAV',
        },
      },
      Customer_name: 'Nom du client',
      After_sale: 'SAV',
      Download_t_one_button: 'Télécharger le fichier .bin T.One V/H',
      Download_aqua_air_button: 'Télécharger le fichier .bin T.One AquaAIR/AIR',
    },
    Not_found_page: {
      Message: 'PAGE NON TROUVÉE',
      Go_back_button: 'Retour',
    },
  },
};

export default fr;
