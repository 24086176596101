import React from 'react';
import clsx from 'clsx';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';

const CheckboxMain = React.forwardRef(
  ({ className, value, children, ...passedProps }, ref) => (
    <Checkbox
      ref={ref}
      checked={value}
      className={clsx(className)}
      {...passedProps}
    >
      {children}
    </Checkbox>
  )
);

CheckboxMain.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
};

export { CheckboxMain as Checkbox };
