import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';

import { loginRequest, passwordRecoverRequest } from 'ducks/auth/actions';

import { isLoginFailedSelector } from 'ducks/auth/selectors';

import { Title } from 'components/Title';
import { WrappedRecoveryForm } from 'components/RecoveryForm';
import { WrappedLoginForm } from 'components/LoginForm';

import styles from './LoginPage.module.css';

export function LoginPage() {
  /* Hooks */
  const dispatch = useDispatch();

  const [isRecoveryFormActive, setIsRecoveryFormActive] = useState(false);
  const isLoginFailed = useSelector(isLoginFailedSelector);

  /* States */
  const initialState = {
    username: { value: '' },
    password: { value: '' },
    recaptcha: { value: '' },
    isRemember: { value: false },
  };

  const [form, setForm] = useState({
    fields: initialState,
  });

  /* Event handlers */
  const toggleRecoveryForm = () => {
    setIsRecoveryFormActive((prevState) => !prevState);
  };

  const handleFormChange = (changedFields) => {
    setForm(({ fields }) => ({
      fields: {
        ...fields,
        ...changedFields,
      },
    }));
  };

  const handleLogin = useCallback(
    (formData) => {
      dispatch(loginRequest(formData));
    },
    [dispatch]
  );

  const handleRecover = useCallback(
    (recoverFormData) => {
      dispatch(passwordRecoverRequest(recoverFormData));
    },
    [dispatch]
  );

  const { fields } = form;

  return (
    <div className={styles.LoginPage}>
      <Row
        type="flex"
        justify="center"
        align="middle"
        className={styles.pageRow}
      >
        <Col>
          <Title />
          {isRecoveryFormActive ? (
            <WrappedRecoveryForm
              fields={fields}
              isLoginFailed={isLoginFailed}
              onChange={handleFormChange}
              handleRecover={handleRecover}
              handleCancel={toggleRecoveryForm}
            />
          ) : (
            <WrappedLoginForm
              fields={fields}
              isLoginFailed={isLoginFailed}
              onChange={handleFormChange}
              handleLogin={handleLogin}
              toggleRecoveryForm={toggleRecoveryForm}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}
