import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table, Checkbox, Button, Icon, Row, Col, Modal } from 'antd';
import PropTypes from 'prop-types';

import { ROUTES, machineLabelPattern } from 'constants/index';

import { langSelector } from 'ducks/config/selectors';

import { FormInput } from 'components/ui/FormInput';

import { makeIconLink } from 'components/ProjectsTabPane/ProjectsTable';

import {
  makeCellTextDateFormatter,
  makeCellTextLinkinizer,
  makeCellTextTooltipizer,
  makeCellTextHighlighter,
} from '../../../utils/tableUtils';

import {
  getEditableSpan,
  getTooltip,
} from '../../ProjectsTabPane/ProjectsTable';

import styles from '../ProjectDetails.module.css';

export function PDMachinesTable({
  machines,
  addMachine,
  cloneMachine,
  deleteMachines,
  isRequestingProjects,
  isAdmin,
}) {
  const { t } = useTranslation();

  const lang = useSelector(langSelector);

  const initialSelectedRecords = {
    ids: [],
    records: [],
  };

  /* States */
  const [showDeleted, setShowDeleted] = useState(false);
  const [isDelModalVisible, setIsDelModalVisible] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [newMachineName, setNewMachineName] = useState('');
  const [newMachineError, setNewMachineError] = useState('');
  const [selectedRecords, setSelectedRecords] = useState(
    initialSelectedRecords
  );

  const tooltipizeCellText = makeCellTextTooltipizer(getTooltip);
  const formatCellTextDate = makeCellTextDateFormatter(lang);
  const getIconLink = makeIconLink('tool');
  const linkinizeCellIcon = makeCellTextLinkinizer(
    getIconLink,
    ROUTES.PROJECT_MACHINE_SETTINGS
  );

  const preProcessCellText = makeCellTextHighlighter(
    getEditableSpan,
    '',
    tooltipizeCellText
  );

  /* Table config */
  const columns = [
    {
      title: t('Machines_table.Column_titles.Duplicate'),
      align: 'center',
      render: (_text, record) => (
        <Icon
          type="copy"
          style={{ fontSize: '20px', color: '#000' }}
          onClick={() => handleCopyInstallation(record)}
        />
      ),
      width: 120,
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Config'),
      align: 'center',
      render: linkinizeCellIcon,
      width: 120,
      ellipsis: true,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 90,
      ellipsis: true,
    },
    {
      title: (
        <span>
          {t('Machines_table.Column_titles.Label')}{' '}
          <sup style={{ color: '#1890ff' }}>
            {t('Common_terms.Hints.Editable')}
          </sup>
        </span>
      ),
      dataIndex: 'label',
      key: 'label',
      sorter: (a, b) => a.label.localeCompare(b.label),
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: preProcessCellText,
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Saved_at'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text) => (text ? formatCellTextDate(text) : 'N/A'),
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Downloaded_at'),
      dataIndex: 'downloadedAt',
      key: 'downloadedAt',
      render: (text) => (text ? formatCellTextDate(text) : 'N/A'),
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Config_file'),
      dataIndex: 'filename',
      render: (text) => text || 'N/A',
      key: 'filename',
    },
    {
      title: t('Machines_table.Column_titles.Downloads'),
      dataIndex: 'downloadsCount',
      key: 'downloadsCount',
    },
  ];

  if (isAdmin) {
    columns.push({
      title: t('Machines_table.Column_titles.Installer'),
      dataIndex: 'installerName',
      key: 'installerName',
      ellipsis: true,
    });
  }

  const rowSelection = {
    selectedRowKeys: selectedRecords.ids,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRecords({
        ids: selectedRowKeys,
        records: selectedRows,
      });
    },
  };

  /* Event handlers */
  const handleCopyInstallation = useCallback(
    (record) => {
      cloneMachine(record);
    },
    [cloneMachine]
  );

  const handleInputChange = useCallback(
    ({ target: { value } }) => {
      setNewMachineError('');
      setNewMachineName(value);
    },
    [machineLabelPattern]
  );

  const handleOpenCreateModal = useCallback(() => {
    setIsCreateModalVisible(true);
  }, []);

  const handleAddOk = useCallback(() => {
    if (!newMachineName) {
      setNewMachineError(
        t('Project_details.Modals.Add.Input.Required_message')
      );
      return;
    }

    addMachine({ label: newMachineName });
    setIsCreateModalVisible(false);
    setNewMachineError('');
  }, [t, addMachine, newMachineName]);

  const handleDeleteOk = useCallback(() => {
    deleteMachines(selectedRecords.ids);
    setIsDelModalVisible(false);
    setSelectedRecords(initialSelectedRecords);
  }, [deleteMachines, selectedRecords, initialSelectedRecords]);

  const handleCancel = useCallback(() => {
    setIsCreateModalVisible(false);
    setIsDelModalVisible(false);
    setNewMachineName('');
    setNewMachineError('');
  }, []);

  const delSelected = useCallback(() => {
    setIsDelModalVisible(true);
  }, []);

  const handleShowDeleted = useCallback(({ target: { checked } }) => {
    setShowDeleted(checked);
  }, []);

  const isRecordsSelected = !!selectedRecords.ids.length;

  return (
    <>
      <Row
        type="flex"
        align="middle"
        justify="space-between"
        className={styles.lastRow}
      >
        <Col span={8}>
          <Checkbox
            disabled={!isAdmin}
            className={styles.showDeleted}
            onChange={handleShowDeleted}
          >
            {t('Project_details.General_info.Inputs.Show_deleted.Label')}
          </Checkbox>
        </Col>
        <Col>
          {isRecordsSelected && (
            <span>
              {t('Project_details.Row_selection_hint', {
                count: selectedRecords.ids.length,
              })}
            </span>
          )}
          <Button
            icon="delete"
            disabled={!isRecordsSelected}
            className={styles.deleteButton}
            onClick={delSelected}
          >
            {t('Project_details.Buttons.Delete')}
          </Button>
          <Button className={styles.addButton} onClick={handleOpenCreateModal}>
            {t('Project_details.Buttons.Add')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={
            showDeleted
              ? machines.filter((machine) => machine.deletedAt)
              : machines.filter((machine) => !machine.deletedAt)
          }
          rowSelection={rowSelection}
          loading={isRequestingProjects}
          pagination={false}
          locale={{ emptyText: t('Machines_table.Empty_text') }}
        />
      </Row>
      <Modal
        visible={isCreateModalVisible}
        closable={false}
        title={t('Project_details.Modals.Add.Title')}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            {t('Project_details.Modals.Add.Cancel_button')}
          </Button>,
          <Button key="ok" type="primary" onClick={handleAddOk}>
            {t('Project_details.Modals.Add.OK_button')}
          </Button>,
        ]}
      >
        <FormInput
          name="newMachineName"
          value={newMachineName}
          error={newMachineError}
          maxLength={30}
          placeholder={t('Project_details.Modals.Add.Input.Placeholder')}
          handleChange={handleInputChange}
        />
      </Modal>
      <Modal
        visible={isDelModalVisible}
        closable={false}
        title={t('Project_details.Modals.Delete.Title')}
        onCancel={handleCancel}
        onOk={handleDeleteOk}
      >
        <p>
          {t('Project_details.Modals.Delete.Content')}
          {selectedRecords.records
            .map(
              (record) =>
                record.label ||
                t('Machines_filters.Delete_modal.Unlabeled_stub', {
                  id: record.id,
                })
            )
            .join(', ')}
          ?
        </p>
      </Modal>
    </>
  );
}

PDMachinesTable.propTypes = {
  machines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      createdAt: PropTypes.string,
      downloadedAt: PropTypes.string,
      configFile: PropTypes.string,
      downloads: PropTypes.number,
      installer: PropTypes.string,
    })
  ),
  addMachine: PropTypes.func.isRequired,
  cloneMachine: PropTypes.func.isRequired,
  deleteMachines: PropTypes.func.isRequired,
  isRequestingProjects: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};
