import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button } from 'antd';
import PropTypes from 'prop-types';

import { loginErrorResetRequest } from 'ducks/auth/actions';

import { Checkbox } from 'components/ui/Checkbox';
import { LoginField } from 'components/ui/LoginField';
import { PasswordField } from 'components/ui/PasswordField';

import styles from './LoginForm.module.css';

const propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFields: PropTypes.func,
  }).isRequired,
  fields: PropTypes.shape({
    username: PropTypes.object,
    password: PropTypes.object,
    isRemember: PropTypes.object,
  }).isRequired,
  isLoginFailed: PropTypes.bool.isRequired,
  handleLogin: PropTypes.func.isRequired,
  toggleRecoveryForm: PropTypes.func.isRequired,
};

function LoginForm({
  form: { getFieldDecorator, validateFields, setFields },
  fields,
  isLoginFailed,
  toggleRecoveryForm,
  handleLogin,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loginErrorResetRequest());

    setFields({
      username: {
        value: '',
        error: null,
      },
      password: {
        value: '',
        error: null,
      },
    });
  }, [dispatch, setFields]);

  const handleSubmit = (event) => {
    event.preventDefault();

    validateFields()
      .then((values) => {
        handleLogin({
          username: values.username,
          password: values.password,
          // isRemember: values.isRemember,
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('submitError', err);
      });
  };

  const { username } = fields;

  return (
    <div className={styles.LoginForm}>
      <Form className={styles.formCard}>
        <LoginField
          username={username.value}
          isError={isLoginFailed}
          getFieldDecorator={getFieldDecorator}
          inputClassName={styles.formInput}
        />
        <PasswordField
          placeholder={t('Login_form.Inputs.Password.Placeholder')}
          isError={isLoginFailed}
          help={isLoginFailed ? t('Login_form.Error_message') : undefined}
          getFieldDecorator={getFieldDecorator}
          inputClassName={styles.formInput}
        />
        <Form.Item className={styles.formField}>
          {getFieldDecorator('isRemember')(
            <Checkbox name="isRemember">
              {t('Login_form.Inputs.Remember.Label')}
            </Checkbox>
          )}
        </Form.Item>
        <Row type="flex" justify="center">
          <Col>
            <Button htmlType="submit" type="primary" onClick={handleSubmit}>
              {t('Login_form.Login_button')}
            </Button>
          </Col>
        </Row>
      </Form>
      <Row type="flex" justify="center">
        <Col>
          <Button type="link" onClick={toggleRecoveryForm}>
            <span className={styles.forgotLink}>
              {t('Login_form.Forgot_link')}
            </span>
          </Button>
        </Col>
      </Row>
    </div>
  );
}

LoginForm.propTypes = propTypes;

export const WrappedLoginForm = Form.create({
  name: 'login_form',
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
  mapPropsToFields(props) {
    const { fields } = props;

    return {
      username: Form.createFormField({
        ...fields.username,
        value: fields.username.value,
      }),
      password: Form.createFormField({
        ...fields.password,
        value: fields.password.value,
      }),
      isRemember: Form.createFormField({
        ...fields.isRemember,
        value: fields.isRemember.value,
      }),
    };
  },
})(LoginForm);
