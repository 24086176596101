const es = {
  translations: {
    Common_terms: {
      Measurements: {
        Airflow: 'm³/h',
        Airspeed: 'm/s',
        Pressure: 'Pa',
        Temperature: '°C',
        Hints: {
          Editable: '*modificable',
        },
        Type_labels: {
          House_or_flat: 'Chalé o piso',
          Subdivision_or_building: 'Complejo residencial o edificio',
        },
      },
    },
    Header: {
      Breadcrumbs: {
        Root: 'Inicio',
        Projects: 'Proyectos',
        Project_details: 'Detalles del proyecto',
        Project_machine_settings: 'Parámetros del equipo',
        Machine_settings: 'Parámetros del equipo',
        Project_new: 'Nuevo proyecto',
        Options: 'Las opciones',
      },
    },
    Footer: {
      Links: {
        TOne_file: 'Archivo SOFT T.One V/H V08',
        TOne_manual: 'Guía usuario T.One V/H',
        Aqua_air_file: 'Archivo SOFT T.One AquaAIR/AIR V15',
        Aqua_air_manual: 'Guía usuario T.One AquaAIR/AIR',
      },
    },
    Login_form: {
      Inputs: {
        Login: {
          Placeholder: 'Usuario',
          Messages: {
            Required: 'Introduzca su usuario',
            Min: '4 caracteres mínimo',
          },
        },
        Password: {
          Placeholder: 'Contraseña',
          Messages: {
            Required: 'Introduzca su contraseña',
            Min: '4 caracteres mínimo',
          },
        },
        Remember: {
          Label: 'Recordarme',
        },
      },
      Login_button: 'Conectarse',
      Forgot_link: '¿Contraseña olvidada?',
      Error_message: 'Usuario o contraseña incorrecto',
    },
    Recovery_form: {
      Intro: {
        Title: 'Recuperación de la contraseña',
        Content:
          'Introduzca su usuario para recibir las instrucciones por e-mail',
      },
      Submit_button: 'Enviar',
      Cancel_button: 'Cancelar',
      Recaptcha: {
        Messages: {
          Required: 'No soy un robot',
        },
      },
      Forgot_link: '¿Usuario olvidado?',
      Back_link: 'Volver',
      Contact_message:
        'Es imposible recuperar su contraseña automáticamente. Contacte con su interlocutor del Grupo Aldes para volver a acceder a su cuenta.',
      Success_message:
        'Si tiene una dirección e-mail válida asociada a su cuenta, se le acaba de enviar una contraseña temporal y otras instrucciones a esta dirección.',
    },
    Reset_password_form: {
      Intro: {
        Title: 'Cambiar su contraseña',
        Content:
          'Introduzca su usuario para recibir las instrucciones por e-mail',
      },
      Inputs: {
        Password_1: {
          Placeholder: 'Nueva contraseña',
        },
        Password_2: {
          Placeholder: 'Nueva contraseña',
        },
        Match_error: 'Contraseñas diferentes',
      },
      Submit_button: 'Enviar',
      Error_message:
        'Se ha producido un error. Por favor, vuelva a cargar la página.',
      Success_message: '¡Se ha cambiado su contraseña!',
      Login_link: 'Conéctese aquí',
    },
    Home_page: {
      Main_menu: {
        Projects: 'Mis proyectos',
        New_project: 'Nuevo proyecto',
        Options: 'Opciones',
      },
    },
    Projects_page: {
      Tab_titles: {
        Projects: 'Proyectos',
        Machines: 'Equipos',
      },
    },
    Projects_filters: {
      Inputs: {
        Search_term: {
          Placeholder: 'Búsqueda por palabra clave',
        },
        Installer: {
          Placeholder: 'Instalador',
        },
        Show_deleted: {
          Label: 'Visualizar los proyectos eliminados',
        },
        Per_page: 'Búsquedas por página',
      },
      Delete_button: 'Borrar',
      Download_button: 'Extracción en formato Excel',
      Config_button: 'ID Config',
      Modals: {
        Delete: {
          Title: 'Borrar los proyectos',
          OK_button: 'Confirmar',
          Cancel_button: 'Cancelar',
          Content: '¿Está seguro de querer eliminar?',
        },
        Config: {
          Title: 'ID Config',
          OK_button: 'OK',
          Content:
            'El nombre del fichero de configuración de una instalación T.One® AquaAIR/AIR generado por esta aplicación se desglosa como en el ejemplo a continuación:\n Ejemplo de nombre de archivo: V01_321_GARCIA_1A_VALENCIA.BIN\n - VERSIÓN del soft de ajuste T.One® AquaAIR / AIR= V01\n - ID_INSTALLATION (CLEREGISTRE) = 321\n - En individual, NOMBRE DEL CLIENTE / en colectivo, NOMBRE PROYECTO = GARCIA\n - REFERENCIA (si varias instalaciones) = 1A\n CIUDAD = VALENCIA\n ¡NO CAMBIAR EL NOMBRE DE ESTE FICHERO!',
        },
      },
      Row_selection_hint: 'Ha seleccionado {{count}} proyecto',
      Row_selection_hint_plural: 'Ha seleccionado {{count}} proyectos',
    },
    Projects_table: {
      Column_titles: {
        Type: 'Tipo',
        Reference: 'Referencia del proyecto',
        Machine_reference: 'Machine reference',
        Created_at: 'Fecha de creación',
        Installer: 'Instalador',
        Id_installer: 'ID Instalador',
        Address: 'Dirección',
        Postcode: 'Código postal',
        City_name: 'Ciudad',
      },
      Empty_text: 'Proyecto no encontrado',
    },
    Project_details: {
      General_info: {
        Title: 'Información general',
        Inputs: {
          Installer: {
            Placeholder: 'Elegir el instalador',
          },
          Address: {
            Placeholder: 'Dirección',
          },
          House_number: {
            Placeholder: 'N°',
          },
          Project_ref: {
            Placeholder: 'Referencia proyecto',
            Messages: {
              Required: 'Campo requerido',
            },
          },
          Postcode: {
            Placeholder: 'Código postal',
            Messages: {
              Required: 'Campo requerido',
              Min: '5 caracteres mínimo',
            },
          },
          City: {
            Placeholder: 'Ciudad',
            Messages: {
              Required: 'Campo requerido',
            },
          },
          Country: {
            Placeholder: 'País',
          },
          Show_deleted: {
            Label: 'Visualizar los equipos eliminados',
          },
        },
        GDPR: 'No introducir información personal de clientes (RGPD)',
      },
      Buttons: {
        Save: 'Guardar los cambios',
        Add: 'Añadir un equipo',
        Delete: 'Eliminar los equipos',
      },
      Modals: {
        Add: {
          Title: 'Nueva instalación',
          Input: {
            Placeholder: 'Introducir Nombre',
            Required_message: 'Campo requerido',
          },
          OK_button: 'Confirmar',
          Cancel_button: 'Cancelar',
        },
        Delete: {
          Title: 'Equipos eliminados',
          Content: '¿Está seguro de querer eliminarlos?',
        },
      },
      Row_selection_hint: 'Ha seleccionado 1 equipo',
      Row_selection_hint_plural: 'Ha seleccionado {{nombre}} equipos',
    },
    Machines_filters: {
      Inputs: {
        Search_term: {
          Placeholder: 'Búsqueda por texto',
        },
        Installer: {
          Placeholder: 'Instalador',
        },
        Units_ref: {
          Placeholder: 'Referencia BDC AIRE/AIRE',
        },
        Show_deleted: {
          Label: 'Visualizar los equipos eliminados',
        },
        Per_page: 'Búsquedas por página',
      },
      Delete_button: 'Borrar',
      Download_button: 'Extracción en formato Excel',
      Delete_modal: {
        Title: 'Borrar equipos',
        OK_button: 'Confirmar',
        Cancel_button: 'Cancelar',
        Content: '¿Está seguro de querer eliminar?',
        Unlabeled_stub: 'UNLABELED [id:{{id}}]',
      },
      Row_selection_hint: 'Ha seleccionado 1 equipo',
      Row_selection_hint_plural: 'Ha seleccionado {{nombre}} equipos',
    },
    Machines_table: {
      Column_titles: {
        Duplicate: 'Duplicar',
        Config: 'Parametrizar',
        Type: 'Tipo',
        Client: 'Referencia obra',
        Id: 'ID',
        Label: 'Denominación',
        Saved_at: 'Fecha de copia de seguridad',
        Downloaded_at: 'Fecha de descarga archivo',
        Installer: 'Instalador',
        Regulation_type: 'Tipo de regulación',
        Mode_available: 'Reversibilidad',
        Units_ref: 'Referencia BDC AIRE/AIRE',
        Config_file: 'Archivo de parametrización',
        Downloads: 'Descargas',
      },
      Empty_text: 'Proyecto no encontrado',
    },
    Machine_settings: {
      Go_to_project_button: 'Volver al proyecto',
      Print_button: {
        Label: 'Imprimir',
        Tooltip:
          '¡Active los Gráficos de fondo y, si es necesario, seleccione la orientación de página vertical en la pantalla de parámetros de impresión!',
      },
      Save_button: 'Guardar los cambios',
      Save_button_popup: '',
      Download_button: 'Descargar .bin',
      Leave_modal: {
        Title: '¿Está seguro de querer salir de la página?',
        Content:
          'Se perderán todos los cambios no guardados. ¿Está seguro de querer continuar?',
      },
      Air_heat_pump_section: {
        Title: 'Bomba de calor aire/aire',
        Inputs: {
          Installation_type: {
            Label: 'Tipo de instalación',
          },
          Units_reference: {
            Label: 'Denominación bomba de calor aire/aire',
          },
          Airflow_low: {
            Label: 'Caudal de aire UI modo Confort (silencio)',
          },
          Airflow_boost: {
            Label: 'Caudal de aire UI modo Boost (máx.)',
          },
        },
      },
      Air_distribution_section: {
        Title: 'Distribución aeráulica',
        Inputs: {
          Type: {
            Label: 'Tipo',
          },
          Nominal_airflow: {
            Label: 'Al caudal mínimo',
          },
          Minimal_airflow: {
            Label: 'A velocidad mín. (1 boca abierta)',
          },
          External_pressure: {
            Label: 'Presión estática externa',
          },
          Airflow_therm_off: {
            Label: 'Caudal de aire termostato OFF',
          },
          Airflow_defrost_cycle: {
            Label: 'Caudal de aire durante el desescarche',
          },
          Airduct_leakage: {
            Label: 'Caudal de fuga red aeráulica',
          },
          External_pressure_avaliable: {
            Label: 'Presión estática disponible termostato OFF',
          },
        },
      },
      Settings_modes_section: {
        Advanced_input: 'Parametrización completa',
        Automatic_button: 'Selección automática de las bocas',
      },
      Rooms_config_section: {
        Inputs: {
          Number_of_channels: {
            Label: 'Número de canales',
          },
          Room_name: {
            Label: 'Nombre de los cuartos',
          },
          Treated_surface: {
            Label: 'Superficie tratada (m²)',
          },
          Ceiling_height: {
            Label: 'Altura bajo techo (m)',
          },
          Airgrill: {
            Label: 'Dimensión rejilla elegida',
          },
          Airleak: {
            Label: 'Presencia caudal de fuga (ranura)',
          },
          Minimal_indoor_airflow: {
            Label: 'Caudal de aire mín. impulsado por UI con compresor ON',
          },
        },
        Text: {
          Airflow_low_noise: {
            Title: 'Caudal de aire UI modo Confort (silencio)',
            Desc1: 'Caudal impulsado total:',
            Desc2: 'Velocidad de impulsión (todas las bocas abiertas):',
          },
          Airflow_boost_mode: {
            Title: 'Caudal de aire UI modo Boost (máx.)',
            Desc1: 'Caudal impulsado total:',
            Desc2: 'Velocidad de impulsión (todas las bocas abiertas):',
          },
        },
      },
      Rooms_config: {
        Advanced_mode: {
          Inputs: {
            Treated_volume: {
              Label: 'Volumen tratado (m³)',
            },
            Airflow_with_4: {
              Label: 'Caudal a 4 Vol/h (m³/h)',
            },
            Effective_opened_airgrill: {
              Label: 'Superficie efectiva boca abierta (cm²)',
            },
            Effective_closed_airgrill: {
              Label: 'Superficie efectiva boca cerrada (cm²)',
            },
            Grills_airflow: {
              Label: 'Caudal rejilla (m³/ h)',
            },
            Rate_of_volume: {
              Label: 'Coeficiente de mezcla (vol/h)',
            },
            Air_jet_heating: {
              Label: 'Alcance chorro caliente / deflexión 20° (m)',
            },
            Air_jet_cooling: {
              Label: 'Alcance chorro frío / deflexión 20° (m)',
            },
          },
        },
      },
      Specific_options_section: {
        Title: 'Opciones de parametrización',
        Inputs: {
          History_step: {
            Label: 'Paso histórico (minutos)',
          },
          Standard_airspeed: {
            Label:
              'Consigna máx crucero de velocidad de aire en las bocas (m/s)',
          },
          Maximal_airspeed: {
            Label: 'Consigna máx punta de velocidad de aire en las bocas (m/s)',
          },
          Maximal_set_heating: {
            Label: 'Temperatura límite alto en calefacción (rango 16-31 °C)',
          },
          Minimal_set_heating: {
            Label: 'Temperatura límite bajo en calefacción (rango 16-31 °C)',
          },
          Maximal_set_cooling: {
            Label: 'Temperatura límite alto en refrescamiento (rango 16-31 °C)',
          },
          Minimal_set_cooling: {
            Label: 'Temperatura límite bajo en refrescamiento (rango 16-31 °C)',
          },
        },
      },
      Reserved_options_section: {
        Title: 'Opciones reservadas Aldes',
        Inputs: {
          Minimal_airflow: {
            Label: 'Caudal de aire UI Mín. si COMP ON (m³/ h)',
          },
          Low_airflow: {
            Label: 'Caudal de aire UI Confort si COMP ON (m³/ h)',
          },
          Boost_airflow: {
            Label: 'Caudal de aire UI Boost si COMP ON (m³/ h)',
          },
          Diff_st_high: {
            Label: 'Dif. ST Alta (°C)',
          },
          Diff_st_low: {
            Label: 'Dif. ST Baja(°C)',
          },
          Opening_outlet_time: {
            Label: 'Plazo apertura boca (s)',
          },
          Closing_outlet_time: {
            Label: 'Plazo cierre boca (s)',
          },
        },
      },
      Error_message: 'Campo obligatorio',
      Minimal_Airleak_Error: 'Caudal de fuga de aire insuficiente',
      Field_error: 'Valores incorrectos',
      Room_names: {
        Living: 'Viviente',
        Office: 'Oficina',
        Lounge: 'Salón',
        Mezzanine: 'Entresuelo',
      },
      Popover: {
        text: 'Para descargar el archivo, primero complete el formulario',
      },
      Folder_reference: 'referencia carpeta',
    },
    New_project_form: {
      Section_titles: {
        General: 'Información general',
        Client: 'Información Cliente',
      },
      Inputs: {
        Type: {
          Label: 'Tipo',
        },
        Reference: {
          Label: 'Referencia del proyecto "interno instalador"',
          Extra: 'No introducir información personal de clientes (RGPD)',
          Placeholder: 'Introducir la referencia proyecto',
          Messages: {
            Required: 'Campo obligatorio',
            Min: '2 caracteres mínimo',
          },
        },
        Installer: {
          Label: 'Instalador',
        },
        Installation_type: {
          Label: 'Tipo de instalaciones',
        },
        Address: {
          Label: 'Dirección',
          Placeholder: 'Nombre de la calle',
          Messages: {
            Min: '2 caracteres mínimo',
          },
        },
        House_number: {
          Label: 'N°',
          Placeholder: 'N°',
        },
        Postcode: {
          Label: 'Código postal',
          Placeholder: 'Introducir el código postal',
          Messages: {
            Required: 'Campo obligatorio',
            Min: '5 caracteres mínimo',
          },
        },
        City: {
          Label: 'Ciudad',
          Placeholder: 'Introducir la ciudad',
          Messages: {
            Required: 'Campo obligatorio',
            Min: '2 caracteres mínimo',
          },
        },
        Country: {
          Label: 'País',
          Placeholder: 'Introducir el país',
          Messages: {
            Required: 'Campo obligatorio',
          },
        },
      },
      Create_button: 'Crear proyecto',
      Blank_machine_label: 'Referencia1',
    },
    Options_page: {
      Title: 'Opción reversible',
      Input: {
        Placeholder: 'ID_IHM',
        Messages: {
          Min: '1 carácter mínimo',
        },
      },
      Table: {
        Empty_text: 'No se encontraron registros',
        Column_titles: {
          Date: 'Fecha y hora',
          User_account: 'Cuenta de usuario',
          T_one_family: 'T.One familia',
          Id_ihm: 'ID_IHM',
          Customer_name: 'Nombre del cliente',
          After_sale_request: 'SAV',
        },
      },
      Customer_name: 'Nombre del cliente',
      After_sale: 'SAV',
      Download_t_one_button: 'Descargar el archivo .bin de T.One V/H',
      Download_aqua_air_button:
        'Descargar el archivo .bin de T.One AquaAIR/AIR',
    },
    Not_found_page: {
      Message: 'Página no encontrada',
      Go_back_button: 'Volver',
    },
  },
};

export default es;
