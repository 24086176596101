import React, { useCallback } from 'react';
import { Form, Row, Col, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { mapListToObject } from 'helpers/common';

import { FormInput } from 'components/ui/FormInput';

import styles from './MachineSettings.module.css';

const { Option } = Select;

const getRelevantReferenceUnitsAndCo = (
  installationTypes,
  currentInstallationTypeId,
  referenceUnits,
  currentReferenceUnitId
) => {
  const INSTALLATION_TYPES = mapListToObject(installationTypes);

  const relevantReferenceUnits = referenceUnits?.filter(
    (unit) =>
      unit.projectType === INSTALLATION_TYPES[currentInstallationTypeId]?.code
  );
  const currentReferenceUnit = referenceUnits?.find(
    (unit) => unit.id === currentReferenceUnitId
  );
  const displayedReferenceUnit = relevantReferenceUnits?.find(
    (unit) => unit.model === currentReferenceUnit?.model
  )
    ? currentReferenceUnit
    : null;

  return {
    relevantReferenceUnits,
    currentReferenceUnit,
    displayedReferenceUnit,
  };
};

const propTypes = {
  values: PropTypes.shape({
    airToAirUnitsReference: PropTypes.number,
    installationType: PropTypes.number,
  }).isRequired,
  errors: PropTypes.shape({
    airToAirUnitsReference: PropTypes.string,
  }).isRequired,
  installationTypes: PropTypes.array.isRequired,
  referenceUnits: PropTypes.array.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setMainUnit: PropTypes.func.isRequired,
};

export function AirHeatPumpSettings({
  values: { airToAirUnitsReference, installationType },
  errors,
  setFieldValue,
  setFieldTouched,
  installationTypes,
  referenceUnits,
  setMainUnit,
}) {
  const { t } = useTranslation();

  const { relevantReferenceUnits, displayedReferenceUnit } =
    getRelevantReferenceUnitsAndCo(
      installationTypes,
      installationType,
      referenceUnits,
      airToAirUnitsReference
    );

  const handleReferenceUnits = useCallback(
    (selected) => {
      setMainUnit(+selected);
    },
    [setMainUnit]
  );

  return (
    <Col
      span={7}
      className={styles.MachineSettings}
      style={{ marginRight: '10px' }}
    >
      <div className={styles.columnLabel}>
        {t('Machine_settings.Air_heat_pump_section.Title')}
      </div>
      <div className={styles.field}>
        <div className={styles.formLabel}>
          {t(
            'Machine_settings.Air_heat_pump_section.Inputs.Installation_type.Label'
          )}
        </div>
        <Form.Item className={styles.formItem}>
          <Select
            name="installationType"
            value={installationType || null}
            onBlur={() => setFieldTouched('installationType')}
            onChange={(newValue) => {
              setFieldValue('installationType', newValue);
              setFieldValue('airToAirUnitsReference', null);
            }}
          >
            {installationTypes.map(({ id, label }) => (
              <Option key={id} value={id}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className={styles.field}>
        <div className={styles.formLabel}>
          {t(
            'Machine_settings.Air_heat_pump_section.Inputs.Units_reference.Label'
          )}
        </div>
        <Form.Item
          className={styles.formItem}
          validateStatus={errors.airToAirUnitsReference ? 'error' : undefined}
        >
          <Select
            name="airToAirUnitsReference"
            value={displayedReferenceUnit?.id || null}
            onBlur={() => setFieldTouched('airToAirUnitsReference')}
            onChange={handleReferenceUnits}
          >
            {relevantReferenceUnits.map((unit) => (
              <Option key={unit.id} value={unit.id}>
                {unit.model}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <div className={styles.field}>
              <div className={styles.formLabel}>
                {t(
                  'Machine_settings.Air_heat_pump_section.Inputs.Airflow_low.Label'
                )}
              </div>
              <FormInput
                styles={{
                  itemClassName: styles.formItem,
                }}
                name="param1"
                value={`${displayedReferenceUnit?.airflowLowNoise || 0} ${t(
                  'Common_terms.Measurements.Airflow'
                )}`}
                disabled
              />
            </div>
          </Col>
          <Col span={12} className={styles.col}>
            <div className={styles.field}>
              <div className={styles.formLabel}>
                {t(
                  'Machine_settings.Air_heat_pump_section.Inputs.Airflow_boost.Label'
                )}
              </div>
              <FormInput
                styles={{
                  itemClassName: styles.formItem,
                }}
                name="param2"
                value={`${displayedReferenceUnit?.airflowBoostMode || 0} ${t(
                  'Common_terms.Measurements.Airflow'
                )}`}
                disabled
              />
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
}

AirHeatPumpSettings.propTypes = propTypes;
