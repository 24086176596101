import React from 'react';

import styles from './Title.module.css';

export function Title() {
  return (
    <div className={styles.Title}>
      <div className={styles.firstText}>T.One</div>
    </div>
  );
}
