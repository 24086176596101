import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

import { SET_LANGUAGE_ACTION } from './actions';

const initialState = fromJS({
  lang: 'fr',
});

export default handleActions(
  {
    [SET_LANGUAGE_ACTION]: (state, action) =>
      state.set('lang', action.payload.lang),
  },
  initialState
);
