import React from 'react';
import clsx from 'clsx';
import { generatePath, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Icon } from 'antd';
import PropTypes from 'prop-types';

import { ROUTES } from '../../constants';

import styles from './MachineSettings.module.css';

export function TopButtonsPanel({ parentProject }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Row
      type="flex"
      justify="end"
      className={clsx(styles.buttonRow, 'no-print')}
    >
      <Col>
        <Button
          type="primary"
          onClick={() =>
            navigate(
              generatePath(ROUTES.PROJECT_DETAILS, { id: parentProject.id })
            )
          }
        >
          {t('Machine_settings.Go_to_project_button')}
          <Icon type="right" />
        </Button>
      </Col>
    </Row>
  );
}

TopButtonsPanel.propTypes = { parentProject: PropTypes.object.isRequired };
