import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

import { ROUTES } from 'constants/index';

import db from 'assets/image/database.png';
import add from 'assets/image/add.png';
import customerSupport from 'assets/image/customer-support.png';

import styles from './HomePage.module.css';

export function HomePage({ isAdmin }) {
  const { t } = useTranslation();

  return (
    <div className={styles.HomePage}>
      <Row
        type="flex"
        justify="center"
        align="middle"
        className={styles.pageRow}
      >
        <Col>
          <div className={styles.homepageMenu}>
            <Link to={ROUTES.PROJECTS}>
              <div className={styles.menuItem}>
                <img
                  className={styles.itemImage}
                  src={db}
                  alt={t('Home_page.Main_menu.Projects')}
                />
                <span className={styles.itemText}>
                  {t('Home_page.Main_menu.Projects')}
                </span>
              </div>
            </Link>
            <Link className={styles.menuItem} to={ROUTES.PROJECT_NEW}>
              <img
                className={styles.itemImage}
                src={add}
                alt={t('Home_page.Main_menu.New_project')}
              />
              <span className={styles.itemText}>
                {t('Home_page.Main_menu.New_project')}
              </span>
            </Link>
            {isAdmin && (
              <Link className={styles.menuItem} to={ROUTES.OPTIONS}>
                <img
                  className={styles.itemImage}
                  src={customerSupport}
                  alt={t('Home_page.Main_menu.Options')}
                />
                <span className={styles.itemText}>
                  {t('Home_page.Main_menu.Options')}
                </span>
              </Link>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

HomePage.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};
