import { generatePath } from 'react-router';

import { request } from 'utils/request';
import { ENDPOINTS } from '../../constants';

/**
 * Get PROJECTS resource
 * @param {number} page
 */
const getProjects = ({
  page = 1,
  perPage = 30,
  searchTerm,
  installerId,
  isDeleted = false,
  sortByType,
  sortByReference,
  sortByCreation,
  sortByCity,
}) =>
  request.get(ENDPOINTS.PROJECTS, {
    params: {
      page,
      items_per_page: perPage,
      search: searchTerm,
      'installer.id': installerId,
      'exists[deletedAt]': isDeleted,
      'order[type]': sortByType,
      'order[reference]': sortByReference,
      'order[createdAt]': sortByCreation,
      'order[cityName]': sortByCity,
    },
    headers: { accept: 'application/ld+json' },
  });

const getProjectsFile = (page = 1, itemsPerPage = 30) =>
  request.get(ENDPOINTS.PROJECTS, {
    params: { page, items_per_page: itemsPerPage },
    headers: { accept: 'text/csv' },
    responseType: 'blob',
  });

/**
 * Get PROJECT resource by Id
 * @param {number} id
 */
const getProject = (id) => request.get(generatePath(ENDPOINTS.PROJECT, { id }));

const getProjectsFileByIds = (ids) =>
  request.get(generatePath(ENDPOINTS.PROJECTS), {
    params: { id: ids },
    headers: { accept: 'text/csv' },
    responseType: 'blob',
  });

/**
 * Update PROJECT resource
 * @param {number} id
 */
const updateProject = (id, fields) =>
  request.patch(generatePath(ENDPOINTS.PROJECT, { id }), fields, {
    headers: { 'content-type': 'application/merge-patch+json' },
  });

/**
 * Create PROJECT resource
 * @param {number} id
 */
const createProject = (data) => request.post(ENDPOINTS.PROJECTS, data);

/**
 * Get common PROJECT parameter resources
 */
// const getProjectsTypes = (page = 1, itemsPerPage = 100) =>
//   request.get(ENDPOINTS.PROJECTS_TYPES, {
//     params: { page, items_per_page: itemsPerPage },
//   });

const getProjectsSubtypes = (page = 1, itemsPerPage = 100) =>
  request.get(ENDPOINTS.PROJECTS_SUBTYPES, {
    params: { page, items_per_page: itemsPerPage },
  });

const getRegulationTypes = (page = 1, itemsPerPage = 100) =>
  request.get(ENDPOINTS.REGULATION_TYPES, {
    params: { page, items_per_page: itemsPerPage },
  });

const getModes = (page = 1, itemsPerPage = 100) =>
  request.get(ENDPOINTS.MODES, {
    params: { page, items_per_page: itemsPerPage },
  });

const getReferenceUnits = (page = 1, itemsPerPage = 100) =>
  request.get(ENDPOINTS.REFERENCE_UNITS, {
    params: { page, items_per_page: itemsPerPage },
  });

const getCountries = (page = 1, itemsPerPage = 100) =>
  request.get(ENDPOINTS.COUNTRIES, {
    params: { page, items_per_page: itemsPerPage },
  });

export const projectsServices = {
  getProjects,
  getProjectsFile,
  getProject,
  getProjectsFileByIds,
  updateProject,
  createProject,
  // getProjectsTypes,
  getProjectsSubtypes,
  getRegulationTypes,
  getModes,
  getReferenceUnits,
  getCountries,
};
