import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import PropTypes from 'prop-types';

import { ROUTES } from 'constants/index';

import { getLocalizedSubTypeLabel } from 'utils';

import { langSelector } from 'ducks/config/selectors';

import {
  makeCellTextLocalizer,
  makeCellTextDateFormatter,
  makeCellTextLinkinizer,
  makeCellTextTooltipizer,
  makeCellTextHighlighter,
} from '../../../utils/tableUtils';

import {
  getEditableSpan,
  getTooltip,
  makeIconLink,
} from '../../ProjectsTabPane/ProjectsTable';

const propTypes = {
  rowSelection: PropTypes.object.isRequired,
  machineRecords: PropTypes.arrayOf(PropTypes.object).isRequired,
  filters: PropTypes.shape({ searchTerm: PropTypes.string }).isRequired,
  sorting: PropTypes.shape({ sortedInfo: PropTypes.object }).isRequired,
  handleTableChange: PropTypes.func.isRequired,
  isRequestingMachines: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export function MachinesTable({
  rowSelection,
  machineRecords,
  filters,
  sorting,
  handleTableChange,
  isRequestingMachines,
  isAdmin,
}) {
  /* Hooks */
  const { t } = useTranslation();

  const lang = useSelector(langSelector);

  /* Table config */
  const formatCellTextDate = makeCellTextDateFormatter(lang);
  const tooltipizeCellText = makeCellTextTooltipizer(getTooltip);
  const localizeTypeText = makeCellTextLocalizer(
    t,
    getLocalizedSubTypeLabel,
    tooltipizeCellText
  );

  const getIconLink = makeIconLink('tool');
  const linkinizeCellIcon = makeCellTextLinkinizer(
    getIconLink,
    ROUTES.MACHINE_SETTINGS
  );

  const preProcessCellText = makeCellTextHighlighter(
    getEditableSpan,
    filters.searchTerm,
    tooltipizeCellText
  );

  const columns = [
    {
      title: t('Machines_table.Column_titles.Config'),
      render: linkinizeCellIcon,
      align: 'center',
      width: 120,
    },
    {
      title: t('Machines_table.Column_titles.Type'),
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      sortOrder:
        sorting.sortedInfo.columnKey === 'type' && sorting.sortedInfo.order,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: localizeTypeText,
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Client'),
      dataIndex: 'projectReference',
      key: 'projectReference',
      sorter: true,
      sortOrder:
        sorting.sortedInfo.columnKey === 'projectReference' &&
        sorting.sortedInfo.order,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: tooltipizeCellText,
      width: 180,
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Id'),
      dataIndex: 'id',
      key: 'id',
      render: tooltipizeCellText,
      width: 90,
      ellipsis: true,
    },
    {
      title: (
        <span>
          {t('Machines_table.Column_titles.Label')}{' '}
          <sup style={{ color: '#1890ff' }}>
            {t('Common_terms.Hints.Editable')}
          </sup>
        </span>
      ),
      dataIndex: 'label',
      render: preProcessCellText,
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Saved_at'),
      dataIndex: 'updatedAt',
      sorter: true,
      sortOrder:
        sorting.sortedInfo.columnKey === 'updatedAt' &&
        sorting.sortedInfo.order,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: formatCellTextDate,
      width: 190,
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Downloaded_at'),
      dataIndex: 'downloadedAt',
      sorter: true,
      sortOrder:
        sorting.sortedInfo.columnKey === 'downloadedAt' &&
        sorting.sortedInfo.order,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: formatCellTextDate,
      width: 250,
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Installer'),
      dataIndex: 'installer',
      render: tooltipizeCellText,
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Regulation_type'),
      dataIndex: 'regulationType',
      render: tooltipizeCellText,
      width: 170,
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Mode_available'),
      dataIndex: 'modeAvailable',
      render: tooltipizeCellText,
      ellipsis: true,
    },
    {
      title: t('Machines_table.Column_titles.Units_ref'),
      dataIndex: 'unitsRef',
      render: tooltipizeCellText,
      width: 200,
      ellipsis: true,
    },
  ].reduce((acc, column) => {
    if (!isAdmin && column.dataIndex === 'installer') {
      return acc;
    }
    return [...acc, column];
  }, []);

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={machineRecords}
      rowSelection={rowSelection}
      onChange={handleTableChange}
      loading={isRequestingMachines}
      locale={{ emptyText: t('Machines_table.Empty_text') }}
      pagination={false}
    />
  );
}

MachinesTable.propTypes = propTypes;
