import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useLoggedInCheck } from 'hooks/useLoggedInCheck';
import { useAdminCheck } from 'hooks/useAdminCheck';

import { AppRouter } from 'routes';

import { AppLayout } from 'components/AppLayout';

export function App() {
  const isAdmin = useAdminCheck();
  const isUserLoggedIn = useLoggedInCheck();

  return (
    <div className="App">
      <BrowserRouter>
        <AppLayout isLoggedIn={isUserLoggedIn}>
          <AppRouter isLoggedIn={isUserLoggedIn} isAdmin={isAdmin} />
        </AppLayout>
      </BrowserRouter>
    </div>
  );
}
