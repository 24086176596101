import * as ENDPOINTS from './endpoints';

/**
 * App routes constants
 */
export const ROUTES = {
  ROOT: '/',
  PROJECTS: '/projects',
  PROJECT_NEW: '/project-new',
  PROJECT_DETAILS: '/projects/:id',
  PROJECT_MACHINE_SETTINGS: '/project/machines/:id',
  MACHINE_SETTINGS: '/machines/:id',
  OPTIONS: '/options',
  LOGIN: '/login',
  LOGOUT: '/logout',
  PASSWORD_RECOVERY: '/password-recovery',
};

/**
 * App roles constants
 */
export const ROLES = {
  USER: 'ROLE_USER',
  ADMIN: 'ROLE_ADMIN',
  EXPERT: 'ROLE_EXPERT',
  INSTALLER: 'ROLE_INSTALLER',
};

/**
 * Reversible file types for /binary-file/reversible?type={type}
 */
export const REVERSIBLE_TYPES = {
  T_ONE_LOAD: 't_one_load',
  AQUA_AIR_LOAD: 'aqua_air_load',
};

export const integerPattern = /^([0-9]\d*)?$/;
export const floatOneDigitPattern = /^[0-9]*(\.([0-9]{1})?)?$/;
export const floatDoubleDigitPattern = /^[0-9]*(\.([0-9]{1,2})?)?$/;
export const postCodePattern = /^[0-9]{1,12}$/;
export const machineLabelPattern =
  /^[ .,!?_\-+=–—~"'@#$%&^*:;(){}[\]<>|0-9a-zA-Z]*$/;

export { ENDPOINTS };
