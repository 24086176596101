import { call, put, takeLatest } from 'redux-saga/effects';

import {
  LOGGED_USER_INFO_REQUEST,
  GET_USERS_REQUEST,
  GET_ALL_USERS_REQUEST,
  GET_USERS_COUNT_REQUEST,
  loggedUserInfoResponse,
  loggedUserInfoError,
  getUsersResponse,
  getUsersError,
  getAllUsersResponse,
  getAllUsersError,
  getUsersCountResponse,
  getUsersCountError,
} from './actions';

import { userServices } from './services';

function* setUserInfoSaga() {
  try {
    const id = localStorage.getItem('USER_ID');
    const { username, name, email, roles } = yield call(
      userServices.getUserInfo,
      id
    );

    yield put(loggedUserInfoResponse({ username, name, email, roles }));
  } catch (error) {
    yield put(loggedUserInfoError({ error }));
  }
}

function* getUsersSaga(action) {
  try {
    const page = action.payload && action.payload.page;
    const perPage = action.payload && action.payload.perPage;

    const users = yield call(userServices.getUsers, page, perPage);

    yield put(getUsersResponse({ users }));
  } catch (error) {
    yield put(getUsersError({ error }));
  }
}

function* getAllUsersSaga() {
  try {
    let allUsers = [];
    let page = 1;
    let response = yield call(userServices.getUsers, page, 30);

    while (response) {
      response = yield call(userServices.getUsers, page, 30);

      if (!Array.isArray(response) || !response.length) {
        break;
      }

      allUsers = [...allUsers, ...response];

      page += 1;
    }

    yield put(getAllUsersResponse({ users: allUsers }));
  } catch (error) {
    yield put(getAllUsersError({ error }));
  }
}

function* getUsersCountSaga() {
  try {
    let page = 1;
    let usersCount = 0;
    let response = yield call(userServices.getUsers, page, 30);

    while (response) {
      response = yield call(userServices.getUsers, page, 30);

      if (!Array.isArray(response) || !response.length) {
        break;
      }

      usersCount += response.length;
      page += 1;
    }

    yield put(getUsersCountResponse({ count: usersCount }));
  } catch (error) {
    yield put(getUsersCountError({ error }));
  }
}

export function* userInfoMainSaga() {
  yield takeLatest(LOGGED_USER_INFO_REQUEST, setUserInfoSaga);
  yield takeLatest(GET_USERS_REQUEST, getUsersSaga);
  yield takeLatest(GET_ALL_USERS_REQUEST, getAllUsersSaga);
  yield takeLatest(GET_USERS_COUNT_REQUEST, getUsersCountSaga);
}
