import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col, Switch } from 'antd';

import { FormSelect } from 'components/ui/FormSelect';
import { floatOneDigitPattern } from '../../../constants';
import { TableCell } from './TableCell';
import { AdvancedSettings } from './AdvancedSettings';
import { RoomSelector } from './RoomSelector';

import mainStyles from '../MachineSettings.module.css';
import styles from './RoomsConfigTable.module.css';

const propTypes = {
  index: PropTypes.number,
  values: PropTypes.shape({
    label: PropTypes.string,
    treatedSurface: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ceilingHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selectedAirgrillDimension: PropTypes.number,
    airleakStatus: PropTypes.bool,
  }),
  errors: PropTypes.shape({
    treatedSurface: PropTypes.string,
    ceilingHeight: PropTypes.string,
  }),
  outlets: PropTypes.array,
  roomNames: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  roomParams: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  advanced: PropTypes.bool,
};

export function TableColumn({
  index,
  values,
  errors,
  roomParams,
  handleChange,
  setFieldValue,
  setFieldTouched,
  outlets,
  roomNames,
  advanced = false,
}) {
  const handleSelectChange = useCallback(
    (name, val) => {
      setFieldValue(name, val);
    },
    [setFieldValue]
  );

  const handleAirLeakStatusChange = useCallback(
    (checked) => {
      setFieldValue(`rooms[${index}].airleakStatus`, checked);
    },
    [index, setFieldValue]
  );

  const handleAirgrillChange = useCallback(
    (name, val) => {
      setFieldValue(`rooms[${index}].selectedAirgrillDimension`, val);
    },
    [index, setFieldValue]
  );

  return (
    <div className={styles.RoomsConfigTable}>
      <Col span={2} className={styles.roomColumn}>
        <div className={styles.headerPanel}>K{parseInt(index, 10) + 1}</div>
        <div className={styles.input}>
          <RoomSelector
            name={`rooms[${index}].label`}
            value={values.label}
            params={roomNames.map((room) => room.label)}
            onBlur={() => setFieldTouched(`rooms[${index}].label`)}
            onChange={handleSelectChange}
          />
        </div>
        <TableCell
          name={`rooms[${index}].treatedSurface`}
          type="number"
          value={values.treatedSurface}
          error={errors.treatedSurface}
          pattern={floatOneDigitPattern}
          minValue={0}
          maxValue={200}
          autoCorrect
          handleBlur={() => setFieldTouched(`rooms[${index}].treatedSurface`)}
          handleChange={handleChange}
          showError={false}
          tabIndex={index + 2}
        />
        <TableCell
          name={`rooms[${index}].ceilingHeight`}
          type="number"
          value={values.ceilingHeight}
          error={errors.ceilingHeight}
          pattern={floatOneDigitPattern}
          setFieldValue={setFieldValue}
          minValue={0}
          maxValue={5.0}
          autoCorrect
          handleBlur={() => setFieldTouched(`rooms[${index}].ceilingHeight`)}
          handleChange={handleChange}
          showError={false}
          tabIndex={index + 12}
        />
        {advanced && (
          <>
            <TableCell
              value={values.treatedSurface * values.ceilingHeight}
              name="height"
              disabled
            />
            <TableCell
              value={values.treatedSurface * values.ceilingHeight * 4}
              name="height"
              disabled
            />
          </>
        )}
        <div className={styles.input}>
          <FormSelect
            styles={{
              itemClassName: mainStyles.formItemTable,
              inputClassName: mainStyles.formInputDescribe,
            }}
            value={values.selectedAirgrillDimension}
            name={`rooms[${index}].selectedAirgrillDimension`}
            optionList={outlets}
            labelField="label"
            handleBlur={() =>
              setFieldTouched(`rooms[${index}].selectedAirgrillDimension`)
            }
            handleChange={handleAirgrillChange}
            tabIndex={index + 22}
          />
        </div>
        <div className={styles.cell}>
          <Switch
            defaultChecked
            checked={values?.airleakStatus || false}
            onBlur={() => setFieldTouched(`rooms[${index}].airleakStatus`)}
            onChange={handleAirLeakStatusChange}
            tabIndex={index + 32}
          />
        </div>
        <div style={{ height: `${74}px` }} />
        {advanced && <AdvancedSettings values={roomParams} />}
      </Col>
    </div>
  );
}

TableColumn.propTypes = propTypes;
