import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Table } from 'antd';

import { langSelector } from 'ducks/config/selectors';
import { getFileHistoryRequest } from 'ducks/fileHistory/actions';
import {
  fileHistoryCurrentPageSelector,
  fileHistoryEntriesSelector,
  fileHistorySortingSelector,
  hasNextHistoryPageSelector,
  isRequestingFileHistorySelector,
} from 'ducks/fileHistory/selectors';

import { makeCellTextDateFormatter } from 'utils/tableUtils';

export function FileHistoryTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hasNextPage = useSelector(hasNextHistoryPageSelector);
  const isLoading = useSelector(isRequestingFileHistorySelector);
  const entries = useSelector(fileHistoryEntriesSelector);
  const sorting = useSelector(fileHistorySortingSelector);
  const currentPage = useSelector(fileHistoryCurrentPageSelector);

  useEffect(() => {
    dispatch(
      getFileHistoryRequest({
        page: 1,
        sorting,
      })
    );
  }, []);

  const lang = useSelector(langSelector);

  const formatCellTextDate = makeCellTextDateFormatter(lang, 'LLL');

  const columns = [
    {
      title: t('Options_page.Table.Column_titles.Date'),
      dataIndex: 'createdAt',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: formatCellTextDate,
    },
    {
      title: t('Options_page.Table.Column_titles.User_account'),
      key: 'user.username',
      dataIndex: ['user', 'name'],
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: t('Options_page.Table.Column_titles.T_one_family'),
      dataIndex: ['family', 'label'],
      ellipsis: true,
    },
    {
      title: t('Options_page.Table.Column_titles.Id_ihm'),
      dataIndex: 'idIhm',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      ellipsis: true,
    },
    {
      title: t('Options_page.Table.Column_titles.Customer_name'),
      dataIndex: 'customerName',
      ellipsis: true,
    },
    {
      title: t('Options_page.Table.Column_titles.After_sale_request'),
      dataIndex: 'afterSaleRequest',
      render: String,
      ellipsis: true,
    },
  ];

  const onTableChange = useCallback((_page, _f, sort) => {
    const order = sort.order === 'ascend' ? 'asc' : 'desc';
    const field = sort.columnKey;

    dispatch(
      getFileHistoryRequest({
        page: 1,
        sorting: { order, field },
      })
    );
  }, []);

  const infiniteRef = useInfiniteScroll({
    hasNextPage,
    loading: isLoading,
    onLoadMore: () => {
      dispatch(
        getFileHistoryRequest({
          page: currentPage + 1,
          sorting,
        })
      );
    },
  });

  return (
    <div ref={infiniteRef}>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={entries}
        onChange={onTableChange}
        loading={isLoading}
        locale={{ emptyText: t('Options_page.Table.Empty_text') }}
        pagination={false}
      />
    </div>
  );
}
