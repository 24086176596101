import axios from 'axios';
import Cookies from 'js-cookie';

import { redirect } from 'helpers/common';
import { API_BASE_URL, AUTH_TOKEN_KEY, TOKEN_ENDPOINTS } from '../config';

const request = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Accept: 'application/json',
  },
});
const requestRaw = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Accept: 'application/json',
  },
});

/**
 * Request interceptor for setting JWT to request header
 */
request.interceptors.request.use(setJWTHeader);
requestRaw.interceptors.request.use(setJWTHeader);

/**
 * Response interceptor for setting JWT to LocalStorage
 */
request.interceptors.response.use(setJWTLocalStorage);
requestRaw.interceptors.response.use(setJWTLocalStorage);

/**
 * Response interceptors for send and check response
 */
request.interceptors.response.use(responseMapper, errorHandler);

/**
 * Converts object keys to camelCase
 *
 * @param {AxiosResponse} response - server's response
 * @return {Promise.<Object>}
 */
function responseMapper(response) {
  return Promise.resolve(response.data);
}

/**
 * Set jwt token in the authorization header under the 'Authorization' key
 *
 * @param {AxiosRequestConfig} config
 * @return {AxiosRequestConfig}
 */
/* eslint-disable no-param-reassign */
function setJWTHeader(config) {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    AUTH_TOKEN_KEY
  )}`;

  return config;
}

/**
 * Save JWT to the LocalStorage
 *
 * @param {AxiosResponse} response
 * @return {AxiosResponse}
 */
function setJWTLocalStorage(response) {
  if (
    TOKEN_ENDPOINTS.some((endpoint) => response.config.url.includes(endpoint))
  ) {
    const { token } = response.data;
    if (token) {
      localStorage.setItem(AUTH_TOKEN_KEY, token);
      Cookies.set('T-ONE-TOKEN', token, { expires: 1 / 48 });
    }
  }
  return response;
}

/**
 * Check response for errors
 * This handler is specific for project
 *
 * @param {AxiosResponse} data - server's response
 * @return {Promise.<Object>}
 */
function errorHandler(data = {}) {
  const { response } = data;
  const newError = {};

  if ([401, 403].includes(response.status)) {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    // eslint-disable-next-line no-restricted-globals
    redirect('/login');
  }

  newError.statusCode = response.status;
  newError.statusMessage = response.statusText;
  /**
   * TODO:
   * change error handling to actual errors structure
   */
  newError.error = parseJSON(response.data && response.data.message);

  return Promise.reject(newError);
}

/**
 * Parse json if needed
 *
 * @param {*} data
 * @return {Object|*}
 */
function parseJSON(data) {
  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
}

export { request, requestRaw };
