import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

import { FormInput } from 'components/ui/FormInput';

import styles from './MachineSettings.module.css';

const propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
  error: PropTypes.number,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export function MinimalAirflowSettings({
  name,
  value,
  error,
  handleChange,
  title,
  disabled,
}) {
  const { t } = useTranslation();

  return (
    <Row className={clsx(styles.narrowRow)}>
      <Col span={4} className={styles.airLeakLabelCol}>
        <div className={styles.fieldTitle}>{title}</div>
      </Col>
      <Col span={3}>
        <FormInput
          styles={{
            itemClassName: styles.formItem,
            inputClassName: styles.formInputDescribe,
          }}
          name={name}
          value={`${value || 0} ${t('Common_terms.Measurements.Airflow')}`}
          handleChange={handleChange}
          placeholder="0"
          disabled={disabled}
          showError={false}
        />
      </Col>
      <Col span={5}>
        <div className={clsx(styles.fieldTitle, styles.airLeakErrorMessage)}>
          {Boolean(error) &&
            `${t('Machine_settings.Minimal_Airleak_Error')} ${error} m/s`}
        </div>
      </Col>
    </Row>
  );
}

MinimalAirflowSettings.propTypes = propTypes;
