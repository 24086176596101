import { generatePath } from 'react-router';
import { request, requestRaw } from 'utils/request';

import {
  MACHINES_SETTINGS,
  MACHINE_SETTINGS,
  MACHINE_SETTINGS_FILE,
  ROOMS_CONFIGURATIONS,
  ROOM_CONFIGURATIONS,
  AIR_DISTRIBUTION,
  CONFIG_OPTIONS,
  DEFAULT_VALUES,
  AIRGRILL_OUTLETS,
  REFERENCE_UNITS,
  REGULATION_TYPES,
  ROOM_NAMES,
  INSTALLATION_TYPES,
  REVERSIBLE_BINARY,
} from 'constants/endpoints';

const getSettings = (settingsId) =>
  request.get(generatePath(MACHINE_SETTINGS, { id: settingsId }));

const getAirDistributionTypes = () =>
  request.get(`${AIR_DISTRIBUTION}?page=1&items_per_page=30`);

const getConfigOptions = () => request.get(CONFIG_OPTIONS);

const getDefaultValues = () => request.get(DEFAULT_VALUES);

const getDataByRef = (ref) => request.get(ref.slice(5, ref.length));

const getGrillOutlets = () => request.get(AIRGRILL_OUTLETS);

const getReferenceUnits = () => request.get(REFERENCE_UNITS);

const getInstallationTypes = () => request.get(INSTALLATION_TYPES);

const getRoomNames = () => request.get(ROOM_NAMES);

const getRegulationTypes = () => request.get(REGULATION_TYPES);

const postMachineSettings = (fields) =>
  request.post(MACHINES_SETTINGS, fields, {
    headers: { 'content-type': 'application/json' },
  });

const postRoomsSettings = (fields) =>
  request.post(ROOMS_CONFIGURATIONS, fields, {
    headers: { 'content-type': 'application/json' },
  });

const updateMachineSettings = (id, fields) =>
  request.patch(generatePath(MACHINE_SETTINGS, { id }), fields, {
    headers: { 'content-type': 'application/merge-patch+json' },
  });

const patchRoomsSettings = (id, fields) =>
  request.patch(generatePath(ROOM_CONFIGURATIONS, { id }), fields, {
    headers: { 'content-type': 'application/merge-patch+json' },
  });

const deleteRoomsSettings = (id) =>
  request.delete(generatePath(ROOM_CONFIGURATIONS, { id }));

/**
 * Get setting file by Id
 * @param {number} id
 */
const getSettingsFile = (id) =>
  requestRaw.get(generatePath(MACHINE_SETTINGS_FILE, { id }), {
    headers: { accept: 'application/octet-stream' },
    responseType: 'blob',
  });

/**
 * Get reversible file by id_ihm
 * @param {string} idIhm
 * @param {REVERSIBLE_TYPES} type
 */
const getReversibleFile = ({ idIhm, type, isAfterSale, customerName }) =>
  requestRaw.get(REVERSIBLE_BINARY, {
    params: {
      type,
      id_ihm: idIhm,
      customer_name: customerName,
      is_after_sale: isAfterSale || '',
    },
    headers: { accept: 'application/octet-stream' },
    responseType: 'blob',
  });

export const machineSettingsServices = {
  getSettings,
  getAirDistributionTypes,
  getConfigOptions,
  getDefaultValues,
  getDataByRef,
  getGrillOutlets,
  getInstallationTypes,
  getReferenceUnits,
  getRoomNames,
  getRegulationTypes,
  postMachineSettings,
  postRoomsSettings,
  updateMachineSettings,
  patchRoomsSettings,
  deleteRoomsSettings,
  getSettingsFile,
  getReversibleFile,
};
