import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Typography } from 'antd';

import { useNavigate } from 'react-router';
import styles from './NotFoundPage.module.css';

const { Text, Paragraph } = Typography;

export function NotFoundPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.NotFoundPage}>
      <Row type="flex" justify="center" className={styles.pageRow}>
        <Col span={14} className={styles.wrapper}>
          <Text className={styles.code404}>404</Text>
          <Paragraph className={styles.message}>
            {t('Not_found_page.Message')}
          </Paragraph>
          <Button type="primary" onClick={() => navigate(-1)}>
            {t('Not_found_page.Go_back_button')}
          </Button>
        </Col>
      </Row>
    </div>
  );
}
