import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography } from 'antd';
// import PropTypes from 'prop-types';

import { downloadSettingsFileRequest } from '../../ducks/machineSettings/actions';

import WrappedOptionsForm from '../../components/OptionsForm';

import styles from './OptionsPage.module.css';
import { FileHistoryTable } from '../FileHistoryTable';

const { Title } = Typography;

export function OptionsPage() {
  /* Hooks */
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /* States */
  const [form, setForm] = useState({
    fields: {
      idIhm: { value: null },
      customerName: { value: null },
      isAfterSale: { value: false },
    },
  });

  /* Event handlers */
  const handleFormChange = (changedFields) => {
    setForm(({ fields }) => ({
      fields: {
        ...fields,
        ...changedFields,
      },
    }));
  };

  const handleFileDownload = useCallback(
    (type) => {
      dispatch(
        downloadSettingsFileRequest({
          type,
          idIhm: form.fields.idIhm.value,
          customerName: form.fields.customerName.value,
          isAfterSale: form.fields.isAfterSale.value,
        })
      );
    },
    [
      dispatch,
      form.fields.idIhm.value,
      form.fields.customerName.value,
      form.fields.isAfterSale.value,
    ]
  );

  return (
    <div className={styles.OptionsPage}>
      <Row type="flex" justify="center" className={styles.pageRow}>
        <Col span={16}>
          <Title level={4} className={styles.title}>
            {t('Options_page.Title')}
          </Title>
          <WrappedOptionsForm
            fields={form.fields}
            isIdFound={form.fields.idIhm.value?.length === 8}
            onChange={handleFormChange}
            handleFileDownload={handleFileDownload}
          />
          <FileHistoryTable />
        </Col>
      </Row>
    </div>
  );
}

OptionsPage.propTypes = {};
